<template>
  <div class="container">
    <template v-if="user && $can('manage', 'UserSettings')">
      <b-button variant="light" class="mt-4" @click="$router.go(-1)">
        <b-icon-chevron-left></b-icon-chevron-left>&nbsp;Zur Liste
      </b-button>
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Profileinstellungen"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label-cols-sm="3"
            label="Login:"
            label-align-sm="right"
            label-for="login"
          >
            <b-form-input
              id="login"
              v-model="login"
              :readonly="user.login != null"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="3"
            label="Vorname:"
            label-align-sm="right"
            label-for="vorname"
          >
            <b-form-input
              id="vorname"
              v-model="user.vorname"
              autocomplete="new-password"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="3"
            label="Nachname:"
            label-align-sm="right"
            label-for="nachname"
          >
            <b-form-input
              id="nachname"
              v-model="user.name"
              autocomplete="new-password"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="3"
            label="E-Mail:"
            label-align-sm="right"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="user.email"
              autocomplete="new-password"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="3"
            label="E-Mail 2:"
            label-align-sm="right"
            label-for="email2"
          >
            <b-form-input
              id="email2"
              v-model="user.email2"
              autocomplete="new-password"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Mail bei zugewiesenem Ticket:"
            label-align-sm="right"
            label-for="zuweisungsmail"
          >
            <b-form-checkbox id="zuweisungsmail" v-model="user.assignedMail"
              >Mail senden</b-form-checkbox
            >
          </b-form-group>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button
              :variant="profileSavedInfo || 'primary'"
              @click="saveStammdaten"
            >
              <template v-if="profileSavedInfo == 'success'">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else-if="profileSavedInfo == 'danger'">
                <b-icon-x aria-hidden="true"></b-icon-x>Nicht gespeichert
              </template>
              <template v-else>Speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Passwort"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label-cols-sm="3"
            label="Passwort:"
            label-align-sm="right"
            label-for="password"
            :state="passwordErrorMsg == null ? null : false"
            :invalid-feedback="passwordErrorMsg"
          >
            <b-form-input
              id="password"
              type="password"
              v-model="password"
              autocomplete="new-password"
              :state="passwordErrorMsg == null ? null : false"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols-sm="3"
            label="Passwort wiederholen:"
            label-align-sm="right"
            label-for="passwordrepeat"
            :state="passwordRepeatErrorMsg == null ? null : false"
            :invalid-feedback="passwordRepeatErrorMsg"
          >
            <b-form-input
              id="passwordrepeat"
              type="password"
              v-model="passwordRepeat"
              autocomplete="new-password"
              :state="passwordRepeatErrorMsg == null ? null : false"
            ></b-form-input>
          </b-form-group>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button
              :variant="passwordSavedInfo || 'primary'"
              @click="savePassword"
            >
              <template v-if="passwordSavedInfo == 'success'">
                <b-icon-check aria-hidden="true"></b-icon-check>Passwort
                geändert
              </template>
              <template v-else-if="passwordSavedInfo == 'danger'">
                <b-icon-x aria-hidden="true"></b-icon-x>Passwort nicht geändert
              </template>
              <template v-else>Passwort ändern</template>
            </b-button>
          </div>
        </template>
      </b-card>

      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Berechtigungen"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label-cols-sm="3"
            label="Teamleiter:"
            label-align-sm="right"
            label-for="leiter"
          >
            <b-form-checkbox id="leiter" v-model="user.teamleiter"
              >Benutzer ist Teamleiter</b-form-checkbox
            >
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Rolle:"
            label-align-sm="right"
            label-for="role"
          >
            <b-form-select
              id="role"
              :options="roles"
              v-model="user.role"
            ></b-form-select>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Berechtigungen:"
            label-align-sm="right"
            label-for="groups"
          >
            <b-form-checkbox-group
              v-model="user.groups"
              :options="groups"
              id="groups"
              stacked
            ></b-form-checkbox-group>
            <b-alert show class="mt-2">
              <b>Achtung:</b> Berechtigungen, die durch die Rolle gesetzt
              werden, sind hier nicht editierbar.
            </b-alert>
          </b-form-group>
        </b-form-group>
        <template slot="footer">
          <div class="text-right">
            <b-button
              :variant="profileSavedInfo || 'primary'"
              @click="saveStammdaten"
            >
              <template v-if="profileSavedInfo == 'success'">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else-if="profileSavedInfo == 'danger'">
                <b-icon-x aria-hidden="true"></b-icon-x>Nicht gespeichert
              </template>
              <template v-else>Speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>
    </template>
    <template v-else-if="$can('manage', 'UserSettings')">
      <b-row class="mt-3">
        <b-col cols="auto" class="mr-auto">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Suche"
          ></b-form-input>
        </b-col>
        <b-col cols="auto">
          <b-button variant="primary" @click="createUser"
            >Neuer Benutzer</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <benutzertabelle
            :users="users"
            :filter="filter"
            @delete-user="deleteUser"
            class="mt-3"
          ></benutzertabelle>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import Benutzertabelle from "@/components/tables/Benutzertabelle";
import User from "@/api/user";
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BFormCheckboxGroup,
  BIconChevronLeft,
  BIconCheck,
  BIconX,
  BAlert,
} from "bootstrap-vue";

export default {
  components: {
    Benutzertabelle,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BIconChevronLeft,
    BIconCheck,
    BIconX,
    BAlert,
  },
  name: "Profil",
  data: function () {
    return {
      users: [],
      filter: null,
      password: null,
      passwordRepeat: null,
      passwordErrorMsg: null,
      passwordRepeatErrorMsg: null,
      loginEdited: null,
      profileSavedInfo: null,
      passwordSavedInfo: null,
    };
  },
  computed: {
    user: {
      get() {
        if (!this.$route.params.id) return null;
        return this.users.find((u) => u.id === this.$route.params.id);
      },
      set(val) {
        let i = this.users.findIndex((u) => u.id === val.id);
        if (i > -1) {
          this.users[i] = val;
        } else {
          this.users.push(val);
        }
      },
    },
    selectedUserRole() {
      if (!this.user || !this.user.role) return null;
      return this.$store.state.rollen.find((r) => r.id === this.user.role.id);
    },
    login: {
      get() {
        return this.loginEdited || this.user.login;
      },
      set(val) {
        this.loginEdited = val;
      },
    },
    roles: {
      get() {
        return this.$store.state.rollen.map((r) => {
          return {
            value: { id: r.id, titel: r.titel, email: r.email },
            text: r.titel,
          };
        });
      },
    },
    groups: {
      get() {
        return this.$store.state.gruppen.map((g) => {
          return {
            value: { id: g.id, name: g.name },
            text: g.beschreibung,
            disabled:
              this.selectedUserRole &&
              this.selectedUserRole.groups.findIndex((rg) => rg.id === g.id) >
                -1,
          };
        });
      },
    },
  },
  mounted() {
    User.getList().then((response) => {
      this.users = response.data;
    });
  },
  methods: {
    createUser() {
      let u = {
        id: "neu",
      };
      this.users.push(u);
      this.$router.push({ name: "benutzer", params: { id: "neu" } });
    },
    deleteUser(user) {
      if (!confirm("Benutzer wirklich löschen?")) return;
      User.delete(user.id).then((response) => {
        if (response.data.success) {
          let idx = this.users.findIndex((u) => u.id === user.id);
          if (idx >= 0) {
            this.users.splice(idx, 1);
          }
        }
      });
    },
    saveStammdaten() {
      if (this.loginEdited) this.user.login = this.loginEdited;
      this.loginEdited = null;
      if (this.password) {
        if (!this.passwordRepeat) {
          this.passwordRepeatErrorMsg = "Bitte Passwort wiederholen!";
          return;
        }
        if (this.password !== this.passwordRepeat) {
          this.passwordRepeatErrorMsg = "Passwörter stimmen nicht überein!";
          return;
        }
        this.user.password = this.password;
      }

      let self = this;
      User.update(this.user).then((response) => {
        if (response.data && response.data.success) {
          this.profileSavedInfo = "success";
          self.user = response.data.user;
          if (self.$route.params.id != response.data.user.id) {
            // neuer User hat jetzt eine ID erhalten
            // User mit ID "neu" entfernen und dann zum richtigen User springen
            let idx = self.users.findIndex((r) => r.id === "neu");
            if (idx > -1) self.users.splice(idx, 1);
            this.$router.replace({
              name: "benutzer",
              params: { id: response.data.user.id },
            });
          }
        } else {
          this.profileSavedInfo = "danger";
        }
        window.setTimeout(() => {
          this.profileSavedInfo = null;
        }, 5000);
      });
    },
    savePassword() {
      this.passwordErrorMsg = null;
      this.passwordRepeatErrorMsg = null;
      if (!this.password) {
        this.passwordErrorMsg = "Bitte Passwort eingeben!";
        return;
      }
      if (!this.passwordRepeat) {
        this.passwordRepeatErrorMsg = "Bitte Passwort wiederholen!";
        return;
      }
      if (this.password !== this.passwordRepeat) {
        this.passwordRepeatErrorMsg = "Passwörter stimmen nicht überein!";
        return;
      }

      User.update({ id: this.user.id, password: this.password }).then(
        (response) => {
          if (response.data && response.data.success) {
            this.passwordSavedInfo = "success";
            this.user = response.data.user;
          } else {
            this.passwordSavedInfo = "danger";
          }
          window.setTimeout(() => {
            this.passwordSavedInfo = null;
          }, 5000);
        }
      );
    },
  },
};
</script>