<template>
  <div>
    <b-row>
      <b-col class="pdr-0 chart-container">
        <bar-chart v-if="loaded" :chartdata="chartData" :options="chartOptions"></bar-chart>
        <b-row v-else align-v="center" align-h="center" class="p-3">
          <b-col cols="auto">
            <b-spinner
              variant="primary"
              label="Lädt..."
              style="width: 10vw; height: 10vw"
            ></b-spinner>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Api } from "@/api";
import BarChart from "./BarChart";
import { DateTime } from "luxon";
import { BCol, BRow, BSpinner } from "bootstrap-vue";
import store from "@/store";
import variables from '@/scss/_export.module.scss';

const BAR_LABELS = {
  gt_13d: ">= 14 d",
  lt_14d: "< 14 d",
  lt_7d: "< 7 d",
  lt_5d: "< 5 d",
  lt_4d: "< 4 d",
  lt_3d: "< 3 d",
  lt_48h: "< 48 h",
  lt_24h: "< 24 h",
};

// red to green
const BAR_COLORS = [
  "#cc0000",
  "#ad1708",
  "#99260d",
  "#7a3d14",
  "#525c1f",
  "#297a29",
  "#009933",
  "#33cc33",
];

export default {
  name: "TicketAlterChart",
  components: {
    BarChart,
    BCol,
    BRow,
    BSpinner,
  },
  store,
  props: {
    type: {
      type: String,
      default: "offen",
    },
  },
  data() {
    return {
      loaded: false,
      BAR_LABELS: BAR_LABELS,
      BAR_COLORS: BAR_COLORS,
      statisticData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        onClick: this.onPointClicked,
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 1,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  watch: {
    tickets(val) {
      let _self = this;
      val.forEach(ticket => {
        let diffDays = DateTime.local()
          .diff(DateTime.fromISO(ticket.erstelltAm), "days")
          .toObject();
        diffDays = Math.floor(diffDays.days); // round down to 'full' days

        // categorize:
        switch (diffDays) {
          case 0:
            // < 24 h
            _self.statisticData.lt_24h.push(ticket);
            break;
          case 1:
            _self.statisticData.lt_48h.push(ticket);
            // to 48 h
            break;
          case 2:
            _self.statisticData.lt_3d.push(ticket);
            // to 3 d
            break;
          case 3:
            _self.statisticData.lt_4d.push(ticket);
            // to 4 d
            break;
          case 4:
            _self.statisticData.lt_5d.push(ticket);
            // to 5 d
            break;
          default:
            if (diffDays === 5 || diffDays === 6) {
              _self.statisticData.lt_7d.push(ticket);
            } else if (diffDays > 6 && diffDays < 14) {
              _self.statisticData.lt_14d.push(ticket);
            } else if (diffDays >= 14) {
              _self.statisticData.gt_13d.push(ticket);
            }
            break;
        }
      });

      this.loaded = true;
    },
  },
  computed: {
    chartData() {
      // set labels
      let mappedLabels = Object.values(this.BAR_LABELS).map(label => label);
      let mappedValues = Object.values(this.statisticData).map(tickets => {
        return tickets.length;
      });

      return {
        labels: mappedLabels,
        datasets: [
          {
            label: "Tickets",
            backgroundColor: this.BAR_COLORS,
            data: mappedValues,
            lineTension: 1,
            pointBackgroundColor: variables.primary,
            pointRadius: 1,
          },
        ],
      };
    },
    tickets() {
      let ticketsOffen = this.$store.getters.tickets("STANDARD", "ALLE");
      ticketsOffen = ticketsOffen.filter(t => {
        // console.log(
        //   `Ticket:\n STATUS: ${t.status}, KATEGORIE: ${t.kategorie}, TicketTyp: ${
        //     t.ticketTyp
        //   }, SorteTitel: ${t.sorte ? t.sorte.titel : ""}`
        // );

        if (t.sorte && t.sorte.titel && t.sorte.titel.includes("Inbetriebnahme")) {
          // console.log("<--ignore");
          return false;
        }
        // console.log("<--store");
        return true;
      });
      //console.log("1: ", ticketsOffen);
      return ticketsOffen;
    },
    // getFromDate() {
    //   return DateTime.local()
    //     .minus({ days: 13 }) // less than 14
    //     .toFormat("yyyy-MM-dd");
    // },
  },
  methods: {
    reloadTickets() {
      this.$store.dispatch("loadTickets");
    },
    onPointClicked(event, point) {
      let label = Object.keys(this.statisticData)[point[0]._index];
      this.$emit("point-clicked", [
        "Offene Tickets (ohne Inbetriebnahme Tickets)",
        this.statisticData[label],
      ]);
    },
    excel() {
      Api.get("excelreport/", {
        params: { type: "open", fromDate: null, toDate: null },
      }).then(response => {
        if (response.data.status === "ok") {
          let filename = response.data.document;
          if (process.env.NODE_ENV === "development")
            filename = "https://tes-dev-tickets.batix.net" + filename;

          window.open(filename);
        }
      });
    },
    createHexColor(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var c = (hash & 0x00ffffff).toString(16).toUpperCase();
      return "#00000".substring(0, 7 - c.length) + c;
    },
  },
  created() {
    this.reloadTickets();

    // set statisticData empty object with new keys
    Object.keys(this.BAR_LABELS).forEach(key => {
      this.statisticData[key] = [];
    });
  },
};
</script>
