import { Api } from "."

export default {
  get: (id) => {
    if (id)
      return Api.get("kvzausfll_settngs/", { params: { id: id } })
    else
      return Api.get("kvzausfll_settngs/")
  },
  update: (kvz_ausfallliste) => {
    if (kvz_ausfallliste.id === 'neu')
      return Api.post("kvzausfll_settngs/", kvz_ausfallliste)
    return Api.put("kvzausfll_settngs/", kvz_ausfallliste)
  },
  updateGeneralSettings: (lowerLimit, upperLimit, lowerCityLimit, upperCityLimit, minCustCount, minCustCountCity) => {
    return Api.put("kvzausfll_settngs/", { lowerLimit: lowerLimit, upperLimit: upperLimit, lowerCityLimit: lowerCityLimit, upperCityLimit: upperCityLimit, minCustCount: minCustCount, minCustCountCity: minCustCountCity })
  },
  delete: (id) => {
    return Api.delete("kvzausfll_settngs/", { params: { id: id } })
  }
}
