<template>
  <b-modal
    id="masterticket-receiver-modal"
    size="m"
    title="Hinzufügen zum Masterticket"
    cancel-title="Abbrechen"
    ok-title="Zum Masterticket hinzufügen"
    header-text-variant="light"
    header-bg-variant="primary"
    :no-close-on-backdrop="true"
    :ok-disabled="!receiver"
    @show="onShow()"
    @ok="onOk()"
    @close="onClose()"
  >
    <b-row>
      <b-col>
        <b-form-group label="Ausgweähltes Masterticket:">
          <b>{{ ticketTitle }}</b>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Benachrichtigung:">
          <b-form-radio v-model="radioSelection" name="radio-benachrichtigung" value="email">E-Mail</b-form-radio>
          <b-form-radio v-model="radioSelection" name="radio-benachrichtigung" value="sms">SMS</b-form-radio>
          <b-form-radio v-model="radioSelection" name="radio-benachrichtigung" value="none">Keine</b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Empfänger" label-for="receiver-input" invalid-feedback="Empfänger erforderlich">
          <b-form-input
            ref="receiver-input"
            id="receiver-input"
            :state="receiver !== '' ? true : false"
            :disabled="receiverDisabled"
            v-model="receiver"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BFormGroup, BFormRadio, BFormInput } from "bootstrap-vue";

export default {
  name: "MasterticketEmpfaengerModal",
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormInput,
  },
  props: {
    ticketTitle: { type: String, required: true },
    selectedKunde: { type: Object, required: false },
  },
  data() {
    return {
      benachrichtigung: {
        receiver: "",
        type: "", // email,sms, '' <-- none
      },
      radioSelection: "",
      receiver: "",
      receiverDisabled: false,
    };
  },
  watch: {
    radioSelection(val) {
      this.receiver = "";
      this.receiverDisabled = false;
      this.benachrichtigung.type = val;

      switch (val) {
        case "email":
          this.receiver = this.selectedKunde.email ? this.selectedKunde.email : "";
          break;
        case "sms":
          if (this.selectedKunde.phone) {
            this.receiver = this.selectedKunde.phone ? this.selectedKunde.phone : "";
          }
          // if (this.selectedKunde.phone && this.selectedKunde.phone2) {
          //   this.receiver = this.selectedKunde.phone + " / " + this.selectedKunde.phone2;
          // }
          if (!this.selectedKunde.phone && this.selectedKunde.phone2) {
            this.receiver = this.selectedKunde.phone2;
          }

          break;

        default:
          this.receiver = " ";
          this.receiverDisabled = true;
          break;
      }
    },
  },
  methods: {
    onShow() {
      if (this.selectedKunde.email) {
        this.radioSelection = "email";
      } else if (this.selectedKunde.phone) {
        this.radioSelection = "sms";
      } else {
        this.radioSelection = "none";
      }
    },
    onOk() {
      this.benachrichtigung.receiver = this.receiver;
      this.$emit("benachrichtigung", this.benachrichtigung);
    },
  },
};
</script>
