<template>
  <b-form-select
    v-model="tempVal"
    :size="size"
    @change="onChanged"
    class="tree-select"
    :state="state"
  >
    <template v-slot:first>
      <slot name="first"></slot>
    </template>
    <b-form-select-option v-for="o in flatten" :key="o.id" :value="o.value" :disabled="o.disabled">
      <template v-for="n in o.depth">
        <b :key="n">-</b>
      </template>
      {{o.titel}}
    </b-form-select-option>
  </b-form-select>
</template>

<script>
import { BFormSelect, BFormSelectOption } from "bootstrap-vue";

export default {
  name: "TreeSelect",
  components: {
    BFormSelect,
    BFormSelectOption
  },
  props: {
    options: { type: Array, required: true },
    value: { type: Object },
    size: { type: String },
    state: { type: Boolean }
  },
  computed: {
    tempVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    flatten() {
      let out = [];
      this.options.forEach(o => {
        let subs = this.flattenIt(0, o);
        subs.forEach(su => out.push(su));
      });
      return out;
    }
  },
  methods: {
    onChanged() {
      this.$emit("change");
    },
    flattenIt(depth = 0, val) {
      let out = [];
      let self = this;
      // sich selbst hinzufügen
      out.push({
        id: val.id,
        titel: val.titel,
        value: { id: val.id, titel: val.titel, archived: val.archived },
        disabled: val.archived,
        depth: depth
      });
      // falls Sub-Elemente, füga iterativ auch die hinzu
      if (val.sub) {
        val.sub.forEach(s => {
          let subs = self.flattenIt(depth + 1, s);
          subs.forEach(su => out.push(su));
        });
      }
      return out;
    }
  }
};
</script>

<style lang="scss">
.tree-select option:disabled {
  color: #ff7474;
}
</style>