import Placeholder from "@/static/placeholder";

export default {
  methods: {
    checkHandynummer(nr) {
      if ( nr && nr.length !== 0 && (nr.startsWith("015") || nr.startsWith("016") || nr.startsWith("017"))) {
        return true;
      }
      return false;
    },
    replacePlaceholderTicket(text, ticket) {
      // TICKETNUMMER
      if (text.search(Placeholder.TICKETNUMMER) !== -1) {
        text = text.replace(Placeholder.TICKETNUMMER, ticket.ticketNr || "");
      }

      //NACHNAME_FIRMA
      if (text.search(Placeholder.NACHNAME_FIRMA) !== -1) {
        text = text.replace(Placeholder.NACHNAME_FIRMA, ticket.kundenname || "");
      }

      //ANSCHRIFT
      if (text.search(Placeholder.ANSCHRIFT) !== -1) {
        let address =
          `${ticket.strasse.strasse || ""} ${ticket.strasse.nr || ""}` +
          "\n" +
          `${ticket.strasse.plz || ""} ${ticket.strasse.ort || ""}`;
        text = text.replace(Placeholder.ANSCHRIFT, address);
      }
      return text;
    },
  }
};
