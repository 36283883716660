<template>
  <b-modal
    :id="id"
    ref="sign-modal"
    size="xl"
    header-bg-variant="primary"
    header-text-variant="light"
    :title="titel"
    ok-title="Übernehmen"
    ok-variant="primary"
    cancel-title="Abbrechen"
    cancel-variant="light"
    @shown="initSignField"
    @ok="handleOk"
  >
    <div ref="sign-wrapper">
      <canvas
        class="border rounded"
        width="460"
        height="260"
        ref="sign-canvas"
        @touchmove="draw"
        @mousemove="draw"
        @mousedown="beginDrawing"
        @touchstart="beginDrawing"
        @mouseup="stopDrawing"
        @mouseleave="stopDrawing"
        @touchend="stopDrawing"
        @touchleave="stopDrawing"
      ></canvas>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "UnterschriftModal",
  components: {
    BModal,
  },
  props: {
    id: String,
    titel: String,
  },
  data() {
    return {
      ctx: null,
      x: 0,
      y: 0,
      minX: null,
      maxX: null,
      minY: null,
      maxY: null,
      isDrawing: false,
      isTouch: false,
    };
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.$refs["sign-canvas"].width = this.$refs["sign-wrapper"].clientWidth;
    },
    initSignField() {
      this.$refs["sign-canvas"].width = this.$refs["sign-wrapper"].clientWidth;
      this.ctx = this.$refs["sign-canvas"].getContext("2d");
      this.ctx.clearRect(
        0,
        0,
        this.$refs["sign-canvas"].width,
        this.$refs["sign-canvas"].height
      );
      this.minX = null;
      this.maxX = null;
      this.minY = null;
      this.maxY = null;
    },
    getPosition(e) {
      this.isTouch = e instanceof TouchEvent;
      if (e instanceof TouchEvent) {
        e.preventDefault();
        let ox = this.$refs["sign-canvas"].getBoundingClientRect().left;
        let oy = this.$refs["sign-canvas"].getBoundingClientRect().top;
        return {
          x: e.changedTouches[0].clientX - ox,
          y: e.changedTouches[0].clientY - oy,
        };
      } else {
        return { x: e.offsetX, y: e.offsetY };
      }
    },
    beginDrawing(e) {
      let pos = this.getPosition(e);
      this.x = pos.x;
      this.y = pos.y;
      this.isDrawing = true;
    },
    stopDrawing(e) {
      if (this.isDrawing) {
        let pos = this.getPosition(e);
        this.drawLine(this.x, this.y, pos.x, pos.y);
        this.x = 0;
        this.y = 0;
        this.isDrawing = false;
      }
    },
    drawLine(x1, y1, x2, y2) {
      this.ctx.beginPath();
      this.ctx.strokeStyle = "blue";
      this.ctx.lineWidth = 2;
      this.ctx.moveTo(x1, y1);
      this.ctx.lineTo(x2, y2);
      this.ctx.stroke();
      this.ctx.closePath();
    },
    draw(e) {
      if (this.isDrawing) {
        e.preventDefault();
        let pos = this.getPosition(e);
        this.drawLine(this.x, this.y, pos.x, pos.y);
        this.x = pos.x;
        this.y = pos.y;
        this.minX = this.minX == null ? this.x : Math.min(this.minX, this.x);
        this.maxX = this.maxX == null ? this.x : Math.max(this.maxX, this.x);
        this.minY = this.minY == null ? this.y : Math.min(this.minY, this.y);
        this.maxY = this.maxY == null ? this.y : Math.max(this.maxY, this.y);
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // erzeruge Canvas, das genauso groß ist, wie die Unterschrift
      let tCanvas = document.createElement("canvas"),
        tCtx = tCanvas.getContext("2d");
      // füge Padding hinzu
      this.minX -= 20;
      this.minY -= 20;
      this.maxX += 20;
      this.maxY += 20;
      let sourceWidth = this.maxX - this.minX;
      let sourceHeight = this.maxY - this.minY;
      let targetWidth = 600;
      let targetHeight = 200;
      tCanvas.width = targetWidth;
      tCanvas.height = targetHeight;
      let targetX = 0;
      let targetY = 0;

      // Unterschrift zentrieren
      if (sourceWidth < sourceHeight) {
        let tWidth = (sourceWidth / sourceHeight) * targetHeight;
        targetX = (targetWidth - tWidth) / 2;
        targetWidth = tWidth;
      } else {
        let tHeight = (sourceHeight / sourceWidth) * targetWidth;
        targetY = (targetHeight - tHeight) / 2;
        targetHeight = tHeight;
        // console.log(
        //  `${sourceWidth} x ${sourceHeight} Höher als breit -> ${targetWidth} x ${targetHeight}`
        //);
      }

      tCtx.drawImage(
        this.$refs["sign-canvas"],
        this.minX,
        this.minY,
        sourceWidth,
        sourceHeight,
        targetX,
        targetY,
        targetWidth,
        targetHeight
      );

      this.$emit("signed", tCanvas.toDataURL());
      this.$nextTick(() => {
        this.$refs["sign-modal"].hide();
      });
    },
  },
};
</script>