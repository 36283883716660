<template>
  <div>
    <loader></loader>
    <main-navigation></main-navigation>

    <!-- Page Content -->
    <div id="page-content-wrapper">
      <div class="container-fluid mt-5">
        <h1>Geplante Arbeiten - Details</h1>
        <template v-if="$can('create', 'GeplanteArbeiten') || $can('update', 'GeplanteArbeiten')">
          <b-row>
            <b-col cols="12" sm="6" v-if="arbeit.erstelltAm">
              {{ arbeit.erstelltAm }}, {{ arbeit.erstelltVon }}
            </b-col>
            <b-col cols="12" sm="6" v-else>
              - Neue geplante Arbeit -
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="12" md="6" sm="12">
              <b-row>
                <b-col cols="6">
                  <b-form-group label-for="datum" label="Datum">
                    <b-form-datepicker
                      id="datum"
                      v-model="datepickerDate"
                      v-bind="{ labelNoDateSelected: 'Bitte Datum Auswählen' }"
                      right
                      button-variant="light"
                      reset-button
                      :min="todayForDatepickers"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      locale="de"
                      :state="arbeit.datum ? true : false"
                    ></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label-for="vonBis" label="Von - Bis">
                    <b-form-input id="vonBis" v-model="arbeit.vonBis"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group label-for="ort" label="Ort">
                    <b-form-input id="ort" v-model="arbeit.ort"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label-for="kvz" label="KVZ">
                    <b-form-input id="kvz" v-model="arbeit.kvz"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group label-for="cntKunden" label="Anzahl Kunden">
                    <b-form-input id="cntKunden" v-model.number="arbeit.cntKunden"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label-for="betroffeneKunden" label="betroffene Kunden">
                    <b-form-select v-model="arbeit.betroffeneKunden" :options="betrKundenOptionen"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label-for="kundenInfo" label="Kundeninformation">
                    <b-row>
                    <b-col cols="6">
                      <b-form-select v-model="arbeit.kundenInfo" :options="kundenInfoOptionen"></b-form-select>
                      <div style="color: red;" v-if="arbeit.kundenInfo != null && arbeit.kundenInfo != '' && arbeit.kundenInfo != 'erfolgt' && arbeit.kundenInfo != 'nicht erfolgt' ">
                        <label>Bisherige Kundeninformation:</label>
                        {{ arbeit.kundenInfo }}
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <b-form-datepicker
                        id="kundenInfoAm"
                        v-model="datepickerKundenInfoAm"
                        v-bind="{ labelNoDateSelected: 'Bitte Datum Auswählen' }"
                        right
                        button-variant="light"
                        reset-button
                        :max="todayForDatepickers"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        locale="de"
                      ></b-form-datepicker>                      
                    </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label-for="kundenInfoAm" label="">
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6" sm="12">
              <b-row>
                <b-col xs="12" sm="6">
                  <b-form-group label-for="status" label="Status">
                    <b-form-select v-model="arbeit.status" :options="statusOptionen"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="6">
                  <b-form-group label-for="verantwMA" label="verantwortlicher Mitarbeiter">
                    <b-form-input id="verantwMA" v-model="arbeit.verantwMA"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col xs="12" sm="6">
                  <b-form-group label-for="ausfallWahrsch" label="Ausfallwahrscheinlichkeit">
                    <b-form-input id="ausfallWahrsch-2" v-model.number="arbeit.ausfallWahrsch" type="range" min="0" max="100" step="2"></b-form-input>
                    <div class="mt-1">{{ arbeit.ausfallWahrsch }}%</div>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="6">
                  <b-form-group label-for="stromlos" label="">
                    <div class="font-weight-bold">Stromlos</div>
                    <b-form-checkbox id="stromlos" v-model="arbeit.stromlos" class="mt-1">
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label-for="bemerkungen" label="Bemerkungen">
                    <b-form-textarea
                      id="bemerkungen"
                      v-model="arbeit.bemerkungen"
                      rows="5"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row align-h="between">
                <b-col cols="3">
                  <b-button
                    :variant="saveSuccess ? 'success' : 'primary'"
                    @click="save()"
                    :disabled="!arbeit.datum"
                  >
                    <template v-if="saveSuccess">
                      <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
                    </template>
                    <template v-else>Speichern</template>
                  </b-button>
                </b-col>
                <b-col cols="2" v-if="arbeit.id.length !== 0">
                  <b-button
                    variant="primary"
                    @click="loeschen()"
                    :disabled="!$can('delete', 'GeplanteArbeiten')"
                  >
                    Löschen
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          Keine Berechtigung
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import GeplanteArbeitenApi from "@/api/geplanteArbeiten";
import Loader from "@/components/Loader";
import store from "@/store";
import { DateTime } from "luxon";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  BButton,
  BFormDatepicker,
  BIconCheck,
} from "bootstrap-vue";

export default {
  name: "GeplanteArbeitenDetail",
  components: {
    MainNavigation,
    Loader,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BIconCheck,
  },
  store,
  data: function() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      todayForDatepickers: today,
      arbeit: {
        bemerkungen: "",
        verantwMA: "",
        cntKunden: 0,
        datum: "",
        dslam: "",
        id: "",
        kundenInfo: "",
        kundenInfoAm: "",
        kvz: "",
        ort: "",
        stromlos: false,
        vonBis: "",
        betroffeneKunden: null,
        ausfallWahrsch: 50,
        status: "geplant",
        erstelltAm: null,
        erstelltVon: ""
      },
      betrKundenOptionen: [
          { value: null, text: '- nichts -' },
          { value: 'IP Access', text: 'IP Access' },
          { value: 'Carrier', text: 'Carrier' },
          { value: 'DSL', text: 'DSL' },
      ],
      statusOptionen: [
          { value: 'geplant', text: 'geplant' },
          { value: 'begonnen', text: 'begonnen' },
          { value: 'beendet', text: 'beendet' },
          { value: 'verschoben', text: 'verschoben' },
      ],
      kundenInfoOptionen: [
          { value: null, text: '- nichts -' },
          { value: 'erfolgt', text: 'erfolgt' },
          { value: 'nicht erfolgt', text: 'nicht erfolgt' },
      ],
      saveSuccess: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    datepickerDate: {
      get() {
        return DateTime.fromISO(this.arbeit.datum).toISODate();
      },
      set(date) {
        this.arbeit.datum = date;
      },
    },
    datepickerKundenInfoAm: {
      get() {
        return this.arbeit.kundenInfoAm ? DateTime.fromISO(this.arbeit.kundenInfoAm).toISODate() : "";
      },
      set(date) {
        this.arbeit.kundenInfoAm = date;
      },
    },
  },
  methods: {
    save() {
      this.arbeit.datum = this.formattedDate(this.arbeit.datum);
      this.arbeit.kundenInfoAm = this.formattedDate(this.arbeit.kundenInfoAm);
      isNaN(this.arbeit.cntKunden) ? (this.arbeit.cntKunden = 0) : null;
      GeplanteArbeitenApi.update(this.arbeit)
        .then(response => {
          this.saveSuccess = true;
          // set this new id!
          this.arbeit.id = response.data.arbeit.id;
        })
        .catch(error => {
          this.saveSuccess = false;
          alert("Beim speichern ist ein Fehler aufgetreten.");
          console.error(error);
        });
    },
    loeschen() {
      if (!confirm("Den Eintrag wirklich löschen?")) return;
      GeplanteArbeitenApi.delete(this.arbeit.id)
        .then(response => {
          //this.saveSuccess = true;
          if (response.status === 200) {
            this.$nextTick(() => {
              this.$router.go(-1);
            });
          }
        })
        .catch(error => {
          //this.saveSuccess = false;
          alert("Beim löschen ist ein Fehler aufgetreten.");
          console.error(error);
        });
    },
    loadGeplanteArbeit() {
      if (
        !(this.$route.params.id.localeCompare("neu") === 0 || this.$route.params.id.length === 0)
      ) {
        GeplanteArbeitenApi.getById(this.$route.params.id)
          .then(response => {
            this.arbeit = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      }
    },
    formattedDate(date) {
      if (date !== null) {
        return DateTime.fromISO(date)
          .setLocale("de")
          .toFormat("yyyy-MM-dd");
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.loadGeplanteArbeit();
  },
};
</script>

<style lang="scss" scoped></style>
