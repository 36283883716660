import { Api } from "."


export default {
  /**
   * 
   * @param {*} sort 
   * Sort by:
   * t - Datum
   * o - Ort
   * c - Anzahl Kunden
   * d - DSLAM
   * k - KVZ
   * 
   * @param {*} desc
   * Sort direction: descending?
   * false (default), true
   * 
   * @param {*} page
   * 0 (default) - Einträge 0-99
   * 1 - Einträge 100 - 199 
   * 
   */
  get: (sort,page,desc=false) => {
     return Api.get("geplante_arbeiten/", { params: {
      sort: sort,
      page: page,
      desc: desc
     } })
  },

  /**
   * @param {*} id
   * 
   */
  getById: (id) =>{
    return Api.get("geplante_arbeiten/", { params: { id: id}})
  },

  /**
   * @description Create a new 'geplanteArbeit' if id === neu
   * else update existing data
   */
  update: (geplanteArbeit) => {
     if (geplanteArbeit.id.length === 0)
       return Api.post("geplante_arbeiten/", geplanteArbeit)
     return Api.put("geplante_arbeiten/", geplanteArbeit)
  },

  /**
   * @description Delete geplante arbeit
   * @param {*} id 
   */
  delete: (id) => {
     // return Api.delete(`geplante_arbeiten/${id}`)
     return Api.delete("geplante_arbeiten/",{ params: { id: id}})
 }
}
