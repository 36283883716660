<template>
  <div>
    <b-table
      hover
      responsive
      :items="tickets"
      :fields="fields"
      :small="compact"
      :no-local-sorting="noLocalSorting"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @sort-changed="onSortChanged"
      @row-clicked="rowClicked"
      :busy.sync="isBusy"
      :sort-compare="sortCompare"
      class="scroll-x"
    >
      <!-- Spalten -->
      <template v-slot:table-colgroup="data">
        <col v-for="field in data.fields" :key="field.key" :style="{ width: columnWidth(field.key) }" />
      </template>

      <!-- Ticketnummer-Zelle -->
      <template v-slot:cell(ticketnr)="data">
        <b-badge :variant="statusVariant(data.item.status)" class="d-none d-sm-block" style="font-size: 14px">{{ data.item.ticketNr }}</b-badge>
      </template>
      <!-- Titel-Zelle -->
      <template v-slot:cell(titel)="data">
        <template v-if="!compact">
          <span :style="{ 'font-weight': isMobile ? 'normal' : 'bolder' }">{{ data.item.titel }}</span>
          <br />
          <b-badge v-if="data.item.sorte" size="sm" variant="light" class="mr-1">{{ data.item.sorte.titel }}</b-badge>
          <b-badge v-if="!isMobile" variant="light" size="sm">{{ data.item.ticketTyp == "VORQUALIFIZIERUNG" ? "vorqualifiziert" : "" }}</b-badge>
          <template v-if="isMobile">
            <b-badge :variant="statusVariant(data.item.status)" class="d-block">{{ statusTitel(data.item.status) }}</b-badge>
          </template>
          <b-badge v-if="!data.item.billingPausiertGesetzt" variant="danger" class="d-block">Billing-Storno nicht gesetzt</b-badge>
        </template>
        <template v-else>{{ data.item.titel }}</template>
      </template>
      <!-- Verantwortlicher-Zelle -->
      <template v-slot:cell(verantw)="data">
        <span v-if="data.item.verantwortlicherBenutzer">
          {{ data.item.verantwortlicherBenutzer.name }}
          <br />
          <small v-if="data.item.verantwortlicheRolle">{{ data.item.verantwortlicheRolle.titel }}</small>
        </span>
        <span v-else-if="data.item.verantwortlicheRolle">{{ data.item.verantwortlicheRolle.titel }}</span>
      </template>
      <!-- Wunschtermin -->
      <template v-slot:cell(termin)="data">
        <template v-if="data.item.termin">
          {{ formatDate(data.item.termin) }}
        </template>
      </template>
      <!-- Status-Zelle -->
      <template v-slot:cell(status)="data">
        <b-badge :variant="statusVariant(data.item.status)" class="d-block">{{ statusTitel(data.item.status) }}</b-badge>
        <b-badge v-if="data.item.kategorie === 'NELI' && !data.item.billingPausiertGesetzt" variant="danger" class="d-block"
          >Billing-Storno nicht gesetzt</b-badge
        >
      </template>

      <!-- Eingangsdatum-Zelle -->
      <template v-slot:cell(eingangsdatum)="data">
        <template v-if="data.item.erstelltAm">
          {{ formatDate(data.item.erstelltAm) }}
          <br />
          {{ formatTime(data.item.erstelltAm) }}
        </template>
      </template>

      <!-- Letzte-Aktion-Zelle -->
      <template v-slot:cell(letzteaktion)="data">
        <template v-if="data.item.letzteAktivitaet">
          {{ formatDate(data.item.letzteAktivitaet) }}
          <br />
          {{ formatTime(data.item.letzteAktivitaet) }}
        </template>
      </template>

      <!-- KVZ-Zelle -->
      <template v-slot:cell(kvz)="data">
        <span :style="{ 'font-weight': isMobile ? 'normal' : 'bolder' }" v-if="data.item.kvz && (data.item.kvz.netkomName || data.item.kvz.telekomName)">{{ data.item.kvz.netkomName }}, {{ data.item.kvz.telekomName }}</span>
        <b-badge v-if="status === 'ALLE' && isMobile" :variant="statusVariant(data.item.status)" class="d-block">{{ statusTitel(data.item.status) }}</b-badge>
      </template>
      <!-- Ort-Zelle -->
      <template v-slot:cell(ort)="data">
        <span style="font-weight: bolder">{{ data.item.kundenname }}</span>
        <br />
        <span v-if="data.item.strasse && strasseNotEmpty (data.item.strasse)">{{ data.item.strasse.plz }} {{ data.item.strasse.ort }}, {{ data.item.strasse.strasse }} {{ data.item.strasse.nr }}</span>
      </template>
      <!-- Aktion-Zelle -->
      <template v-slot:cell(actions)="data">
        <template v-if="$can('read', data.item) || refTicket">
          <b-button-group :size="compact ? 'xs' : 'sm'">
            <b-button v-if="!refTicket" variant="primary" :to="{ name: 'ticketdetail', params: { id: data.item.id } }">
              <b-icon-pencil v-if="$can('update', data.item)"></b-icon-pencil>
              <b-icon-info-circle v-else></b-icon-info-circle>
            </b-button>
            <b-button v-if="refTicket" variant="primary" @click="openRefTicket(data.item.id, data.item.hmac)">
              <b-icon-pencil v-if="$can('update', data.item)"></b-icon-pencil>
              <b-icon-info-circle v-else></b-icon-info-circle>
            </b-button>
            <b-button
              variant="light"
              @click="linkCustomer(data.item)"
              v-if="data.item.kategorie === 'MASTER' && kunde"
              title="Kunde am Masterticket hinterlegen"
            >
              <b-icon-bell></b-icon-bell>
            </b-button>
            <b-button variant="light" @click="togglePinned(data.item)" v-if="!compact && !isMobile">
              <b-icon-eye v-if="!data.item.pinned"></b-icon-eye>
              <b-icon-eye-slash v-if="data.item.pinned"></b-icon-eye-slash>
            </b-button>
          </b-button-group>
        </template>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BButton, BButtonGroup, BIconPencil, BIconInfoCircle, BIconEye, BIconEyeSlash, BIconBell, BBadge } from "bootstrap-vue";
import Tickets from "@/api/tickets";
import store from "@/store";
import { SET_TICKET_PINNED } from "@/store/mutations.type";
import { DateTime } from "luxon";

export default {
  components: {
    BTable,
    BButton,
    BButtonGroup,
    BIconPencil,
    BIconInfoCircle,
    BIconEye,
    BIconEyeSlash,
    BIconBell,
    BBadge,
  },
  store,
  props: {
    tickets: { type: Array, required: true },
    refTicket: Object, // Referenzticket für die Auflistung von Tickets zu gleichem Vertrag (kein Check auf Access, dafür mit HMAC)
    status: String,
    kunde: Object,
    compact: { type: Boolean, default: false },
    noLocalSorting: { type: Boolean, default: false },
    sortBy: { type: String, default: null },
    sortDesc: { type: Boolean, default: false },
  },
  data() {
    return {
      isMobile: !window.matchMedia("(min-width: 768px)").matches,
    };
  },
  watch: {
    sortBy() {
      this.$emit("sortBy", this.sortBy);
    },
    sortDesc() {
      this.$emit("sortDesc", this.sortDesc);
    },
  },
  computed: {
    fields() {
      let f = [];
      if (!this.isMobile) {
        f.push({
          key: "ticketnr",
          label: "Ticketnr.",
          sortable: true,
        });
      }
      if (!this.isMobile || this.compact) {
        f.push({
          key: "titel",
          label: "Titel",
          sortable: true,
        });
      }
      if (!this.compact && !this.isMobile) {
        f.push({
          key: "verantw",
          label: "Zugeordnet an",
          sortable: false,
        });
      }
      if (this.tickets.find(t => t.kategorie === "INSTALLER")) {
        f.push({
          key: "termin",
          label: "Wunschtermin",
          sortable: true,
        })
      }
      if (this.status === "ALLE" && !this.isMobile)
        f.push({
          key: "status",
          label: "Status",
          sortable: false,
        });
      // wenn !mobil, drucke vor Ort und KVZ
      if (!this.compact && !this.isMobile) {
        f.push({
          key: "eingangsdatum",
          label: "Eingangsdatum",
          sortable: true,
        });
        f.push({
          key: "letzteaktion",
          label: "Letzte Aktion",
          sortable: true,
        });
      }
      // KVZ nur anzeigen, wenn nicht alle Tickets Mastertickets sind
      if (this.tickets.find(t => t.kategorie != "MASTER")) {
        f.push({
          key: "kvz",
          label: "KVZ",
          sortable: true,
        });
      }
      if (!this.compact) {
        f.push({
          key: "ort",
          label: "Ort",
          sortable: true,
        });
      }
      // wenn mobil, drucke nach Ort und KVZ
      if (!this.compact && this.isMobile) {
        f.push({
          key: "eingangsdatum",
          label: "Eingangsdatum",
          sortable: true,
        });
        f.push({
          key: "letzteaktion",
          label: "Letzte Aktion",
          sortable: true,
        });
      }
      if (!this.isMobile) {
        f.push({
          key: "actions",
          label: !this.compact && !this.isMobile ? "Aktion" : "",
          sortable: false,
        });
      }
      return f;
    },
    isBusy() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    strasseNotEmpty(obj) {
      return obj.plz || obj.ort || obj.strasse || obj.nr;
    },
    columnWidth(key) {
      if (key === "actions") {
        if (this.isMobile) return "60px";
        if (!this.compact) return "120px";
      }
      if (key === "kvz") {
        if (this.isMobile) return "100px";
      }
      if (key === "sorte") {
        if (this.isMobile) return "60px";
      }
      if (key === "ticketnr") {
        if (this.isMobile) return "120px";
        if (!this.compact) return "190px";
      }
      if (key === "ort") {
        if (this.isMobile) return "100px";
      }
      return "auto";
    },
    checkMobile() {
      this.isMobile = !window.matchMedia("(min-width: 768px)").matches;
    },
    rowClicked(item) {
      if (this.$can("read", item) || this.refTicket) {
        if (!this.refTicket) {
          this.$router.push({ name: "ticketdetail", params: { id: item.id } });
        } else {
          this.openRefTicket(item.id, item.hmac);
        }
      }
    },
    openRefTicket(id, hmac) {
      let target = this.$router.resolve({
        name: "ticketdetail",
        params: { id: id },
        query: { ref: this.refTicket.id, hmac: hmac },
      });
      window.open(target.href, "_blank");
    },
    onSortChanged(evt) {
      this.$emit("sortChanged", evt);
    },
    togglePinned(ticket) {
      let pinned = !ticket.pinned;
      let self = this;
      Tickets.setPinned(ticket.id, pinned).then(response => {
        if (response.data.success) self.$store.commit(SET_TICKET_PINNED, [ticket.id, pinned]);
      });
    },
    statusVariant(status) {
      let s = this.$store.state.status.find(s => s.name === status);
      if (s) return s.variant;
      return "";
    },
    statusTitel(status) {
      let s = this.$store.state.status.find(s => s.name === status);
      if (s) return s.titel;
      return "";
    },
    daysAgo(date) {
      let today = DateTime.local();
      let then = DateTime.fromISO(date);
      return Math.floor(today.diff(then).as("days"));
    },
    formatDate(date) {
      return DateTime.fromISO(date)
        .setLocale("de")
        .toLocaleString({
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
    },
    formatTime(date) {
      return DateTime.fromISO(date)
        .setLocale("de")
        .toLocaleString({
          hour: "2-digit",
          minute: "2-digit",
        });
    },
    linkCustomer(data) {
      this.$bvModal.msgBoxConfirm("MEH?");
      Tickets.linkCustomer(
        data.ticketId,
        this.kunde.email,
        true,
        data.produkt.contractId,
        this.kunde.id,
        this.kunde.lastname,
        this.kunde.firstname
      ).then(() => {
        var p = this.modalprodukte.find(p => p.id === data.produkt.id);
        p.linked.push(data.ticketId);
      });
    },
    sortCompare(aRow, bRow, key) {
      var a, b;
      if (key === "ticketnr") {
        a = aRow.ticketNr || "";
        b = bRow.ticketNr || "";
      } else if (key === "titel") {
        a = aRow.titel || "";
        b = bRow.titel || "";
      } else if (key === "eingangsdatum") {
        a = aRow.erstelltAm || "";
        b = bRow.erstelltAm || "";
      } else if (key === "letzteaktion") {
        a = aRow.letzteAktivitaet || "";
        b = bRow.letzteAktivitaet || "";
      } else if (key === "ort") {
        a = aRow.strasse.plz + aRow.strasse.ort || "";
        b = bRow.strasse.plz + bRow.strasse.ort || "";
      } else {
        return false;
      }
      return a.localeCompare(b);
    },
  },
  created() {
    window.addEventListener("resize", this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>

<style lang="scss">
.btn-white {
  background-color: #ffffff;
  border-color: #d3d3d3;
}
.ticketNo {
  display: inline;
  font-size: 85%;
  letter-spacing: 0.1em;
  padding: 0.3em 0.6em 0.3em;
  background-color: var(--ticket_number);
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.status-label {
  display: block;
  padding: 0.3em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25em;
}
</style>
