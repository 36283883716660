<template>
  <b-table striped hover responsive :items="users" :fields="fields" :filter="filter">
    <template v-slot:table-colgroup="data">
      <col
        v-for="field in data.fields"
        :key="field.key"
        :style="{ width: field.key === 'aktion' ? '120px' : 'auto' }"
      />
    </template>
    <template v-slot:cell(name)="data">{{data.item.name}}</template>
    <template v-slot:cell(vorname)="data">{{data.item.vorname}}</template>
    <template v-slot:cell(mail)="data">{{data.item.email}}</template>
    <template v-slot:cell(aktion)="data">
      <b-button-group>
        <b-button variant="light" :to="`/einstellungen/benutzer/${data.item.id}`" size="sm">
          <b-icon-pencil></b-icon-pencil>
        </b-button>
        <b-button variant="danger" @click="onDeleteUser(data.item)" size="sm">
          <b-icon-trash></b-icon-trash>
        </b-button>
      </b-button-group>
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
  BButton,
  BButtonGroup,
  BIconPencil,
  BIconTrash
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BButton,
    BButtonGroup,
    BIconPencil,
    BIconTrash
  },
  name: "Benutzertabelle",
  props: {
    users: { type: Array, required: true },
    filter: { type: String }
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "vorname",
          label: "Vorname",
          sortable: true
        },
        {
          key: "mail",
          label: "E-Mail",
          sortable: true
        },
        {
          key: "aktion",
          label: "Aktionen",
          sortable: false
        }
      ]
    };
  },
  methods: {
    onDeleteUser(user) {
      this.$emit("delete-user", user);
    }
  }
};
</script>