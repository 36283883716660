<template>
  <div>
    <loader></loader>
    <main-navigation></main-navigation>
    <div class="container-fluid mt-5">
      <h1>Kundensuche</h1>
      <b-row align-v="end">
        <b-col cols="6" md="5">
          <b-form @submit="reloadKunden">
            <b-form-group label="Kd-Nummer">
              <b-form-input v-model="kdnr"></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="6" md="5">
          <b-form @submit="reloadKunden">
            <b-form-group label="Freitext">
              <b-form-input v-model="search"></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group>
            <b-button @click="reloadKunden" variant="primary" block
              >Suchen</b-button
            >
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <kundentabelle :kunden="kunden"></kundentabelle>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import Kunden from "@/api/customer";
import Kundentabelle from "@/components/tables/Kundentabelle";
import Loader from "@/components/Loader";
import {
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  name: "Kundensuche",
  components: {
    MainNavigation,
    Kundentabelle,
    Loader,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  data: function () {
    return {
      search: "",
      kdnr: this.$route.query.nr,
      kunden: [],
    };
  },
  computed: {},
  methods: {
    reloadKunden(evt) {
      if (evt) evt.preventDefault();
      Kunden.search(this.search, this.kdnr).then((response) => {
        this.kunden = response.data;
      });
    },
  },
  mounted() {
    this.$store.dispatch("loadTickets");
    if (this.kdnr) this.reloadKunden(null);
  },
};
</script>