import { Api } from "."

export default {
  getPortabfrageList: (contractNo) => {
    return Api.get("portabfrage/", { params: {contractNo: contractNo}})
  },
  createPortabfrage: (contractNo) => {
    return Api.post("portabfrage/", { contractNo: contractNo })
  },
}
