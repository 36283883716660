<template>
  <b-card class="m-1">
    <b-row>
      <b-col cols="12">
        <template v-for="(msg, id) in errors">
          <b-alert show variant="danger" :key="id">{{ msg | removeBr }}</b-alert>
        </template>
        <template v-for="(msg, id) in warnings">
          <b-alert show variant="warning" :key="id">{{ msg | removeBr }}</b-alert>
        </template>
      </b-col>
      <b-col cols="12" lg="6">
        <table class="table table-striped">
          <tr class="table-primary">
            <th colspan="2" scope="col">Kundendaten</th>
          </tr>
          <tr>
            <td>Kundennummer</td>
            <td>{{ kundendaten.kundennummer }}</td>
          </tr>
          <tr>
            <td>Vertragsnummer</td>
            <td>{{ kundendaten.vertragsnummer }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>{{ kundendaten.name }}</td>
          </tr>
          <tr>
            <td>KVz</td>
            <td>{{ kundendaten.kvz }}</td>
          </tr>
          <tr>
            <td>gebuchtes Produkt</td>
            <td>{{ kundendaten.geb_produkt }}</td>
          </tr>
          <tr>
            <td>provisioniertes Produkt</td>
            <td>{{ kundendaten.prov_produkt }}</td>
          </tr>
          <tr>
            <td>Vorgabe TAL</td>
            <td>{{ kundendaten.vorgabe_tal }} m</td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" lg="6">
        <table class="table table-striped">
          <tr class="table-primary">
            <th colspan="3">VLANs</th>
          </tr>
          <tr class="table-primary">
            <th scope="col">VLAN-ID</th>
            <th scope="col">MAC</th>
            <th scope="col">IP</th>
          </tr>
          <tr v-for="(v, id) in vlans" :key="id">
            <td>{{ id }}</td>
            <td>
              <div v-for="m in v.mac" :key="m">{{ m }}</div>
            </td>
            <td>
              <div v-for="i in v.ip4" :key="i">{{ i }}</div>
              <div v-for="i in v.ip6" :key="i">{{ i }}</div>
            </td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" lg="6">
        <table class="table table-striped">
          <tr class="table-primary">
            <th colspan="2" scope="col">Portwerte</th>
          </tr>
          <tr>
            <td>Portdescription</td>
            <td>{{ portwerte.description }}</td>
          </tr>
          <tr>
            <td>Sync-Status</td>
            <td>{{ portwerte.sync_status }}</td>
          </tr>
          <tr>
            <td>Sync</td>
            <td>{{ portwerte.sync ? "ja" : "nein" }}</td>
          </tr>
          <tr>
            <td>Syncwerte</td>
            <td>{{ portwerte.syncwerte.down }} / {{ portwerte.syncwerte.up }}</td>
          </tr>
          <tr>
            <td>Syncwerte-MAX</td>
            <td>
              {{ portwerte.syncwerte_max.down }} /
              {{ portwerte.syncwerte_max.up }}
            </td>
          </tr>
          <tr>
            <td>Sync seit</td>
            <td>{{ portwerte.sync_seit }}</td>
          </tr>
          <tr>
            <td>Noise Margin Down/Up</td>
            <td>{{ portwerte.noise_margin.down }} dB / {{ portwerte.noise_margin.up }} dB</td>
          </tr>
          <tr>
            <td>Vendor</td>
            <td>{{ portwerte.vendor }}</td>
          </tr>
          <tr>
            <td>MELT-Länge</td>
            <td>{{ portwerte.melt.melt_laenge }} m</td>
          </tr>
          <tr>
            <td>Längenunterschied A-/B-Ader</td>
            <td>{{ portwerte.melt.diff_ab_ader }}</td>
          </tr>
          <tr>
            <td>A-/B-Länge</td>
            <td>{{ portwerte.melt.a_laenge }} / {{ portwerte.melt.b_laenge }}</td>
          </tr>
          <tr>
            <td>A-/B-Widerstand</td>
            <td>{{ portwerte.melt.a_widerstand }} / {{ portwerte.melt.b_widerstand }}</td>
          </tr>
          <tr>
            <td>AB-/BA-Widerstand</td>
            <td>{{ portwerte.melt.ab_widerstand }} / {{ portwerte.melt.ba_widerstand }}</td>
          </tr>
        </table>
      </b-col>
      <b-col cols="12" lg="6">
        <table class="table table-striped">
          <tr class="table-primary">
            <th colspan="5">Fehlerzähler</th>
          </tr>
          <tr class="table-primary">
            <th rowspan="2">Wert</th>
            <th colspan="2">24 h</th>
            <th colspan="2">15 min</th>
          </tr>
          <tr class="table-primary">
            <th scope="col">Near-End</th>
            <th scope="col">Far-End</th>
            <th scope="col">Near-End</th>
            <th scope="col">Far-End</th>
          </tr>
          <tr>
            <td>CV</td>
            <td v-for="(v, index) in fehler.cv" :key="v + index.toString()">{{ v }}</td>
          </tr>
          <tr>
            <td>CEC</td>
            <td v-for="(v, index) in fehler.cec" :key="v + index.toString()">{{ v }}</td>
          </tr>
          <tr>
            <td>FEC</td>
            <td v-for="(v, index) in fehler.fec" :key="v + index.toString()">{{ v }}</td>
          </tr>
          <tr>
            <td>ES</td>
            <td v-for="(v, index) in fehler.es" :key="v + index.toString()">{{ v }}</td>
          </tr>
          <tr>
            <td>SES</td>
            <td v-for="(v, index) in fehler.ses" :key="v + index.toString()">{{ v }}</td>
          </tr>
          <tr>
            <td>FailedInit</td>
            <td v-for="(v, index) in fehler.failed" :key="v + index.toString()">{{ v }}</td>
          </tr>
          <tr>
            <td>FullInit</td>
            <td v-for="(v, index) in fehler.full" :key="v + index.toString()">{{ v }}</td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BAlert } from "bootstrap-vue";
import { v4 as uuid } from "uuid";
import { DateTime } from "luxon";

export default {
  name: "Portabfrage",
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
  },
  props: {
    portabfrage: Object,
    erstelltAm: String,
  },
  filters: {
    removeBr(val) {
      return val.replaceAll("<br>", " ").replaceAll("<br/>", " ");
    },
  },
  computed: {
    api() {
      if (!this.portabfrage && !this.portabfrage.api) return null;
      return this.portabfrage.api;
    },
    kundendaten() {
      return {
        kundennummer: this.api ? this.api.kundennummer : null,
        vertragsnummer: this.api ? this.api.vertragsnummer : null,
        name: this.portabfrage ? `${this.portabfrage.vorname} ${this.portabfrage.nachname}` : null,
        kvz: this.portabfrage ? `${this.portabfrage.kvz} ${this.portabfrage.address}` : null,
        geb_produkt: this.api && this.api.product ? this.api.product.name : null,
        prov_produkt: this.portabfrage ? this.portabfrage.speedprofile : null,
        vorgabe_tal: this.api && this.api.tal_length_dtag ? this.api.tal_length_dtag : null,
      };
    },
    vlans() {
      return this.api ? this.api.data_vlan : null;
    },
    portwerte() {
      let melt = {};

// API hat sich geändert (alt: 09.09.2021 11:33:34, erste nue erfolgreiche Portabfrage: 17.09.2021 14:55:03)
      if (this.erstelltAm && DateTime.fromISO(this.erstelltAm) < DateTime.fromSQL("2021-09-10")) {
        melt.melt_laenge = this.api && this.api.melt ? this.api.melt.tal_length_melt : '-';
        melt.diff_ab_ader = this.api && this.api.melt && this.api.melt.tal_length_abdiff_percent ? this.api.melt.tal_length_abdiff_percent + " %" : '-';
        melt.a_laenge = this.api && this.api.melt.c_rg ? this.api.melt.c_rg + " nF" : '-';
        melt.b_laenge = this.api && this.api.melt.c_tg ? this.api.melt.c_tg + " nF" : '-';
        melt.a_widerstand = this.api && this.api.melt.r_rg ? this.api.melt.r_rg + " \u2126" : '-';
        melt.b_widerstand = this.api && this.api.melt.r_tg ? this.api.melt.r_tg + " \u2126" : '-';
        melt.ba_widerstand = this.api && this.api.melt.r_tr ? this.api.melt.r_tr + " \u2126" : '-';
        melt.ab_widerstand = this.api && this.api.melt.r_rt ? this.api.melt.r_rt + " \u2126" : '-';
      } else {
        melt.melt_laenge = this.api ? this.api.tal_length_melt : '-';
        melt.diff_ab_ader = this.api && this.api.tal_length_abdiff_percent ? this.api.tal_length_abdiff_percent + " %" : '-';
        melt.a_laenge = this.api && this.api.melt_c_rg ? this.api.melt_c_rg + " nF" : '-';
        melt.b_laenge = this.api && this.api.melt_c_tg? this.api.melt_c_tg + " nF" : '-';
        melt.a_widerstand = this.api && this.api.melt_r_rg? this.api.melt_r_rg + " \u2126" : '-';
        melt.b_widerstand = this.api && this.api.melt_r_tg? this.api.melt_r_tg + " \u2126" : '-';
        melt.ba_widerstand = this.api && this.api.melt_r_tr? this.api.melt_r_tr + " \u2126" : '-';
        melt.ab_widerstand = this.api && this.api.melt_r_rt? this.api.melt_r_rt + " \u2126" : '-';
      }

      return {
        description: this.portabfrage.portdesc,
        sync_status: this.api.dsl_state,
        sync: this.portabfrage.sync.state,
        syncwerte: {
          up: this.portabfrage.sync.up,
          down: this.portabfrage.sync.down,
        },
        syncwerte_max: {
          up: this.portabfrage.sync.upmax,
          down: this.portabfrage.sync.downmax,
        },
        sync_seit: this.portabfrage.sync.time,
        noise_margin: {
          up: this.portabfrage.sync.noisemarginup,
          down: this.portabfrage.sync.noisemargindown,
        },
        vendor: this.portabfrage.sync.vendor,
        melt,
      };
    },
    getUuid() {
      return uuid();
    },
    warnings() {
      return this.portabfrage.warnings;
    },
    errors() {
      return this.portabfrage.errors;
    },
    fehler() {
      return {
        cv: [
          this.portabfrage.fehler["24"].CV.near,
          this.portabfrage.fehler["24"].CV.far,
          this.portabfrage.fehler["15"].CV.near,
          this.portabfrage.fehler["15"].CV.far,
        ],
        cec: [
          this.portabfrage.fehler["24"].CEC.near,
          this.portabfrage.fehler["24"].CEC.far,
          this.portabfrage.fehler["15"].CEC.near,
          this.portabfrage.fehler["15"].CEC.far,
        ],
        fec: [
          this.portabfrage.fehler["24"].FEC.near,
          this.portabfrage.fehler["24"].FEC.far,
          this.portabfrage.fehler["15"].FEC.near,
          this.portabfrage.fehler["15"].FEC.far,
        ],
        es: [
          this.portabfrage.fehler["24"].ES.near,
          this.portabfrage.fehler["24"].ES.far,
          this.portabfrage.fehler["15"].ES.near,
          this.portabfrage.fehler["15"].ES.far,
        ],
        ses: [
          this.portabfrage.fehler["24"].SES.near,
          this.portabfrage.fehler["24"].SES.far,
          this.portabfrage.fehler["15"].SES.near,
          this.portabfrage.fehler["15"].SES.far,
        ],
        failed: [
          this.portabfrage.fehler["24"].FailedInit.near,
          this.portabfrage.fehler["24"].FailedInit.far,
          this.portabfrage.fehler["15"].FailedInit.near,
          this.portabfrage.fehler["15"].FailedInit.far,
        ],
        full: [
          this.portabfrage.fehler["24"].FullInit.near,
          this.portabfrage.fehler["24"].FullInit.far,
          this.portabfrage.fehler["15"].FullInit.near,
          this.portabfrage.fehler["15"].FullInit.far,
        ],
      };
    },
  },
};
</script>
