var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"responsive":"","hover":"","items":_vm.kunden,"fields":_vm.fields,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(data){return _vm._l((data.fields),function(field){return _c('col',{key:field.key})})}},{key:"cell(number)",fn:function(data){return [_c('div',{class:{ locked: data.item.locked },attrs:{"title":'organice: ' + data.item.id}},[(data.item.locked)?_c('div',{staticClass:"locked_msg"},[_vm._v(" Kunde ist gesperrt ")]):_vm._e(),_c('div',[_c('b',[_vm._v(_vm._s(data.item.number))])]),_c('div',[_vm._v(" "+_vm._s(data.item.salutation)+" "+_vm._s(data.item.firstname)+" "+_vm._s(data.item.lastname)+" ")]),(data.item.company)?_c('div',[_vm._v(_vm._s(data.item.company))]):_vm._e()])]}},{key:"cell(zipcode)",fn:function(data){return [_c('div',{class:{ locked: data.item.locked },attrs:{"title":_vm.getAdditionalContactInfos(data.item)}},[_c('div',[_vm._v(_vm._s(data.item.zipcode)+" "+_vm._s(data.item.city))]),_c('div',[_vm._v(" "+_vm._s(data.item.street)+" "+_vm._s(data.item.streetNumber)+_vm._s(data.item.streetNumberAddon)+" ")])])]}},{key:"cell(kvzstreet)",fn:function(data){return [(data.item.kvzstreet && data.item.kvzstreet.vertriebsgebiet)?_c('div',{attrs:{"title":_vm.getAdditionalKvzInfos(data.item)}},[_vm._v(" "+_vm._s(data.item.kvzstreet.vertriebsgebiet.titel)+" ")]):_c('div',{staticClass:"locked_msg"},[_vm._v("Standort unbekannt")])]}},{key:"cell(creationdate)",fn:function(data){return [_vm._v(_vm._s(data.item.creationdate))]}},{key:"cell(accountManager)",fn:function(data){return [_vm._v(_vm._s(data.item.accountManager))]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-detail",modifiers:{"modal-detail":true}}],on:{"click":function($event){_vm.selectedKunde = data.item;
          _vm.loadProdukte(data.item);}}},[_c('b-icon-checklist')],1)]}}])}),_c('b-modal',{attrs:{"id":"modal-detail","size":"xl","title":"Tickets","ok-only":"","ok-title":"Schließen","header-text-variant":"light","header-bg-variant":"primary","reloadKunden":"","no-close-on-backdrop":true},on:{"show":_vm.resetModal}},[(!_vm.selectedProdukt)?[(
          _vm.selectedKunde &&
          _vm.selectedKunde.masterTickets &&
          _vm.selectedKunde.masterTickets.length > 0
        )?[_c('h5',[_vm._v("Mastertickets")]),_c('tickettabelle',{attrs:{"tickets":_vm.selectedKunde.masterTickets,"compact":true}})]:_vm._e(),(
          _vm.selectedKunde &&
          _vm.selectedKunde.kvzTickets &&
          _vm.selectedKunde.kvzTickets.length > 0
        )?[_c('h5',[_vm._v("Tickets im KVZ")]),_c('tickettabelle',{attrs:{"tickets":_vm.selectedKunde.kvzTickets,"compact":true}})]:_vm._e(),(
          _vm.selectedKunde &&
          _vm.selectedKunde.ownTickets &&
          _vm.selectedKunde.ownTickets.length > 0
        )?[_c('h5',[_vm._v("Eigene Tickets")]),_c('tickettabelle',{attrs:{"tickets":_vm.selectedKunde.ownTickets,"compact":true}})]:_vm._e(),_c('h5',[_vm._v("Produkte")]),(_vm.loading)?_c('div',[_c('b-spinner',{staticStyle:{"width":"25px","height":"25px"},attrs:{"variant":"primary","label":"Lädt..."}}),_vm._v("  Frage Produkte in Organice ab... ")],1):_vm._e(),_c('b-card-group',{attrs:{"deck":""}},_vm._l((_vm.modalprodukte),function(p){return _c('produktcard',{key:p.id,attrs:{"produkt":p,"addTicketBtn":true,"showContractNumber":true,"masterTickets":_vm.selectedKunde.masterTickets},on:{"link-customer":_vm.openMasterticketReceiver,"create-ticket":_vm.selectProdukt}})}),1)]:_vm._e()],2),(_vm.selectedKunde)?_c('masterticket-empfaenger-modal',{attrs:{"selectedKunde":_vm.selectedKunde,"ticketTitle":_vm.selectedMasterticket ? _vm.selectedMasterticket.titel : ''},on:{"benachrichtigung":_vm.linkCustomer}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }