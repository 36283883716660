import Vue from 'vue'
import VueRouter from 'vue-router'
import { abilitiesPlugin } from '@casl/vue'
import App from '@/App.vue'
import router from '@/Router'
import store from '@/store'
import 'jquery'
import "bootstrap/js/src/modal"
import { BootstrapVue, BootstrapVueIcons, BVToastPlugin, BVModalPlugin } from 'bootstrap-vue'
import vueDebounce from 'vue-debounce'
import JsonView from 'jsonview-vue';
import vuelidate from 'vuelidate'

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faDownload)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(abilitiesPlugin, store.state.ability)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(BVToastPlugin)
Vue.use(BVModalPlugin)
Vue.use(JsonView);
Vue.use(vuelidate);

// Setting a default timer This is set to '300ms' if not specified
Vue.use(vueDebounce, { defaultTime: '500ms' })

new Vue({
  store,
  router,
  render: h => h(App),
  created: function () {

  }
}).$mount('#app')
