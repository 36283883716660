<template>
  <b-table
    striped
    hover
    :items="ausfaelle"
    :fields="fields"
    :filter="filter"
    :busy="isBusy"
  >
    <template v-slot:table-colgroup="data">
      <col v-for="field in data.fields" :key="field.key" />
    </template>
    <template v-slot:cell(von)="data">
      <template v-if="data.item.von"
        >{{ formatDate(data.item.von) }}
        {{ formatTime(data.item.von) }} Uhr</template
      >
    </template>
    <template v-slot:cell(bis)="data">
      <template v-if="data.item.bis"
        >{{ formatDate(data.item.bis) }}
        {{ formatTime(data.item.bis) }} Uhr</template
      >
    </template>
    <template v-slot:cell(plz)="data">{{ data.item.plz }}</template>
    <template v-slot:cell(meldung)="data">{{ data.item.meldung }}</template>
    <template v-slot:cell(cnt)="data">{{ data.item.cnt }}</template>
  </b-table>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { DateTime } from "luxon";

export default {
  components: {
    BTable,
  },
  name: "GlobalOfficeTabelle",
  props: {
    ausfaelle: { type: Array, required: true },
    filter: { type: String },
  },
  data() {
    return {
      fields: [
        {
          key: "von",
          label: "Erstes Auftreten",
          sortable: false,
        },
        {
          key: "bis",
          label: "Letztes Auftreten",
          sortable: false,
        },
        {
          key: "plz",
          label: "PLZ",
          sortable: false,
        },
        {
          key: "meldung",
          label: "Störung",
          sortable: false,
        },
        {
          key: "cnt",
          label: "Anzahl",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    isBusy() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date).setLocale("de").toLocaleString({
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
    formatTime(date) {
      return DateTime.fromISO(date).setLocale("de").toLocaleString({
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>