export const API_START_LOADING = "startLoading"
export const API_FINISH_LOADING = "finishLoading"
export const SET_TICKETS = "setTickets"
export const SET_ARCHIVE = "setArchiveTickets"
export const RESET_ARCHIVE = "resetArchiveTickets"

export const SET_TICKET = "setTicket"
export const SET_TICKET_PINNED = "setTicketPinned"
export const SET_TICKET_VIEWED = "setTicketViewed"
export const SET_KATEGORIEN = "setKategorien"
export const SET_CUSTOMER = "setCustomer"
export const SET_USER = "setUser"
export const SET_USERS = "setUsers"
export const SET_MELDUNGSPOSITIONEN = "setMeldungspositionen"