<template>
  <div class="container">
    <template v-if="kvzausfallliste && $can('manage', 'KvzAusfLstSettings')">
      <b-button variant="light" class="mt-4" @click="$router.go(-1)">
        <b-icon-chevron-left></b-icon-chevron-left>&nbsp;Zur Liste
      </b-button>
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Vorlage"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label-cols-sm="3"
            label="Titel:"
            label-align-sm="right"
            label-for="titel"
          >
            <b-form-input
              id="titel"
              v-model="kvzausfallliste.titel"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Mindestens ... Prozent der Kunden am KVZ betroffen:"
            label-align-sm="right"
            label-for="xkundenamkvz"
          >
            <b-form-input
              id="xkundenamkvz"
              type="number"
              v-model="kvzausfallliste.xKundenAmKVZ"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Mindestens ... Prozent der Kunden im kompletten Ort betroffen:"
            label-align-sm="right"
            label-for="xkundenimort"
          >
            <b-form-input
              id="xkundenimort"
              type="number"
              v-model="kvzausfallliste.xKundenImOrt"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Liste von SMS Nummern:"
            label-align-sm="right"
            label-for="smsnummern"
          >
            <b-form-tags
              input-id="smsnummern"
              v-model="kvzausfallliste.smsnummern"
              input-type="tel"
              tag-variant="primary"
              tag-pills
              :separator="[' ', ',']"
              add-button-text="+"
              placeholder="Mobilnummer eingeben..."
              duplicate-tag-text="Doppelter Eintrag"
            ></b-form-tags>
          </b-form-group>
                    <b-form-group
            label-cols-sm="3"
            label="Liste von Mailadressen:"
            label-align-sm="right"
            label-for="mailadressen"
          >
            <b-form-tags
              input-id="mailadressen"
              v-model="kvzausfallliste.mailadressen"
              input-type="email"
              tag-variant="primary"
              tag-pills
              :separator="[' ', ',']"
              add-button-text="+"
              placeholder="Mailadresse eingeben..."
              duplicate-tag-text="Doppelter Eintrag"
            ></b-form-tags>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Wochentage:"
            label-align-sm="right"
            label-for="weekdays"
          >
            <b-form-checkbox-group
              v-model="kvzausfallliste.wochentage"
              :options="wochentage"
              id="weekdays"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Uhrzeit von:"
            label-align-sm="right"
            label-for="clockfrom"
          >
            <b-form-input
              id="clockfrom"
              type="time"
              v-model="kvzausfallliste.von"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Uhrzeit bis:"
            label-align-sm="right"
            label-for="clockuntil"
          >
            <b-form-input
              id="clockuntil"
              type="time"
              v-model="kvzausfallliste.bis"
            ></b-form-input>
          </b-form-group>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button :variant="savedInfo || 'primary'" @click="saveDaten">
              <template v-if="savedInfo == 'success'">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else-if="savedInfo == 'danger'">
                <b-icon-x aria-hidden="true"></b-icon-x>Nicht gespeichert
              </template>
              <template v-else>Speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>
    </template>
    <template v-else-if="$can('manage', 'KvzAusfLstSettings')">
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Systemeinstellungen"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-row>
            <b-col cols="12" sm="6">
              <b-form-group
                label="Kunden mit Totalausfall pro KVZ (in %)"
                label-class="font-weight-bold pt-1"
                label-align-sm="center"
                class="mb-0"
              >
                <b-form-group
                  label-cols="9"
                  label="Alarm über:"
                  label-align-sm="right"
                  label-for="upperKvzLimit"
                >
                  <b-form-input
                    id="upperKvzLimit"
                    type="number"
                    v-model="upperKvzLimit"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols="9"
                  label="Entwarnung unter:"
                  label-align-sm="right"
                  label-for="lowerKvzLimit"
                >
                  <b-form-input
                    id="lowerKvzLimit"
                    type="number"
                    v-model="lowerKvzLimit"
                  ></b-form-input>
                </b-form-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group
                label="Kunden mit Totalausfall pro Ort (in %)"
                label-class="font-weight-bold pt-1"
                label-align-sm="center"
                class="mb-0"
              >
                <b-form-group
                  label-cols="9"
                  label="Alarm über:"
                  label-align-sm="right"
                  label-for="upperCityLimit"
                >
                  <b-form-input
                    id="upperCityLimit"
                    type="number"
                    v-model="upperCityLimit"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols="9"
                  label="Entwarnung unter:"
                  label-align-sm="right"
                  label-for="lowerCityLimit"
                >
                  <b-form-input
                    id="lowerCityLimit"
                    type="number"
                    v-model="lowerCityLimit"
                  ></b-form-input>
                </b-form-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6">
              <b-form-group
                label="Prozente erst ab minimaler Kundenanzahl"
                label-class="font-weight-bold pt-1"
                label-align-sm="center"
                class="mb-0"
              >
                <b-form-group
                  label-cols="9"
                  label="am Ort:"
                  label-align-sm="right"
                  label-for="minCustCountCity"
                >
                  <b-form-input
                    id="minCustCountCity"
                    type="number"
                    v-model="minCustCountCity"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols="9"
                  label="am KVZ:"
                  label-align-sm="right"
                  label-for="minCustCount"
                >
                  <b-form-input
                    id="minCustCount"
                    type="number"
                    v-model="minCustCount"
                  ></b-form-input>
                </b-form-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button
              :variant="savedSettingsInfo ? 'success' : 'primary'"
              @click="saveSettings"
            >
              <template v-if="savedSettingsInfo">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else>Einstellung speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>

      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Alarmierungslisten"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-row class="mt-3">
            <b-col cols="auto" class="mr-auto">
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Suche"
              ></b-form-input>
            </b-col>
            <b-col cols="auto">
              <b-button variant="primary" @click="createKvzAusfallliste"
                >Neue Alarmierung</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                striped
                fixed
                hover
                :items="kvzausfalllisten"
                :fields="fields"
                :filter="filter"
                class="mt-3"
              >
                <template v-slot:table-colgroup="data">
                  <col
                    v-for="field in data.fields"
                    :key="field.key"
                    :style="{
                      width: field.key === 'aktion' ? '120px' : 'auto',
                    }"
                  />
                </template>
                <template v-slot:cell(titel)="data">{{
                  data.item.titel
                }}</template>
                <template v-slot:cell(aktion)="data">
                  <b-button-group>
                    <b-button
                      variant="light"
                      :to="`/einstellungen/kvzausfall/${data.item.id}`"
                      size="sm"
                    >
                      <b-icon-pencil></b-icon-pencil>
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="deleteVorlage(data.item)"
                    >
                      <b-icon-trash></b-icon-trash>
                    </b-button>
                  </b-button-group>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form-group>
      </b-card>
    </template>
  </div>
</template>

<script>
import Kvzausfallliste from "@/api/kvzausfallliste";
import store from "@/store";

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormTags,
  BButton,
  BButtonGroup,
  BTable,
  BIconPencil,
  BIconChevronLeft,
  BIconCheck,
  BIconX,
  BIconTrash,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormTags,
    BButton,
    BButtonGroup,
    BTable,
    BIconPencil,
    BIconChevronLeft,
    BIconCheck,
    BIconX,
    BIconTrash,
  },
  store,
  name: "KVZAusfall",
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      kvzausfalllisten: [],
      lowerKvzLimit: null,
      upperKvzLimit: null,
      lowerCityLimit: null,
      upperCityLimit: null,
      minCustCount: null,
      minCustCountCity: null,
      savedInfo: null,
      savedSettingsInfo: null,
      filter: null,
      fields: [
        {
          key: "titel",
          label: "Titel",
          sortable: true,
        },
        {
          key: "von",
          label: "Von",
          sortable: false,
        },
        {
          key: "bis",
          label: "Bis",
          sortable: false,
        },
        {
          key: "aktion",
          label: "Aktionen",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    Kvzausfallliste.get().then((response) => {
      this.kvzausfalllisten = response.data.vorlagen;
      this.upperKvzLimit = response.data.upperLimit;
      this.lowerKvzLimit = response.data.lowerLimit;
      this.upperCityLimit = response.data.upperCityLimit;
      this.lowerCityLimit = response.data.lowerCityLimit;
      this.minCustCount = response.data.minCustCount;
      this.minCustCountCity = response.data.minCustCountCity;
    });
  },
  computed: {
    kvzausfallliste: {
      get() {
        if (!this.id) return null;
        return this.kvzausfalllisten.find((u) => u.id === this.id);
      },
      set(val) {
        let i = this.kvzausfalllisten.findIndex((u) => u.id === val.id);
        if (i > -1) {
          this.kvzausfalllisten[i] = val;
        } else {
          this.kvzausfalllisten.push(val);
        }
      },
    },
    wochentage: {
      get() {
        return [
          { text: "Montag", value: "Mo" },
          { text: "Dienstag", value: "Di" },
          { text: "Mittwoch", value: "Mi" },
          { text: "Donnerstag", value: "Do" },
          { text: "Freitag", value: "Fr" },
          { text: "Samstag", value: "Sa" },
          { text: "Sonntag", value: "So" },
        ];
      },
    },
  },
  methods: {
    createKvzAusfallliste() {
      let u = {
        id: "neu",
      };
      this.kvzausfalllisten.push(u);
      this.$router.push({ name: "kvz_ausfall", params: { id: "neu" } });
    },
    deleteKvzAusfallliste(kvzausfallliste) {
      if (!confirm("KVZ-Ausfall-Liste wirklich löschen?")) return;
      Kvzausfallliste.delete(kvzausfallliste.id).then(() => {
        let idx = this.kvzausfalllisten.findIndex(
          (r) => r.id === kvzausfallliste.id
        );
        if (idx > -1) this.kvzausfalllisten.splice(idx, 1);
      });
    },
    saveSettings() {
      Kvzausfallliste.updateGeneralSettings(
        this.lowerKvzLimit,
        this.upperKvzLimit,
        this.lowerCityLimit,
        this.upperCityLimit,
        this.minCustCount,
        this.minCustCountCity
      ).then((response) => {
        if (response.data && response.data.success) {
          this.savedSettingsInfo = "success";
        } else {
          this.savedSettingsInfo = "danger";
        }
        window.setTimeout(() => {
          this.savedSettingsInfo = null;
        }, 5000);
      });
    },
    saveDaten() {
      let self = this;
      Kvzausfallliste.update(this.kvzausfallliste).then((response) => {
        if (response.data && response.data.success) {
          this.savedInfo = "success";
          self.kvzausfallliste = response.data.kvzausfallliste;
          if (self.$route.params.id != response.data.kvzausfallliste.id) {
            // neue kvzausfallliste hat jetzt eine ID erhalten
            // kvzausfallliste mit ID "neu" entfernen und dann zur richtigen kvzausfallliste springen
            let idx = self.kvzausfalllisten.findIndex((r) => r.id === "neu");
            if (idx > -1) self.kvzausfalllisten.splice(idx, 1);
            this.$router.replace({
              name: "kvz_ausfall",
              params: { id: response.data.kvzausfallliste.id },
            });
          }
        } else {
          this.savedInfo = "danger";
        }
        window.setTimeout(() => {
          this.savedInfo = null;
        }, 5000);
      });
    },
  },
};
</script>
