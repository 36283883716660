<template>
  <div class="chart-container">
    <bar-chart v-if="loaded" :chartdata="chartData" :options="chartOptions"></bar-chart>
    <b-row v-else align-v="center" align-h="center" class="p-3">
      <b-col cols="auto">
        <b-spinner variant="primary" label="Lädt..." style="width: 10vw; height: 10vw"></b-spinner>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Api } from "@/api";

import BarChart from "./BarChart";
import ticketApi from "@/api/tickets";
import { DateTime } from "luxon";
import { Ticket } from "@/classes/entities";
import { BRow, BCol, BSpinner } from "bootstrap-vue";
import variables from "@/scss/_export.module.scss";

export default {
  name: "TicketKWChart",
  components: {
    BarChart,
    BRow,
    BCol,
    BSpinner,
  },
  props: {},
  data() {
    return {
      statisticData: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              id: "fehlerAxis",
              type: "linear",
              stacked: true,
              position: "left",
            },
            {
              id: "bearbeitungszeitAxis",
              type: "linear",
              stacked: false,
              position: "right",
            },
          ],
        },
        onClick: this.onPointClicked,
      },
      loaded: false,
    };
  },
  computed: {
    chartData() {
      let data = {
        labels: Object.keys(this.statisticData),
        datasets: [
          {
            type: "line",
            label: "Mittlere Bearbeitungszeit",
            borderColor: variables.primary,
            backgroundColor: "rgba(0,0,0,0)",
            data: Object.values(this.statisticData).map((v) => Math.round(v.bearbeitungszeit / (60 * 100)) / 10),
            lineTension: 0,
            borderWidth: 2,
            pointRadius: 1,
            yAxisID: "bearbeitungszeitAxis",
          },
        ],
      };

      let gruende = [];

      Object.values(this.statisticData).forEach((v) => {
        v.gruende.forEach((grund) => {
          if (gruende.indexOf(grund.grund) < 0) gruende.push(grund.grund);
        });
      });
      gruende.forEach((grund) => {
        let cnts = [];
        Object.values(this.statisticData).forEach((v) => {
          let g = v.gruende.find((g) => g.grund === grund);
          if (g) cnts.push(g.cnt);
          else cnts.push(0);
        });

        data.datasets.push({
          type: "bar",
          label: grund,
          data: cnts,
          backgroundColor: this.createHexColor(grund),
          yAxisID: "fehlerAxis",
        });
      });

      return data;
    },
  },
  methods: {
    onPointClicked(event, point) {
      let week = Object.keys(this.statisticData)[point[0]._index];
      ticketApi.getWeeklyStatisticList(week).then((response) => {
        this.$emit("point-clicked", [`Tickets der Woche ${week}`, response.data.tickets.map((e) => new Ticket(e))]);
      });
    },
    loadData() {
      let chart = this;
      let from = DateTime.local().minus({ weeks: 58 });
      ticketApi.getWeeklyStatistic(from.toFormat("yyyy-MM-dd")).then((response) => {
        chart.statisticData = response.data;
        chart.loaded = true;
      });
    },
    createHexColor(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var c = (hash & 0x00ffffff).toString(16).toUpperCase();
      return "#00000".substring(0, 7 - c.length) + c;
    },
    excel() {
      Api.get("excelreport/", { params: { type: "newkw" } }).then((response) => {
        if (response.data.status === "ok") {
          let filename = response.data.document;
          if (process.env.NODE_ENV === "development") filename = "https://tes-dev-tickets.batix.net" + filename;

          window.open(filename);
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
