import Vue from "vue";
import Vuex from "vuex";
import Rules from "./ability";
import { Ability } from "@casl/ability";
import Tickets from "@/api/tickets";
import { Ticket, Rolle } from "@/classes/entities";
import Kategorien from "@/api/kategorien";
import User from "@/api/user";
import apiPlugin from "@/api/plugin";

import {
  SET_USER,
  SET_TICKETS,
  SET_ARCHIVE,
  RESET_ARCHIVE,
  SET_TICKET,
  SET_TICKET_PINNED,
  SET_TICKET_VIEWED,
  SET_KATEGORIEN,
  API_START_LOADING,
  API_FINISH_LOADING,
  SET_USERS,
  SET_MELDUNGSPOSITIONEN,
} from "./mutations.type";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tickets: [],
    archive: [],
    archiveCnt: 0,
    status: [],
    sorten: [],
    gruende: [],
    textvorlagen: [],
    rollen: [],
    groups: [],
    meldungspositionen: [],
    users: [],
    user: null,
    ability: new Ability([]),
    loading: 0,
  },
  mutations: {
    [API_START_LOADING](state) {
      state.loading++;
    },
    [API_FINISH_LOADING](state) {
      state.loading--;
    },
    [SET_KATEGORIEN](state, val) {
      state.status = val.status.sort((a, b) => (a.order ? a.order : 99) - (b.order ? b.order : 99));
      state.sorten = val.sorten;
      state.rollen = val.rollen.map(e => new Rolle(e));
      state.gruppen = val.groups;
      state.gruende = val.gruende;
      state.textvorlagen = val.textvorlagen;
    },
    [SET_TICKETS](state, val) {
      state.tickets = val.map(e => new Ticket(e));
    },
    [SET_ARCHIVE](state, val) {
      state.archive = val.tickets.map(e => new Ticket(e));
      state.archiveCnt = val.count;
    },
    [RESET_ARCHIVE](state) {
      state.archive = [];
      state.archiveCnt = 0;
    },
    [SET_TICKET](state, val) {
      var index = state.tickets.map(e => e.id).indexOf(val.id);
      if (index !== -1) {
        state.tickets[index] = new Ticket(val);
      } else {
        state.tickets.push(new Ticket(val));
      }
    },
    [SET_TICKET_PINNED](state, [id, pinned]) {
      var index = state.tickets.findIndex(e => e.id === id);
      if (index !== -1) {
        state.tickets[index].pinned = pinned;
      }
    },
    [SET_TICKET_VIEWED](state, id) {
      var index = state.tickets.findIndex(e => e.id === id);
      if (index !== -1) {
        state.tickets[index].unviewed = false;
      }
    },
    [SET_USER](state, user) {
      state.user = user;
      // setze Berechtigungen für den aktuellen User im Ability-Object
      state.ability.update(Rules.defineRulesFor(state.user));
    },
    [SET_USERS](state, users) {
      state.users = users;
    },
    [SET_MELDUNGSPOSITIONEN](state, meldungspositionen) {
      state.meldungspositionen = meldungspositionen
    }
  },
  actions: {
    login({ commit }, [username, password]) {
      return new Promise((resolve, reject) => {
        User.login(username, password).then(response => {
          if (response.data.id) {
            commit(SET_USER, response.data);
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          if (error.response) {
            reject(error.response.status)
          } else {
            reject(null)
          }
        })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-unused-vars
        User.logout().then(response => {
          commit(SET_USER, null)
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    },
    loadTicket({ commit }, id) {
      Tickets.get(id).then(response => {
        commit(SET_TICKET, response.data);
      });
    },
    loadTickets({ commit }) {
      Tickets.get().then(response => {
        commit(SET_TICKETS, response.data);
      });
    },
    loadArchiveTickets({ commit }, [page, filter]) {
      let sortKey = null;
      let sortDesc = false;
      let filterParams = [];
      if (filter) {
        switch (filter.sortBy) {
          case "ticketnr":
            sortKey = "n";
            break;
          case "titel":
            sortKey = "t";
            break;
          case "eingangsdatum":
            sortKey = "e";
            break;
          case "ort":
            sortKey = "o";
            break;
          case "kvz":
            sortKey = "k";
            break;
          case "letzteaktion":
            sortKey = "l";
            break;
        }
        sortDesc = filter.sortDesc;
        filterParams.eigene = filter.eigene;
        filterParams.search = filter.search;
        filterParams.ungelesen = filter.ungelesen;
        filterParams.unzugeordnet = filter.unzugeordnet;
        filterParams.kat = filter.kategorie;
        if (filter.kvz) filterParams.kvz = filter.kvz.id;
        if (filter.rolle) filterParams.rolle = filter.rolle.map(e => e.id).join(",");
        if (filter.sorte) filterParams.sorte = filter.sorte.id;
      }
      Tickets.getArchive(page, sortKey, sortDesc, filterParams).then(response => {
        commit(SET_ARCHIVE, response.data);
      });
    },
    loadKategorien({ commit }) {
      Kategorien.get().then(response => {
        commit(SET_KATEGORIEN, response.data);
      }).catch(() => {});
    },
    loadUsers({ commit }) {
      User.getList().then((response) => {
        commit(SET_USERS, response.data);
      }).catch(() => {});
    },
    updateTicket({ commit }, [ticket, cb, errCb]) {
      Tickets.update(ticket).then(response => {
        if (response.data.ticket)
          commit(SET_TICKET, response.data.ticket);
        if (typeof cb === 'function') cb(response.data.success);
      }).catch(() => {
        if (typeof errCb === 'function') errCb();
      })
    },
    loadUser({ commit }) {
      User.getSelf().then(response => {
        commit(SET_USER, response.data);
      }).catch(() => {});
    },
    setTicketViewed({ commit }, id) {
      commit(SET_TICKET_VIEWED, id);
    },
    loadMeldungspositionen({ commit }) {
      apiPlugin.meldungspositionen.get().then(response => {
        commit(SET_MELDUNGSPOSITIONEN, response.data);
      }).catch(() => {});
    }
  },
  getters: {
    isLoginShown: state => {
      return state.login_show === true;
    },
    isLoading: state => {
      return state.loading > 0;
    },
    ticket: state => id => {
      return state.tickets.find(t => t.id === id);
    },
    tickets: state => (kategorie, status) => {
      // WENN ARCHIV ABGEFRAGT WIRD; GIB TICKETS AUS DEM ARCHIV ZURÜCK
      if (status !== "ARCHIV") {
        return state.tickets.filter(
          e =>
            (!kategorie || kategorie === "PINNED" || e.kategorie == kategorie) &&
            (kategorie !== "PINNED" || e.pinned) &&
            (!status || status === "ALLE" || e.status === status) &&
            state.ability.can("read", e)
        );
      } else {
        return state.archive.filter(
          e =>
            (!kategorie || kategorie === "PINNED" || e.kategorie == kategorie) &&
            (kategorie !== "PINNED" || e.pinned) &&
            (!status || status === "ALLE" || e.status === status) &&
            state.ability.can("read", e)
        );
      }
    },
    masterTickets: state => {
      return state.tickets.filter(e => e.kategorie === "MASTER");
    },
    kvzTickets: state => kvzid => {
      return state.tickets.filter(e => {
        e.kvz && e.kvz.id === kvzid;
      });
    },
    kundenTickets: state => kdid => {
      return state.tickets.filter(e => e.organiceKundenId === kdid);
    },
    sorten: state => () => {
      return state.sorten;
    },
    sorte: state => (id) => {
      return state.sorten.find(e => e.id === id);
    }
  },
});
