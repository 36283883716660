<template>
  <div class="container">
    <template v-if="vorlage && $can('manage', 'VorlagenSettings')">
      <b-button variant="light" class="mt-4" @click="$router.go(-1)">
        <b-icon-chevron-left></b-icon-chevron-left>&nbsp;Zur Liste
      </b-button>
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Vorlageneinstellungen"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group label-cols-sm="3" label="Titel:" label-align-sm="right" label-for="titel">
            <b-form-input id="titel" v-model="vorlage.titel"></b-form-input>
          </b-form-group>

          <b-form-group label-cols-sm="3" dropright label="Platzhalter:" label-align-sm="right" label-for="titel">
            <b-dropdown text="" dropright variant="primary" size="xs" class="mt-2">
              <b-dropdown-item v-for="ph in placeholder" :key="ph.label" @click="setPlaceholder(ph.value)">{{
                ph.value
              }}</b-dropdown-item>
            </b-dropdown>
            <b-toast
              id="copy-to-cb-toast"
              size="xs"
              title="Platzhalter wurde in Zwischenablage kopiert."
              auto-hide
            >
            </b-toast>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Text für Mail / Kommentar:"
            label-align-sm="right"
            label-for="mailtext"
          >
            <b-form-textarea id="mailtext" v-model="vorlage.mailtext" rows="8"></b-form-textarea>
          </b-form-group>
          <b-form-group label-cols-sm="3" label="Text für SMS:" label-align-sm="right" label-for="smstext">
            <b-form-textarea id="smstext" v-model="vorlage.smstext" rows="3"></b-form-textarea>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Gründe:"
            description="Gründe, bei denen diese Vorlage beim Abschluss auswählbar sein soll"
            label-align-sm="right"
            label-for="groups"
          >
            <b-form-checkbox-group
              v-model="vorlage.gruende"
              :options="gruende"
              id="gruende"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button :variant="savedInfo || 'primary'" @click="saveDaten">
              <template v-if="savedInfo == 'success'">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else-if="savedInfo == 'danger'">
                <b-icon-x aria-hidden="true"></b-icon-x>Nicht gespeichert
              </template>
              <template v-else>Speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>
    </template>
    <template v-else-if="$can('manage', 'VorlagenSettings')">
      <b-row class="mt-3">
        <b-col cols="auto" class="mr-auto">
          <b-form-input v-model="filter" type="search" placeholder="Suche"></b-form-input>
        </b-col>
        <b-col cols="auto">
          <b-button variant="primary" @click="createVorlage">Neue Vorlage</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped fixed hover :items="vorlagen" :fields="fields" :filter="filter" class="mt-3">
            <template v-slot:table-colgroup="data">
              <col
                v-for="field in data.fields"
                :key="field.key"
                :style="{ width: field.key === 'aktion' ? '120px' : 'auto' }"
              />
            </template>
            <template v-slot:cell(titel)="data">{{ data.item.titel }}</template>
            <template v-slot:cell(aktion)="data">
              <b-button-group>
                <b-button variant="light" :to="`/einstellungen/vorlagen/${data.item.id}`" size="sm">
                  <b-icon-pencil></b-icon-pencil>
                </b-button>
                <b-button variant="danger" size="sm" @click="deleteVorlage(data.item)">
                  <b-icon-trash></b-icon-trash>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import Vorlage from "@/api/vorlage";
import store from "@/store";
import Placeholder from "@/static/placeholder";

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckboxGroup,
  BButton,
  BButtonGroup,
  BTable,
  BIconPencil,
  BIconChevronLeft,
  BIconCheck,
  BIconX,
  BIconTrash,
  BDropdown,
  BDropdownItem,
  BToast,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckboxGroup,
    BButton,
    BButtonGroup,
    BTable,
    BIconPencil,
    BIconChevronLeft,
    BIconCheck,
    BIconX,
    BIconTrash,
    BDropdown,
    BDropdownItem,
    BToast,
  },
  store,
  name: "Rollen",
  data: function() {
    return {
      placeholder: [
        {
          label: `Ticketnummer`,
          value: Placeholder.TICKETNUMMER,
        },
        {
          label: `Nachname / Firma`,
          value: Placeholder.NACHNAME_FIRMA,
        },
        {
          label: `Anschrift`,
          value: Placeholder.ANSCHRIFT,
        },
      ],
      vorlagen: [],
      filter: null,
      savedInfo: null,
      fields: [
        {
          key: "titel",
          label: "Titel",
          sortable: true,
        },
        {
          key: "aktion",
          label: "Aktionen",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    vorlage: {
      get() {
        if (!this.$route.params.id) return null;
        return this.vorlagen.find(u => u.id === this.$route.params.id);
      },
      set(val) {
        let i = this.vorlagen.findIndex(u => u.id === val.id);
        if (i > -1) {
          this.vorlagen[i] = val;
        } else {
          this.vorlagen.push(val);
        }
      },
    },
    gruende: {
      get() {
        return this.$store.state.gruende.map(e => {
          return {
            text: e.titel,
            value: e.id,
          };
        });
      },
    },
  },
  mounted() {
    Vorlage.get().then(response => {
      this.vorlagen = response.data;
    });
  },
  methods: {
    createVorlage() {
      let u = {
        id: "neu",
      };
      this.vorlagen.push(u);
      this.$router.push({ name: "vorlagen", params: { id: "neu" } });
    },
    deleteVorlage(vorlage) {
      if (!confirm("Vorlage wirklich löschen?")) return;
      Vorlage.delete(vorlage.id).then(() => {
        let idx = this.vorlagen.findIndex(r => r.id === vorlage.id);
        if (idx > -1) this.vorlagen.splice(idx, 1);
      });
    },
    saveDaten() {
      let self = this;
      Vorlage.update(this.vorlage).then(response => {
        if (response.data && response.data.success) {
          this.savedInfo = "success";
          self.vorlage = response.data.vorlage;
          if (self.$route.params.id != response.data.vorlage.id) {
            // neue Vorlage hat jetzt eine ID erhalten
            // Vorlage mit ID "neu" entfernen und dann zur richtigen Vorlage springen
            let idx = self.vorlagen.findIndex(r => r.id === "neu");
            if (idx > -1) self.vorlagen.splice(idx, 1);
            this.$router.replace({
              name: "vorlagen",
              params: { id: response.data.vorlage.id },
            });
          }
        } else {
          this.savedInfo = "danger";
        }
        window.setTimeout(() => {
          this.savedInfo = null;
        }, 5000);
      });
    },
    setPlaceholder(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$bvToast.show("copy-to-cb-toast");
        })
        .catch(() => {
          alert(`'${text}' konnte nicht in die Zwischenablage kopiert werden.`);
        });
    },
  },
};
</script>
