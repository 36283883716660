import { render, staticRenderFns } from "./Formular.vue?vue&type=template&id=03f64c9a"
import script from "./Formular.vue?vue&type=script&lang=js"
export * from "./Formular.vue?vue&type=script&lang=js"
import style0 from "./Formular.vue?vue&type=style&index=0&id=03f64c9a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports