<template>
  <div>
    <b-row>
      <b-col class="pdr-0 chart-container">
        <bar-chart v-if="loaded" :chartdata="chartData" :options="chartOptions"></bar-chart>
        <b-row v-else align-v="center" align-h="center" class="p-3">
          <b-col cols="auto">
            <b-spinner
              variant="primary"
              label="Lädt..."
              style="width: 10vw; height: 10vw;"
            ></b-spinner>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="auto" class="btn-container">
        <chart-buttons-tage v-model="filter"></chart-buttons-tage>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Api } from "@/api";

import BarChart from "./BarChart";
import ticketApi from "@/api/tickets";
import ChartButtonsTage from "./ChartButtonsTage";
import { DateTime } from "luxon";
import { Ticket } from "@/classes/entities";
import { BRow, BCol, BSpinner } from "bootstrap-vue";
import variables from '@/scss/_export.module.scss';

export default {
  name: "TicketGeoChart",
  components: {
    BarChart,
    ChartButtonsTage,
    BCol,
    BRow,
    BSpinner,
  },
  props: {
    type: {
      type: String,
      default: "offen",
    },
  },
  data() {
    return {
      statisticData: {},
      loaded: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        onClick: this.onPointClicked,
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 1,
              },
            },
          ],
        },
      },
      tickets: [],
      filter: 0,
      fromDate: null,
      toDate: null,
    };
  },
  watch: {
    filter(val) {
      switch (val) {
        case 1:
          this.set7Tage();
          break;
        case 2:
          this.set30Tage();
          break;
        case 3:
          this.setLaufenderMonat();
          break;
        case 4:
          this.setLetzterMonat();
          break;
        case 5:
          this.set365Tage();
          break;
        // ...
      }
    },
  },
  computed: {
    chartData() {
      let namedLabels = Object.keys(this.statisticData).map(label => {
        let onkz = label.split("|")[0];
        let ort = label.split("|")[1];
        if (onkz === "null" && ort === "null") return "unbekannt";

        return `${ort}${onkz !== "null" ? ` (${onkz})` : ""}`;
      });
      return {
        labels: namedLabels,
        datasets: [
          {
            label: "Tickets",
            backgroundColor: namedLabels.map(l => this.createHexColor(l)),
            data: Object.values(this.statisticData),
            lineTension: 0,
            borderWidth: 1,
            pointBackgroundColor: variables.primary,
            pointRadius: 1,
          },
        ],
      };
    },
  },
  methods: {
    onPointClicked(event, point) {
      let label = Object.keys(this.statisticData)[point[0]._index];
      let onkz = label.split("|")[0];
      let ort = label.split("|")[1];
      ticketApi.getGeoStatisticList(this.fromDate, this.toDate, onkz, ort).then(response => {
        this.$emit("point-clicked", [
          "Tickets aus dem Bereich ",
          response.data.tickets.map(e => new Ticket(e)),
        ]);
      });
    },
    set7Tage() {
      this.fromDate = DateTime.local()
        .minus({ days: 7 })
        .toFormat("yyyy-MM-dd");
      this.toDate = null;
      this.loadChartdata();
    },
    set30Tage() {
      this.fromDate = DateTime.local()
        .minus({ days: 30 })
        .toFormat("yyyy-MM-dd");
      this.toDate = null;
      this.loadChartdata();
    },
    setLaufenderMonat() {
      this.fromDate = DateTime.local()
        .set({ day: 1 })
        .toFormat("yyyy-MM-dd");
      this.toDate = null;
      this.loadChartdata();
    },
    setLetzterMonat() {
      this.fromDate = DateTime.local()
        .set({ day: 1 })
        .minus({ months: 1 })
        .toFormat("yyyy-MM-dd");
      this.toDate = DateTime.local()
        .set({ day: 1 })
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd");
      this.loadChartdata();
    },
    set365Tage() {
      this.fromDate = DateTime.local()
        .minus({ days: 365 })
        .toFormat("yyyy-MM-dd");
      this.toDate = null;
      this.loadChartdata();
    },
    loadChartdata() {
      this.loaded = false;
      ticketApi.getGeoStatistic(this.fromDate, this.toDate).then(response => {
        this.statisticData = response.data;
        this.loaded = true;
      });
    },
    createHexColor(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var c = (hash & 0x00ffffff).toString(16).toUpperCase();
      return "#00000".substring(0, 7 - c.length) + c;
    },
    excel() {
      Api.get("excelreport/", {
        params: { type: "city", fromDate: this.fromDate, toDate: this.toDate },
      }).then(response => {
        if (response.data.status === "ok") {
          let filename = response.data.document;
          if (process.env.NODE_ENV === "development")
            filename = "https://tes-dev-tickets.batix.net" + filename;

          window.open(filename);
        }
      });
    },
  },
  mounted() {
    this.filter = 2;
  },
};
</script>
