<template>
  <div>
    <loader></loader>
    <main-navigation></main-navigation>
    <div class="container-fluid">
      <v-template v-if="$can('read', 'GlobalOffice')">
        <h1>GlobalOffice-Meldungen</h1>
        <b-row align-v="end">
          <b-col cols="auto" class="mr-auto">
            <b-form-input v-model="search" placeholder="Suche"></b-form-input>
          </b-col>
          <b-col cols="auto">
            <b-button
              @click="reloadAusfaelle($route.query.page)"
              variant="light"
              class="ml-2"
              title="Liste aktualisieren"
            >
              <b-icon-arrow-repeat></b-icon-arrow-repeat>
            </b-button>
          </b-col>
        </b-row>
        <b-row align-h="center" v-if="cnt > 100">
          <b-col cols="auto">
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="numberOfPages"
              use-router
            ></b-pagination-nav>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <global-office-tabelle
              :ausfaelle="ausfaelle"
              :filter="search"
              class="mt-3"
            ></global-office-tabelle>
          </b-col>
        </b-row>
        <b-row align-h="center" v-if="cnt > 100">
          <b-col cols="auto">
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="numberOfPages"
              use-router
            ></b-pagination-nav>
          </b-col>
        </b-row>
      </v-template>
    </div>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import GlobalOffice from "@/api/globaloffice";
import GlobalOfficeTabelle from "@/components/tables/GlobalOfficeTabelle";
import Loader from "@/components/Loader";
import {
  BCol,
  BRow,
  BIconArrowRepeat,
  BFormInput,
  BButton,
  BPaginationNav,
} from "bootstrap-vue";

export default {
  name: "GlobalOffice",
  components: {
    MainNavigation,
    GlobalOfficeTabelle,
    Loader,
    BCol,
    BRow,
    BIconArrowRepeat,
    BFormInput,
    BButton,
    BPaginationNav,
  },
  data: function () {
    return {
      search: "",
      ausfaelle: [],
      cnt: 0,
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.reloadAusfaelle(to.query.page);
    next();
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.cnt / 100);
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    reloadAusfaelle(page) {
      page = page || "1";
      page = parseInt(page) - 1;
      GlobalOffice.getAusfaelle(page).then((response) => {
        this.ausfaelle = response.data.ausfaelle;
        this.cnt = response.data.count;
      });
    },
  },
  mounted() {
    this.reloadAusfaelle();
  },
};
</script>