import { Api } from "."

export default {
  get: (id) => {
    if (id)
      return Api.get("sorte/", { params: { id: id } })
    else
      return Api.get("sorte/")
  },
  update: (sorte) => {
    if (sorte.id === 'neu')
      return Api.post("sorte/", sorte)
    return Api.put("sorte/", sorte)
  },
  delete: (id) => {
    return Api.delete("sorte/", { params: { id: id } })
  }
}
