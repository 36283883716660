<template>
  <div>
    <b-row>
      <b-col class="pdr-0 chart-container">
        <line-chart v-if="loaded" :chart-data="chartData" :options="chartOptions"></line-chart>
        <b-row v-else align-v="center" align-h="center" class="p-3">
          <b-col cols="auto">
            <b-spinner
              variant="primary"
              label="Lädt..."
              style="width: 10vw; height: 10vw"
            ></b-spinner>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6" md="auto" class="btn-container">
        <chart-buttons-tage v-model="filterTage"></chart-buttons-tage>
        <b-form-select
          class="element-max-width"
          id="vorlage-input"
          v-model="filterSortenId"
          :options="sortenParents"
          @change="loadChartdata"
        ></b-form-select>
      </b-col>
      <!-- <b-col cols="6" md="auto" class="btn-container">
        <chart-buttons-sorten-parents
          :sorten="sortenParents"
          v-model="filterSortenId"
        ></chart-buttons-sorten-parents>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { Api } from "@/api";

import LineChart from "./LineChart";
import ticketApi from "@/api/tickets";
import ChartButtonsTage from "./ChartButtonsTage";
// import ChartButtonsSortenParents from "./ChartButtonsSortenParents";
import { DateTime } from "luxon";
import { Ticket } from "@/classes/entities";
import { BCol, BRow, BSpinner, BFormSelect } from "bootstrap-vue";
import store from "@/store";
import variables from '@/scss/_export.module.scss';

export default {
  name: "TicketOffenChart",
  store,
  components: {
    LineChart,
    ChartButtonsTage,
    // ChartButtonsSortenParents,
    BCol,
    BRow,
    BSpinner,
    BFormSelect,
  },
  props: {
    type: {
      type: String,
      default: "offen",
    },
  },
  data() {
    return {
      statisticData: {},
      tickets: [],
      loaded: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        onClick: this.onPointClicked,
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 1,
              },
            },
          ],
        },
      },
      filterTage: 0,
      filterSortenId: null,
      fromDate: null,
      toDate: null,
    };
  },
  watch: {
    filterTage(val) {
      switch (val) {
        case 1:
          this.set7Tage();
          break;
        case 2:
          this.set30Tage();
          break;
        case 3:
          this.setLaufenderMonat();
          break;
        case 4:
          this.setLetzterMonat();
          break;
        case 5:
          this.set365Tage();
          break;
        // ...
      }
    },
  },
  computed: {
    chartData() {
      return {
        labels: Object.keys(this.statisticData).map(date =>
          DateTime.fromFormat(date, "yyyy-MM-dd").toFormat("dd.MM.")
        ),
        datasets: [
          {
            label: "Tickets",
            backgroundColor: variables.primaryHalfTransparent,
            data: Object.values(this.statisticData),
            lineTension: 0,
            borderColor: variables.primary,
            borderWidth: 1,
            pointBackgroundColor: variables.primary,
            pointRadius: 1,
          },
        ],
      };
    },
    sortenParents() {
      let sortenPartentsMapped = this.$store.getters
        .sorten()
        .filter(sp => sp.parent === null || sp.parent === "")
        .map(s => {
          return {
            text: s.titel,
            value: s.id,
          };
        });
      // füge option am Anfang ein, default:
      sortenPartentsMapped.unshift({ text: "Alle", value: null });
      return sortenPartentsMapped;
    },
  },
  methods: {
    onPointClicked(event, point) {
      let oberkategorieName = this.sortenParents.find(sp => sp.value === this.filterSortenId);
      ticketApi
        .getOpenTicketsStatisticList(
          Object.keys(this.statisticData)[point[0]._index],
          this.filterSortenId
        )
        .then(response => {
          this.$emit("point-clicked", [
            "Offene Tickets, Kategorie: " + oberkategorieName.text,
            response.data.tickets.map(e => new Ticket(e)),
          ]);
        });
    },
    set7Tage() {
      this.fromDate = DateTime.local()
        .minus({ days: 7 })
        .toFormat("yyyy-MM-dd");
      this.toDate = DateTime.local().toFormat("yyyy-MM-dd");
      this.loadChartdata();
    },
    set30Tage() {
      this.fromDate = DateTime.local()
        .minus({ days: 30 })
        .toFormat("yyyy-MM-dd");
      this.toDate = DateTime.local().toFormat("yyyy-MM-dd");
      this.loadChartdata();
    },
    setLaufenderMonat() {
      this.fromDate = DateTime.local()
        .set({ day: 1 })
        .toFormat("yyyy-MM-dd");
      this.toDate = DateTime.local().toFormat("yyyy-MM-dd");
      this.loadChartdata();
    },
    setLetzterMonat() {
      this.fromDate = DateTime.local()
        .set({ day: 1 })
        .minus({ months: 1 })
        .toFormat("yyyy-MM-dd");
      this.toDate = DateTime.local()
        .set({ day: 1 })
        .minus({ days: 1 })
        .toFormat("yyyy-MM-dd");
      this.loadChartdata();
    },
    set365Tage() {
      this.fromDate = DateTime.local()
        .minus({ days: 365 })
        .toFormat("yyyy-MM-dd");
      this.toDate = DateTime.local().toFormat("yyyy-MM-dd");
      this.loadChartdata();
    },
    loadChartdata() {
      this.loaded = false;
      // console.log("this.fromDate", this.fromDate);
      // console.log("this.toDate", this.toDate);
      // console.log("this.filterSortenId", this.filterSortenId);
      ticketApi
        .getOpenTicketsStatistic(this.fromDate, this.toDate, this.filterSortenId)
        .then(response => {
          this.statisticData = response.data;
          this.loaded = true;
        });
    },
    excel() {
      console.log("this.fromDate", this.fromDate);
      console.log("this.toDate", this.toDate);
      console.log("this.filterSortenId", this.filterSortenId);

      Api.get("excelreport/", {
        params: {
          type: "open",
          fromDate: this.fromDate,
          toDate: this.toDate,
          sortenParents: this.filterSortenId,
        },
      }).then(response => {
        if (response.data.status === "ok") {
          let filename = response.data.document;
          if (process.env.NODE_ENV === "development")
            filename = "https://tes-dev-tickets.batix.net" + filename;

          window.open(filename);
        }
      });
    },
  },
  mounted() {
    this.filterTage = 2;
  },
};
</script>
