<template>
  <b-modal
    :id="id"
    ref="archive-modal"
    header-bg-variant="primary"
    header-text-variant="light"
    title="Ticket abschließen"
    :ok-title="deleteMode ? 'Ticket löschen' : 'Ticket abschließen'"
    ok-variant="primary"
    cancel-title="Abbrechen"
    cancel-variant="light"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <template v-if="!deleteMode">
      <b-form-group
        label-for="grund"
        label="Abschlussgrund"
        :state="modalAbschlussgrundState"
        invalid-feedback="Abschlussgrund erforderlich"
      >
        <b-form-select
          ref="grund"
          id="grund"
          v-model="grund"
          :options="abschlussgruende"
          :state="modalAbschlussgrundState"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        label-for="billing"
        label="Billing fortsetzen ab"
        v-if="ticket && ticket.billingPausiert"
        :state="modalPausiertBisState"
        invalid-feedback="Datum muss gesetzt werden"
      >
        <b-form-datepicker
          id="billing"
          v-model="billingPausiertBis"
          right
          button-variant="light"
          v-bind="datepickerLabels"
          reset-button
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          locale="de"
          :state="modalPausiertBisState"
        ></b-form-datepicker>
      </b-form-group>

      <b-nav tabs class="mb-3">
        <b-nav-item @click="modalCommentType = 'comment'" :active="modalCommentType == 'comment'"
          >Kommentar</b-nav-item
        >
        <b-nav-item @click="modalCommentType = 'mail'" :active="modalCommentType == 'mail'"
          >E-Mail</b-nav-item
        >
        <b-nav-item @click="modalCommentType = 'sms'" :active="modalCommentType == 'sms'"
          >SMS</b-nav-item
        >
      </b-nav>

      <b-form-group
        v-if="modalReceiverType"
        :state="modalReceiverState"
        label="Empfänger"
        label-for="receiver-input"
        invalid-feedback="Empfänger erforderlich"
      >
        <!-- User input wenn Mail -->
        <template v-if="modalCommentType === 'mail'">
          <b-form-input
            ref="receiver-input"
            id="receiver-input"
            v-model="receiver"
            :state="modalReceiverState"
            :type="modalReceiverType"
            required
          ></b-form-input>
        </template>
        <template v-else>
          <!-- Droopdown wenn SMS -->
          <!-- <b-form-select
            ref="receiver-input"
            id="receiver-input"
            v-model="receiver"
            :options="smsReceivers"
            :state="modalReceiverState"
          ></b-form-select> -->
          <b-form-input
            ref="receiver-input"
            id="receiver-input"
            v-model="receiver"
            :state="modalReceiverState"
            :type="modalReceiverType"
            required
          ></b-form-input>
        </template>
      </b-form-group>
      <b-form-group
        :state="modalTextState"
        label-for="text-input"
        invalid-feedback="Text erforderlich"
      >
        <template slot="label">
          Text
          <b-dropdown
            text="Vorlage"
            variant="primary"
            class="float-right"
            size="sm"
            right
            style="margin-top: -8px"
            v-if="vorlagenBearbeitet.length > 0"
          >
            <b-dropdown-item-button
              v-for="v in vorlagenBearbeitet"
              :key="v.id"
              @click="insertVorlage(v)"
              >{{ v.titel }}</b-dropdown-item-button
            >
          </b-dropdown>
        </template>
        <b-form-textarea
          ref="text-input"
          id="text-input"
          v-model="modalTextInput"
          rows="6"
          :state="modalTextState"
          required
        ></b-form-textarea>
      </b-form-group>
      <b-alert v-if="modalError != null" show variant="danger">{{ modalError }}</b-alert>
      <!-- Kunden Benachrichtigen? Nur bei Masterticket -->
      <template v-if="isMasterTicket">
        <b-form-checkbox id="append-customers" v-model="informCustomer"
          ><strong>Angehängte Kunden benachrichtigen</strong></b-form-checkbox
        >
      </template>
    </template>
    <template v-else>
      <b-alert show variant="danger"
        >Ticket hat weder Titel noch Beschreibung! Es wird beim Abschließen gelöscht!
        Fortfahren?</b-alert
      >
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BAlert,
  BDropdown,
  BDropdownItemButton,
  BNav,
  BNavItem,
  BFormCheckbox,
} from "bootstrap-vue";
import Tickets from "@/api/tickets";
import store from "@/store";
import MixinWorker from "@/mixins/Worker";
import { v4 as uuid } from "uuid";

export default {
  name: "ArchivierenModal",
  store,
  mixins: [MixinWorker],
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BModal,
    BAlert,
    BDropdown,
    BDropdownItemButton,
    BFormSelect,
    BNav,
    BNavItem,
    BFormCheckbox,
  },
  props: {
    id: String,
    ticket: Object,
  },
  data() {
    return {
      billingPausiertBis: null,
      modalCommentType: "comment",
      modalReceiverInput: null,
      modalTextInput: null,
      modalReceiverState: null,
      modalTextState: null,
      modalAbschlussgrundState: null,
      modalPausiertBisState: null,
      modalError: null,
      grund: null,
      selectedVorlage: null,
      vorlagenBearbeitet: [],
      datepickerLabels: {
        labelPrevDecade: "Vorheriges Jahrzehnt",
        labelPrevYear: "Vorheriges Jahr",
        labelPrevMonth: "Vorheriger Monat",
        labelCurrentMonth: "Aktueller Monat",
        labelNextMonth: "Nächster Monat",
        labelNextYear: "Nächstes Jahr",
        labelNextDecade: "Nächstes Jahrzehnt",
        labelToday: "Heute",
        labelSelected: "Ausgewähltes Datum",
        labelNoDateSelected: "Nicht fortsetzen",
        labelCalendar: "Kalender",
        labelNav: "Kalendernavigation",
        labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
        labelResetButton: "Nicht fortsetzen",
      },
      informCustomer: true,
    };
  },
  computed: {
    isMasterTicket() {
      if (!this.ticket) {
        return false;
      }
      return this.ticket.kategorie === "MASTER";
    },
    receiver: {
      get() {
        if (this.modalReceiverInput) return this.modalReceiverInput;
        switch (this.modalCommentType) {
          case "sms":
            // finde mögliche Handynummer: 015, 016, 017
            if (this.ticket.rufnummer && this.checkHandynummer(this.ticket.rufnummer.replace(/\D/g, ""))) {
              return this.ticket.rufnummer.replace(/\D/g, "");
            }
            if (this.ticket.rufnummer2 && this.checkHandynummer(this.ticket.rufnummer2.replace(/\D/g, ""))) {
              return this.ticket.rufnummer2.replace(/\D/g, "");
            }
            return (this.ticket.rufnummer)? this.ticket.rufnummer.replace(/\D/g, ""):'';
          case "mail":
            return (this.ticket.email)?this.ticket.email:'';
          default:
            return null;
        }
      },
      set(val) {
        this.modalReceiverInput = val;
      },
    },
    // smsReceivers() {
    //   let rufnummern = [];
    //   (this.ticket.rufnummer != null)?rufnummern.push(this.ticket.rufnummer):null;
    //   (this.ticket.rufnummer2 != null)?rufnummern.push(this.ticket.rufnummer2):null;
    //   return rufnummern;
    // },
    abschlussgruende: {
      get() {
        return this.$store.state.gruende.map(e => {
          return { text: e.titel, value: e, disabled: false };
        });
      },
    },
    vorlagen: {
      get() {
        return this.$store.state.textvorlagen.filter(
          e => this.grund && e.gruende.indexOf(this.grund.id) > -1
        );
      },
    },
    modalReceiverType() {
      switch (this.modalCommentType) {
        case "sms":
          return "tel";
        case "mail":
          return "email";
        default:
          return null;
      }
    },
    deleteMode() {
      return this.ticket && !this.ticket.titel && !this.ticket.beschreibung;
    },
  },
  watch: {
    vorlagen(v) {
      // FOR EACH v[x].mailtext /.smstext / .titel --> replace placeholders
      this.vorlagenBearbeitet = [];
      v.forEach(el => {
        this.vorlagenBearbeitet.push({
          titel: el.titel,
          mailtext: this.replacePlaceholderTicket(el.mailtext, this.ticket),
          smstext: this.replacePlaceholderTicket(el.smstext, this.ticket),
        });
      });
    },
  },
  methods: {
    resetModal() {
      this.modalReceiverInput = null;
      this.modalTextInput = null;
      this.modalReceiverState = null;
      this.modalTextState = null;
      this.modalAbschlussgrundState = null;
      this.modalPausiertBisState = null;
      this.modalError = null;
    },
    insertVorlage(vorlage) {
      if (!this.modalTextInput) {
        this.modalTextInput = this.modalCommentType === "sms" ? vorlage.smstext : vorlage.mailtext;
      } else {
        this.modalTextInput += `\r${
          this.modalCommentType === "sms" ? vorlage.smstext : vorlage.mailtext
        }`;
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.modalError = null;
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      if (this.deleteMode) {
        this.ticket.status = "ARCHIV";
        this.$store.dispatch("updateTicket", [this.ticket]);
        // Hide the modal manually
        this.$nextTick(() => {
          this.$router.go(-1);
        });
      } else {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return;
        }
        this.ticket.billingPausiertBis = this.billingPausiertBis;

        if (this.modalCommentType === "comment") {
          Tickets.createFinalComment(this.ticket.id, this.modalTextInput).then(response => {
            if (response.data && response.data.success) {
              this.ticket.aktivitaeten.push(response.data.comment);
              this.updateTicket();
            } else {
              this.modalError = "Fehler beim Erstellen des Kommentars!";
            }
          });
        } else if (this.modalCommentType === "sms") {
          // Sende SMS wenn Empfänger.... Validierung, ob erforderlich wurde vorher geprüft
          console.log("SMS Empfänger:", this.receiver);
          if (this.receiver) {
            Tickets.sendSMS(this.ticket.id, this.receiver, this.modalTextInput).then(response => {
              if (response.data && response.data.success) {
                this.ticket.aktivitaeten.push(response.data.comment);
                this.updateTicket();
              } else {
                this.modalError = "Fehler beim Versenden der SMS!";
              }
            });
          } else {
            this.updateTicket();
          }
        } else if (this.modalCommentType === "mail") {
          console.log("Mail Empfänger:", this.receiver);
          // Sende Email wenn Empfänger.... Validierung, ob erforderlich wurde vorher geprüft
          if (this.receiver) {
            Tickets.sendMail(this.ticket.id, this.receiver, this.modalTextInput).then(response => {
              if (response.data && response.data.success) {
                this.ticket.aktivitaeten.push(response.data.comment); // nur bei Versand
                this.updateTicket();
              } else {
                this.modalError = "Fehler beim Versenden der E-Mail!";
              }
            });
          } else {
            this.updateTicket();
          }
        }
      }
    },
    updateTicket() {
      this.ticket.status = "ARCHIV";
      this.ticket.ticketabschlussText = this.modalTextInput;
      this.ticket.kundenBenachrichtigen = this.informCustomer;
      this.ticket.archivGrund = this.grund;
      this.$store.dispatch("updateTicket", [this.ticket]);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["archive-modal"].hide();
      });
    },
    checkFormValidity() {
      this.modalAbschlussgrundState = this.grund != null;
      this.modalPausiertBisState = !this.ticket.billingPausiert || this.billingPausiertBis != null;
      this.modalTextState = this.$refs["text-input"].checkValidity();
      if (this.modalReceiverType) {
        if (!this.isMasterTicket || (this.isMasterTicket && !this.informCustomer)) {
          this.modalReceiverState = this.$refs["receiver-input"].checkValidity();
        } else {
          this.modalReceiverState = true;
        }
        return (
          this.modalReceiverState &&
          this.modalTextState &&
          this.modalAbschlussgrundState &&
          this.modalPausiertBisState
        );
      } else {
        return this.modalTextState && this.modalAbschlussgrundState && this.modalPausiertBisState;
      }
    },
    getUuid() {
      return uuid();
    },
  },
};
</script>
