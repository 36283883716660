<template>
  <div>
    <div
      class="button-element"
      v-for="n in 5"
      :key="n"
      @click="val = n"
      :class="{active: val === n}"
    >
      <button>{{ getFilterName(n) }}</button>
      <br />
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "ChartButtonsTage",
  props: {
    value: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      val: this.value
    };
  },
  methods: {
    getFilterName(n) {
      switch (n) {
        case 1:
          return "7 Tage";
        case 2:
          return "30 Tage";
        case 3:
          return DateTime.local().toFormat("MMM. yyyy");
        case 4:
          return DateTime.local()
            .minus({ months: 1 })
            .toFormat("MMM. yyyy");
        case 5:
          return "365 Tage";
      }
    }
  },
  watch: {
    val(v) {
      this.$emit("input", v);
    },
    value(v) {
      this.val = v;
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  border: none;
  background: #ededed;
  color: #8b8b8b;
  font-weight: 400;
  font-size: 90%;
  width: 100%;
  outline: none;
  margin-bottom: 5px;
  padding: 5px 10px;
}
.button-element:first-child {
  margin-top: 16px;
}
.active button {
  background: var(--primary);
  color: #fff;
}
</style>