<template>
  <div class="container">
    <template v-if="role && $can('manage', 'RollenSettings')">
      <b-button variant="light" class="mt-4" @click="$router.go(-1)">
        <b-icon-chevron-left></b-icon-chevron-left>&nbsp;Zur Liste
      </b-button>
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Rolleneinstellungen"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label-cols-sm="3"
            label="Titel:"
            label-align-sm="right"
            label-for="titel"
          >
            <b-form-input id="titel" v-model="role.titel"></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="E-Mail:"
            label-align-sm="right"
            label-for="mail"
          >
            <b-form-input id="mail" v-model="role.email"></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Berechtigungen:"
            label-align-sm="right"
            label-for="groups"
          >
            <b-form-checkbox-group
              v-model="role.groups"
              :options="groups"
              id="groups"
              stacked
            ></b-form-checkbox-group>
            <b-alert show class="mt-2">
              <b>Hinweis:</b> Berechtigungen, die durch die Rolle gesetzt
              werden, können auf Benutzerebene nicht wieder entfernt werden.
            </b-alert>
          </b-form-group>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button :variant="savedInfo || 'primary'" @click="saveDaten">
              <template v-if="savedInfo == 'success'">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else-if="savedInfo == 'danger'">
                <b-icon-x aria-hidden="true"></b-icon-x>Nicht gespeichert
              </template>
              <template v-else>Speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>
    </template>
    <template v-else-if="$can('manage', 'RollenSettings')">
      <b-row class="mt-3">
        <b-col cols="auto" class="mr-auto">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Suche"
          ></b-form-input>
        </b-col>
        <b-col cols="auto">
          <b-button variant="primary" @click="createRole">Neue Rolle</b-button>
        </b-col>
      </b-row>
      <b-alert show class="mt-2" v-if="errMsg" variant="danger" dismissible>{{
        errMsg
      }}</b-alert>
      <b-row>
        <b-col>
          <rollentabelle
            :roles="roles"
            :filter="filter"
            @deleteRole="deleteRole"
            class="mt-3"
          ></rollentabelle>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import Rollentabelle from "@/components/tables/Rollentabelle";
import Role from "@/api/role";
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckboxGroup,
  BIconChevronLeft,
  BIconCheck,
  BIconX,
  BAlert,
} from "bootstrap-vue";

export default {
  components: {
    Rollentabelle,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckboxGroup,
    BIconChevronLeft,
    BIconCheck,
    BIconX,
    BAlert,
  },
  name: "Rollen",
  data: function () {
    return {
      roles: [],
      filter: null,
      savedInfo: null,
      errMsg: null,
    };
  },
  computed: {
    role: {
      get() {
        if (!this.$route.params.id) return null;
        return this.roles.find((u) => u.id === this.$route.params.id);
      },
      set(val) {
        let i = this.roles.findIndex((u) => u.id === val.id);
        if (i > -1) {
          this.roles[i] = val;
        } else {
          this.roles.push(val);
        }
      },
    },
    groups: {
      get() {
        return this.$store.state.gruppen.map((g) => {
          return { value: { id: g.id, name: g.name }, text: g.beschreibung };
        });
      },
    },
  },
  mounted() {
    Role.get().then((response) => {
      this.roles = response.data;
    });
  },
  methods: {
    createRole() {
      let u = {
        id: "neu",
      };
      this.roles.push(u);
      this.$router.push({ name: "rollen", params: { id: "neu" } });
    },
    deleteRole(role) {
      this.errMsg = null;
      if (!confirm("Rolle wirklich löschen?")) return;
      //let selfroles = this.roles;
      Role.delete(role.id).then((response) => {
        if (response.data.success) {
          let idx = this.roles.findIndex((r) => r.id === role.id);
          if (idx > -1) this.roles.splice(idx, 1);
        } else {
          this.errMsg =
            "Rolle konnte nicht gelöscht werden! Evtl. sind noch Benutzer oder Tickets darauf verplant.";
        }
      });
    },
    saveDaten() {
      let self = this;
      Role.update(this.role).then((response) => {
        if (response.data && response.data.success) {
          this.savedInfo = "success";
          self.role = response.data.role;
          if (self.$route.params.id != response.data.role.id) {
            // neue Rolle hat jetzt eine ID erhalten
            // Rolle mit ID "neu" entfernen und dann zur richtigen Rolle springen
            let idx = self.roles.findIndex((r) => r.id === "neu");
            if (idx > -1) self.roles.splice(idx, 1);
            this.$router.replace({
              name: "rollen",
              params: { id: response.data.role.id },
            });
          }
        } else {
          this.savedInfo = "danger";
        }
        window.setTimeout(() => {
          this.savedInfo = null;
        }, 5000);
      });
    },
  },
};
</script>