<template>
  <div>
    <main-navigation></main-navigation>
    <div class="container-fluid scope-style">
      <b-row class="mt-5">
        <b-col>
          <h1>Willkommen im Ticketsystem</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card-group deck>
            <b-col class="col-md-6">
              <b-card title="Nach Ticketnummer suchen" class="mt-3">
                <b-form @submit="searchTicketByNr">
                  <b-row>
                    <b-col>
                      <b-form-input v-model="ticketNr" placeholder="Ticket-Nr"></b-form-input>
                    </b-col>
                    <b-col cols="auto">
                      <b-button @click="searchTicketByNr" variant="primary">Suchen</b-button>
                    </b-col>
                  </b-row>
                </b-form>
                <b-alert class="mt-3" :show="ticketNrErr != null" variant="danger">{{
                  ticketNrErr
                }}</b-alert>
              </b-card>

              <b-card title="Nach Kundennummer suchen" class="mt-3" v-if="$can('read', 'Kunde')">
                <b-form @submit="searchCustomer">
                  <b-row>
                    <b-col>
                      <b-form-input v-model="kdnr" placeholder="Kunden-Nr"></b-form-input>
                    </b-col>
                    <b-col cols="auto">
                      <b-button @click="searchCustomer" block variant="primary">Suchen</b-button>
                    </b-col>
                  </b-row>
                </b-form>
                <b-alert class="mt-3" :show="kdnrErr != null" variant="danger">{{
                  kdnrErr
                }}</b-alert>
              </b-card>
            </b-col>
            <b-col class="col-md-6">
              <b-card title="Ticket erstellen" class="mt-3" v-if="$can('read', 'Kunde')">
                <b-row>
                  <b-col class="align-self-start" cols="auto">
                    <b-button variant="primary" :to="{ name: 'kundensuche' }"
                      >Ticket erstellen</b-button
                    >
                  </b-col>
                </b-row>
              </b-card>

              <b-card title="Portabfrage" class="mt-3" v-if="$can('create', 'Portabfrage')">
                <b-form @submit="searchPort">
                  <b-row>
                    <b-col>
                      <b-form-input
                        v-model="contractNo"
                        placeholder="Vertragsnummer"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="auto">
                      <b-button @click="searchPort" block variant="primary">Suchen</b-button>
                    </b-col>
                  </b-row>
                </b-form>
                <b-alert class="mt-3" :show="contractNoErr != null" variant="danger">{{
                  contractNoErr
                }}</b-alert>
              </b-card>
            </b-col>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
    <!-- Modal für Portabfrage -->
    <portabfrage-modal
      v-if="contractNoValid"
      id="modal-portabfrage"
      :contract-no="contractNo"
    ></portabfrage-modal>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import Tickets from "@/api/tickets";
import PortabfrageModal from "@/components/modals/Portabfrage";
import {
  BRow,
  BCol,
  BCard,
  BCardGroup,
  BButton,
  BAlert,
  BForm,
  BFormInput /*, VBModal*/,
} from "bootstrap-vue";

export default {
  name: "Startseite",
  components: {
    MainNavigation,
    PortabfrageModal,
    BRow,
    BCol,
    BCard,
    BCardGroup,
    BForm,
    BFormInput,
    BAlert,
    BButton,
  },
  // directives: {
  //   "b-modal": VBModal,
  // },
  data() {
    return {
      ticketNr: null,
      ticketNrErr: null,
      kdnr: null,
      kdnrErr: null,
      contractNo: null,
      contractNoErr: null,
      contractNoValid: false,
    };
  },
  methods: {
    searchTicketByNr(evt) {
      evt.preventDefault();
      this.ticketNrErr = null;
      if (!this.ticketNr) {
        this.ticketNrErr = "Keine Ticketnummer eingegeben";
        return;
      }
      Tickets.getByTicketnr(this.ticketNr)
        .then(response => {
          if (response && response.data && response.data.id) {
            this.$router.push({
              name: "ticketdetail",
              params: { id: response.data.id },
            });
          } else {
            this.ticketNrErr = "Ticket nicht gefunden";
          }
        })
        .catch(err => {
          if (err.response.status == 403) {
            this.ticketNrErr = "Zugriff auf Ticket nicht erlaubt";
          } else if (err.response.status == 404) {
            this.ticketNrErr = "Ticket nicht gefunden";
          } else {
            this.ticketNrErr = "Fehler beim Suchen des Tickets";
          }
        });
    },
    searchCustomer(evt) {
      evt.preventDefault();
      this.kdnrErr = null;
      if (!this.kdnr) {
        this.kdnrErr = "Keine Kundennummer eingegeben";
        return;
      }

      this.$router.push({
        name: "kundensuche",
        query: { nr: this.kdnr },
      });
    },
    searchPort(evt) {
      evt.preventDefault();
      this.contractNoErr = null;
      this.contractNoValid = false;
      if (!this.contractNo) {
        this.contractNoErr = "Keine Vertragsnummer eingegeben";
        return;
      }

      if (this.contractNo /*is valid checking? current do it in portabfrage*/) {
        this.contractNoValid = true;
        this.$nextTick(() => {
          this.$bvModal.show("modal-portabfrage");
        });
      }
    },
  },
};
</script>
