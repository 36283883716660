<template>
  <b-row>
    <b-col cols="12" class="mt-1">
      <b-form-datepicker
        right
        button-variant="light"
        v-model="inBeobachtungDate"
        v-bind="datepickerLabels"
        :date-format-options="{
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        }"
        :reset-button="true"
        locale="de"
        size="sm"
      ></b-form-datepicker>
    </b-col>
    <b-col v-if="!inBeobachtung" cols="12">
      <small class="text-danger">Ablaufdatum muss gesetzt sein</small>
    </b-col>
  </b-row>
</template>

<script>
import { BFormDatepicker, BRow, BCol } from "bootstrap-vue";
import { DateTime } from "luxon";
export default {
  name: "DateTimeSelect",
  components: {
    BRow,
    BCol,
    BFormDatepicker,
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      inBeobachtung: this.value,
      datepickerLabels: {
        labelPrevDecade: "Vorheriges Jahrzehnt",
        labelPrevYear: "Vorheriges Jahr",
        labelPrevMonth: "Vorheriger Monat",
        labelCurrentMonth: "Aktueller Monat",
        labelNextMonth: "Nächster Monat",
        labelNextYear: "Nächstes Jahr",
        labelNextDecade: "Nächstes Jahrzehnt",
        labelToday: "Heute",
        labelSelected: "Ausgewähltes Datum",
        labelNoDateSelected: "Kein Datum gesetzt",
        labelCalendar: "Kalender",
        labelNav: "Kalendernavigation",
        labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
        "label-reset-button": "Unbegrenzt",
      },
      timepickerLabels: {
        labelMinutes: "Minuten",
        labelSeconds: "Sekunden",
        labelIncrement: "Erhöhen",
        labelDecrement: "Verringern",
        labelSelected: "Ausgewählte Zeit",
        labelNoTimeSelected: "keine",
        labelCloseButton: "Schließen",
        "label-reset-button": "Unbegrenzt",
      },
    };
  },
  computed: {
    inBeobachtungDate: {
      get() {
        if (!this.inBeobachtung) return "";
        return DateTime.fromISO(this.inBeobachtung).toISODate();
      },
      set(val) {
        if (val) {
          this.inBeobachtung = DateTime.fromSQL(val).toISO({ suppressMilliseconds: true });
        } else {
          this.inBeobachtung = null;
        }
      },
    },
  },
  watch: {
    inBeobachtung(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style></style>
