<template>
  <div>
    <loader></loader>
    <main-navigation></main-navigation>
    <div class="container-fluid">
      <v-template v-if="$can('read', 'KVZAusfall')">
        <h1>KVZ-Ausfälle</h1>
        <b-row align-v="end">
          <b-col cols="auto" class="mr-auto">
            <b-form-input v-model="search" placeholder="*PLZ"></b-form-input>
          </b-col>
          <b-col cols="auto">
            <b-button
              @click="reloadAusfaelle($route.query.page)"
              variant="light"
              class="ml-2"
              title="Liste aktualisieren"
            >
              <b-icon-arrow-repeat></b-icon-arrow-repeat>
            </b-button>
          </b-col>
        </b-row>
        <b-row align-h="center" v-if="cnt > 100">
          <b-col cols="auto">
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="numberOfPages"
              use-router
            ></b-pagination-nav>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <kvz-ausfalltabelle
              :ausfaelle="ausfaelle"
              class="mt-3"
            ></kvz-ausfalltabelle>
          </b-col>
        </b-row>
        <b-row align-h="center" v-if="cnt > 100">
          <b-col cols="auto">
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="numberOfPages"
              use-router
            ></b-pagination-nav>
          </b-col>
        </b-row>
      </v-template>
    </div>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import KVZ from "@/api/kvzs";
import KvzAusfalltabelle from "@/components/tables/KVZAusfallTabelle";
import Loader from "@/components/Loader";
import { debounce } from 'vue-debounce';
import {
  BCol,
  BRow,
  BIconArrowRepeat,
  BFormInput,
  BButton,
  BPaginationNav,
} from "bootstrap-vue";

export default {
  name: "KVZAusfaelle",
  components: {
    MainNavigation,
    KvzAusfalltabelle,
    Loader,
    BCol,
    BRow,
    BIconArrowRepeat,
    BFormInput,
    BButton,
    BPaginationNav,
  },
  data: function () {
    return {
      search: "",
      ausfaelle: [],
      cnt: 0,
      dFn: null
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.reloadAusfaelle(to.query.page);
    next();
  },
  watch: {
    search(){
      this.dFn(null);
    }
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.cnt / 100);
    },
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    reloadAusfaelle(page) {
      page = page || "1";
      page = parseInt(page) - 1;
      KVZ.getAusfaelle(page,this.search).then((response) => {
        this.ausfaelle = response.data.ausfaelle;
        this.cnt = response.data.count;
      });
    },
  },
  mounted() {
    this.reloadAusfaelle();

    let vm = this;
    this.dFn = debounce(v => vm.reloadAusfaelle(v), '700ms')
  },
};
</script>