<template>
  <div class="container">
    <template v-if="sorte && $can('manage', 'KategorieSettings')">
      <b-button variant="light" class="mt-4" @click="$router.go(-1)">
        <b-icon-chevron-left></b-icon-chevron-left>&nbsp;Zur Liste
      </b-button>
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Kategorieeinstellungen"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label-cols-sm="3"
            label="Titel:"
            label-align-sm="right"
            label-for="titel"
          >
            <b-form-input id="titel" v-model="sorte.titel"></b-form-input>
          </b-form-group>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button :variant="savedInfo || 'primary'" @click="saveDaten">
              <template v-if="savedInfo == 'success'">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else-if="savedInfo == 'danger'">
                <b-icon-x aria-hidden="true"></b-icon-x>Nicht gespeichert
              </template>
              <template v-else>Speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>
    </template>
    <template v-else-if="$can('manage', 'KategorieSettings')">
      <b-row class="mt-3">
        <b-col cols="auto" class="mr-auto">
          <!--<b-form-input v-model="filter" type="search" placeholder="Suche"></b-form-input>-->
        </b-col>
        <b-col cols="auto">
          <b-button variant="primary" @click="createSorte(null)"
            >Neue Kategorie</b-button
          >
        </b-col>
      </b-row>
      <b-list-group class="list-group-root">
        <kategoriecard
          v-for="k in sortenTree"
          :key="k.titel"
          :kategorie="k"
          @deleteKategorie="deleteKategorie"
          @addSubKategorie="createSorte"
          class="mt-3"
        ></kategoriecard>
      </b-list-group>
    </template>
  </div>
</template>

<script>
import Sorte from "@/api/sorte";
import Kategoriecard from "@/components/Kategoriecard";

import {
  BCard,
  BRow,
  BCol,
  BListGroup,
  BFormGroup,
  BFormInput,
  BIconChevronLeft,
  BIconCheck,
  BIconX,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    Kategoriecard,
    BCard,
    BRow,
    BCol,
    BListGroup,
    BFormGroup,
    BFormInput,
    BIconChevronLeft,
    BIconCheck,
    BIconX,
    BButton,
  },
  name: "Rollen",
  data: function () {
    return {
      sorten: [],
      filter: null,
      savedInfo: null,
    };
  },
  computed: {
    sortenTree: {
      get() {
        return this.sorten
          .filter((e) => e.parent === "" || e.parent == null)
          .map((s) => {
            var sub = this.subKategorien(s.id);
            return {
              id: s.id,
              titel: s.titel,
              archived: s.archived,
              subKategorien: sub,
            };
          });
      },
    },
    sorte: {
      get() {
        if (!this.$route.params.id) return null;
        return this.sorten.find((u) => u.id === this.$route.params.id);
      },
      set(val) {
        let i = this.sorten.findIndex((u) => u.id === val.id);
        if (i > -1) {
          this.sorten[i] = val;
        } else {
          this.sorten.push(val);
        }
      },
    },
  },
  mounted() {
    Sorte.get().then((response) => {
      this.sorten = response.data;
    });
  },
  methods: {
    subKategorien(parent) {
      if (!parent) return [];
      return this.sorten
        .filter((e) => e.parent === parent)
        .map((s) => {
          return {
            id: s.id,
            titel: s.titel,
            archived: s.archived,
            subKategorien: this.subKategorien(s.id),
          };
        });
    },
    createSorte(parent) {
      let u = {
        id: "neu",
        parent: parent,
      };
      this.sorten.push(u);
      this.$router.push({ name: "kategorien", params: { id: "neu" } });
    },
    deleteKategorie(sorte) {
      if (!confirm("Kategorie wirklich löschen?")) return;
      Sorte.delete(sorte.id).then(() => {
        let idx = this.sorten.findIndex((r) => r.id === sorte.id);
        if (idx > -1) this.sorten.splice(idx, 1);
      });
    },
    saveDaten() {
      let self = this;
      Sorte.update(this.sorte).then((response) => {
        if (response.data && response.data.success) {
          this.savedInfo = "success";
          self.sorte = response.data.sorte;
          if (self.$route.params.id != response.data.sorte.id) {
            // neue Sorte hat jetzt eine ID erhalten
            // Sorte mit ID "neu" entfernen und dann zur richtigen Sorte springen
            let idx = self.sorten.findIndex((r) => r.id === "neu");
            if (idx > -1) self.sorten.splice(idx, 1);
            this.$router.replace({
              name: "kategorien",
              params: { id: response.data.sorte.id },
            });
          }
        } else {
          this.savedInfo = "danger";
        }
        window.setTimeout(() => {
          this.savedInfo = null;
        }, 5000);
      });
    },
  },
};
</script>
