<template>
  <div>
    <loader></loader>
    <main-navigation></main-navigation>
    <div class="container-fluid mt-5">
      <div
        class="mt-1 mb-1 mt-md-2 mb-md-2 mt-lg-4 mb-lg-4 center kategoriefilter"
      >
        <b-nav pills align="center">
          <template v-for="k in kategorien">
            <b-nav-item
              :key="k.id"
              :to="{
                path: `/tickets/${k.id.toLowerCase()}/${selectedStatus.toLowerCase()}`,
                query: $route.query,
              }"
              exact
              exact-active-class="bg-primary text-light"
              :active="selectedKategorie == k.id.toUpperCase()"
              v-if="$can('read', kategorie(k))"
            >
              {{ k.titel }}
              <b-badge
                v-if="countTicketsInKategorie(k.id)"
                pill
                variant="light"
                >{{ countTicketsInKategorie(k.id) }}</b-badge
              >
            </b-nav-item>
          </template>
        </b-nav>
      </div>
      <b-row class="mb-2">
        <b-col cols="auto" class="mr-auto">
          <b-dropdown
            class="hideOverSM"
            v-if="selectedStatusObject"
            :variant="selectedStatusObject.variant"
          >
            <template slot="button-content">
              {{ selectedStatusObject.titel }}
              <b-badge
                v-if="countTicketsInStatus(selectedStatus)"
                pill
                variant="light"
                >{{ countTicketsInStatus(selectedStatus) }}</b-badge
              >
            </template>
            <b-dropdown-item
              v-for="s in status"
              :key="s.id"
              :to="{
                path: `/tickets/${selectedKategorie.toLowerCase()}/${s.name.toLowerCase()}`,
                query: $route.query,
              }"
              :variant="s.variant != 'white' ? s.variant : 'dark'"
            >
              {{ s.titel }}
              <b-badge
                v-if="
                  s.name.toLowerCase() !== 'archiv' ||
                  countTicketsInStatus(s.name)
                "
                pill
                :variant="s.variant"
                >{{ countTicketsInStatus(s.name) }}</b-badge
              >
            </b-dropdown-item>
          </b-dropdown>
          <b-nav class="hideUnderSM engeTabs" pills align="left">
            <template v-for="s in status">
              <b-nav-item
                :key="s.id"
                :to="{
                  path: `/tickets/${selectedKategorie.toLowerCase()}/${s.name.toLowerCase()}`,
                  query: $route.query,
                }"
                exact
                exact-active-class="bg-primary text-light"
                :variant="s.variant != 'white' ? s.variant : 'dark'"
              >
                {{ s.titel }}
                <b-badge
                  v-if="
                    s.name.toLowerCase() !== 'archiv' ||
                    countTicketsInStatus(s.name)
                  "
                  pill
                  :variant="s.variant"
                  >{{ countTicketsInStatus(s.name) }}</b-badge
                >
              </b-nav-item>
            </template>
          </b-nav>
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <b-button
            variant="primary"
            @click="createMasterTicket"
            v-if="
              selectedKategorie == 'MASTER' && $can('create', emptyMasterticket)
            "
          >
            <span class="d-none d-md-inline">Neues</span>
            <span class="d-md-none">+</span> Master-Ticket
          </b-button>
          <b-button
            variant="primary"
            :to="{ name: 'kundensuche' }"
            v-if="
              selectedKategorie == 'STANDARD' &&
              $can('create', emptyStandardticket) &&
              $can('read', 'Kunde')
            "
          >
            <span class="d-none d-md-inline">Neues</span>
            <span class="d-md-none">+</span> Ticket
          </b-button>

          <b-dropdown
            variant="light"
            right
            class="ml-2"
            ref="filter-dropdown"
            menu-class="filter-dropdown"
          >
            <template slot="button-content">
              <b-icon-funnel-fill
                class="mr-1"
                v-if="cntFilter"
              ></b-icon-funnel-fill>
              <b-icon-funnel class="mr-1" v-else></b-icon-funnel>
              <span class="d-none d-md-inline mr-1">Filter</span>
              <b-badge variant="danger" v-if="cntFilter">{{
                cntFilter
              }}</b-badge>
            </template>
            <b-dropdown-form v-on:submit.prevent>
              <b-form-group label="Suche">
                <b-form-input
                  size="sm"
                  placeholder="Freitext"
                  v-model="filter.search"
                  @change="onFilterChanged"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="KVZ">
                <b-form-select
                  size="sm"
                  :options="availableKvzs"
                  v-model="filter.kvz"
                  @change="onFilterChanged"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >- nichts gewählt -</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
              <!--<b-form-group label="Jahr">
                <b-form-select
                  size="sm"
                  :options="availableJahr"
                  v-model="filter.jahr"
                  @change="onFilterChanged"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >- nichts gewählt -</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>-->
              <b-form-group label="Rolle">
                <b-form-tags
                  v-model="rollenTags"
                  size="sm"
                  add-on-change
                  no-outer-focus
                  class="mb-2 p-0 border-0"
                >
                  <template
                    v-slot="{
                      tags,
                      inputAttrs,
                      inputHandlers,
                      disabled,
                      removeTag,
                    }"
                  >
                    <b-form-select
                      size="sm"
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled"
                      :options="
                        availableRolle
                          .map((r) => r.value.titel)
                          .filter((t) => rollenTags.indexOf(t) < 0)
                      "
                    >
                      <template v-slot:first>
                        <!-- This is required to prevent bugs with Safari -->
                        <option disabled value="">Rolle wählen...</option>
                      </template>
                    </b-form-select>
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mt-2"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-badge
                          pill
                          variant="primary"
                          role="button"
                          @click.stop="removeTag(tag)"
                          >{{ tag }} <b-icon-x
                        /></b-badge>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-form-group>

              <b-form-group label="Benutzer">
                <b-form-select
                  size="sm"
                  :options="availableBenutzer"
                  v-model="filter.benutzer"
                  @change="onFilterChanged"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >- nichts gewählt -</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group label="Kategorie">
                <tree-select
                  size="sm"
                  v-model="filter.sorte"
                  :options="sortenAsTree"
                  :state="null"
                  @change="onFilterChanged"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >- nichts gewählt -</b-form-select-option
                    >
                  </template>
                </tree-select>
              </b-form-group>

              <b-form-checkbox
                switch
                size="sm"
                v-model="filter.ungelesen"
                @change="onFilterChanged"
                >Nur ungelesene Tickets</b-form-checkbox
              >
              <b-form-checkbox
                switch
                size="sm"
                v-model="filter.unzugeordnet"
                @change="onFilterChanged"
                >Nur nicht zugeordnete Tickets</b-form-checkbox
              >
            </b-dropdown-form>
          </b-dropdown>
          <b-button
            @click="reloadTickets"
            variant="light"
            class="ml-2"
            title="Tickets aktualisieren"
          >
            <b-icon-arrow-repeat></b-icon-arrow-repeat>
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center" v-if="selectedStatus == 'ARCHIV'">
        <b-col cols="auto">
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="numberOfArchivePages"
            use-router
          ></b-pagination-nav>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <tickettabelle
            :tickets="filteredTickets"
            :status="selectedStatus"
            sort-compare="sortCompare"
            :sort-by="filterSort.sortBy"
            :sort-desc="filterSort.sortDesc"
            :noLocalSorting="selectedStatus === 'ARCHIV'"
            @sortChanged="onSortChanged"
          ></tickettabelle>
        </b-col>
      </b-row>

      <b-row align-h="center" v-if="selectedStatus == 'ARCHIV'">
        <b-col cols="auto">
          <b-pagination-nav
            :link-gen="linkGen"
            :number-of-pages="numberOfArchivePages"
            use-router
          ></b-pagination-nav>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BNav,
  BNavItem,
  BRow,
  BCol,
  BPaginationNav,
  BButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BDropdownForm,
  BIconFunnel,
  BIconFunnelFill,
  BIconArrowRepeat,
  BFormTags,
  BIconX,
} from "bootstrap-vue";
import Tickettabelle from "@/components/tables/Tickettabelle";
import TreeSelect from "@/components/TreeSelect";
import MainNavigation from "@/components/MainNavigation";
import Loader from "@/components/Loader";
import Tickets from "@/api/tickets";
import { Ticket } from "@/classes/entities";
import { Kategorie } from "@/classes/entities";
import store from "@/store";
import { SET_TICKET, RESET_ARCHIVE } from "@/store/mutations.type";
import { DateTime } from "luxon";

export default {
  name: "Ticketliste",
  components: {
    BBadge,
    BNav,
    BNavItem,
    BRow,
    BCol,
    BPaginationNav,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BIconFunnel,
    BIconFunnelFill,
    BIconArrowRepeat,
    Tickettabelle,
    TreeSelect,
    MainNavigation,
    Loader,
    BFormTags,
    BIconX,
  },
  store,
  data() {
    return {
      kategorien: [
        { titel: "Master-Tickets", id: "MASTER" },
        { titel: "Standard-Tickets", id: "STANDARD" },
        { titel: "NELI-Tickets", id: "NELI" },
        { titel: "Installer-Tickets", id: "INSTALLER" },
        { titel: "Beobachtete Tickets", id: "PINNED" },
        { titel: "BTC-Tickets", id: "BTC" },
      ],
      filter: {
        kvz: null,
        jahr: null,
        rolle: [],
        sorte: null,
        ungelesen: false,
        unzugeordnet: false,
        search: "",
        kategorie: "",
        benutzer: null,
      },
    };
  },
  computed: {
    filterSort() {
      return {
        sortBy: this.$route.query.sortBy,
        sortDesc: this.$route.query.sortDesc == "true",
      };
    },
    numberOfArchivePages: {
      get() {
        return Math.max(Math.ceil(this.$store.state.archiveCnt / 50), 1);
      },
    },
    emptyMasterticket() {
      return new Ticket({ kategorie: "MASTER" });
    },
    emptyStandardticket() {
      return new Ticket({ kategorie: "STANDARD" });
    },
    selectedKategorie: {
      get() {
        return (this.$route.params.kategorie || "STANDARD").toUpperCase();
      },
    },
    selectedStatus: {
      get() {
        return (this.$route.params.ticketstatus || "ALLE").toUpperCase();
      },
    },
    selectedStatusObject: {
      get() {
        let stat = this.selectedStatus;
        return this.status.find((e) => e.name === stat);
      },
    },
    rollenTags: {
      get() {
        if (!this.filter.rolle) return [];
        return this.filter.rolle.map((r) => r.titel);
      },
      set(val) {
        this.filter.benutzer = null;
        this.filter.rolle = this.availableRolle
          .filter((ar) => val.indexOf(ar.value.titel) >= 0)
          .map((r) => r.value);
        this.onFilterChanged();
      },
    },
    sortenFilterIds() {
      var ids = [];
      this.getSubSortenIds(this.$route.query.sorte, ids);
      return ids;
    },
    cntFilter() {
      let cnt = 0;
      if (this.filter.kvz) cnt++;
      if (this.filter.jahr) cnt++;
      if (this.filter.rolle && this.filter.rolle.length > 0) cnt++;
      if (this.filter.sorte) cnt++;
      if (this.filter.ungelesen) cnt++;
      if (this.filter.unzugeordnet) cnt++;
      if (this.filter.benutzer) cnt++;
      if (this.filter.search != "") cnt++;
      return cnt;
    },
    status: {
      get() {
        return [
          {
            id: null,
            name: "ALLE",
            order: 0,
            titel: "Alle",
            tooltip: "",
            variant: "white",
          },
        ].concat(this.$store.state.status);
      },
    },
    tickets: {
      get() {
        return this.$store.getters.tickets(
          this.selectedKategorie,
          this.selectedStatus
        );
      },
    },
    filteredTickets: {
      get() {
        return this.tickets.filter(this.ticketFilter);
      },
    },
    availableKvzs() {
      let kvzs = this.tickets.map((t) => {
        let names = [];
        if (t.kvz.netkomName) names.push(t.kvz.netkomName);
        if (t.kvz.telekomName) names.push(t.kvz.telekomName);

        return {
          value: t.kvz,
          text: names.join(", "),
        };
      });
      // Duplikate entfernen
      return kvzs.filter(
        (v, i) => kvzs.findIndex((k) => v.text === k.text) === i && v.text != ""
      );
    },
    users() {
      return this.$store.state.users;
    },
    angemeldeterBenutzer() {
      return this.$store.state.user;
    },
    availableJahr() {
      let jahre = this.tickets.map((t) => {
        let jahr = DateTime.fromISO(t.erstelltAm).toFormat("yyyy");
        return {
          value: jahr,
          text: jahr,
        };
      });
      // Duplikate entfernen
      return jahre.filter(
        (v, i) =>
          jahre.findIndex((k) => v.text === k.text) === i && v.text != ""
      );
    },
    availableRolle() {
      return this.$store.state.rollen.map((t) => {
        return {
          value: { id: t.id, titel: t.titel },
          text: t.titel,
        };
      });
    },
    availableBenutzer() {
      // let filterTicketsByUser = this.users.filter(u => {
      //   // Wir blenden User aus, zu denen es keine Tickets gibt
      //   return this.tickets.find(t => t.verantwortlicherBenutzer.id === u.id) !== undefined ? true : false;
      // });
      // filterTicketsByUser;

      return this.users
        .filter((u) => {
          // Wir blenden User aus, zu denen es keine Tickets gibt
          return this.tickets.find(
            (t) => t.verantwortlicherBenutzer.id === u.id
          ) !== undefined
            ? true
            : false;
        })
        .map((u) => {
          return {
            value: u.id,
            text: u.name,
          };
        });
    },
    sortenAsTree: {
      get() {
        return this.sortenTree();
      },
    },
    availableSorten() {
      return this.$store.state.sorten.map((t) => {
        return { id: t.id, titel: t.titel, archived: t.archived };
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    // Such oder Filterparameter geupdated
    // lade die Archiv-Tickets nach wenn man auf Archiv-Bagde klickt (für die Anzahl)
    this.filter.kategorie = to.params.kategorie
      ? to.params.kategorie.toUpperCase()
      : "STANDARD";
    if (to.path.indexOf("/archiv") >= 0) {
      this.reloadArchiveTickets(to.query.page);
    } else {
      // Setze das Badge zurück wenn switch Master,Standard, NELI oder Beobachtete Tickets,
      // ansonsten würde immer die flasche Zahl stehen bleiben
      //console.log("Reset Archive: " + from.path.split(`/`)[2], to.path.split(`/`)[2]);

      // Vergleiche Änderung auf SUB Pfad
      if (from.path.split(`/`)[2] !== to.path.split(`/`)[2])
        this.$store.commit(RESET_ARCHIVE);
    }
    next();
  },
  methods: {
    ticketFilter(t) {
      let jahr = DateTime.fromISO(t.erstelltAm).toFormat("yyyy");
      if (t.status === "ARCHIV") return true; // bei ARCHIV nicht im Frontend filtern, da wird im Backend gefiltert!
      return (
        (!this.$route.query.kvz ||
          (t.kvz && this.$route.query.kvz == t.kvz.id)) &&
        (!this.$route.query.benutzer ||
          (t.verantwortlicherBenutzer &&
            t.verantwortlicherBenutzer.id === this.$route.query.benutzer)) &&
        (!this.$route.query.jahr || this.$route.query.jahr == jahr) &&
        (!this.$route.query.rolle ||
          (t.verantwortlicheRolle &&
            this.$route.query.rolle
              .split(",")
              .indexOf(t.verantwortlicheRolle.id) >= 0)) &&
        (!this.$route.query.sorte ||
          (t.sorte && this.sortenFilterIds.indexOf(t.sorte.id) >= 0)) &&
        (!this.$route.query.ungelesen ||
          t.unviewed == (this.$route.query.ungelesen === "true")) &&
        (!this.$route.query.unzugeordnet ||
          (!t.userId && this.$route.query.unzugeordnet === "true")) &&
        (!this.$route.query.suche ||
          this.$route.query.suche === "" ||
          (t.ticketNr &&
            t.ticketNr
              .toLowerCase()
              .search(this.$route.query.suche.toLowerCase()) > -1) ||
          (t.kundenname &&
            t.kundenname
              .toLowerCase()
              .search(this.$route.query.suche.toLowerCase()) > -1) ||
          (t.kundennummer &&
            t.kundennummer
              .toLowerCase()
              .search(this.$route.query.suche.toLowerCase()) > -1) ||
          (t.vertragsnummer &&
            t.vertragsnummer
              .toLowerCase()
              .search(this.$route.query.suche.toLowerCase()) > -1))
      );
    },
    onSortChanged(val) {
      this.filter.sortBy = val.sortBy;
      this.filter.sortDesc = val.sortDesc;
      //this.reloadArchiveTickets(this.$route.query.page);
      this.onFilterChanged();
    },
    onFilterChanged() {
      // change feuert bei Checkbox blöderweise vorm ändern -> warte einen Tick, dann ist der Wert im Filter aktuell
      this.$nextTick().then(() => {
        let params = {};
        if (this.filter.jahr) params.jahr = this.filter.jahr;
        if (this.filter.rolle)
          params.rolle = this.filter.rolle.map((r) => r.id).join(",");
        if (this.filter.sorte) params.sorte = this.filter.sorte.id;
        if (this.filter.kvz) params.kvz = this.filter.kvz.id;
        if (this.filter.benutzer) params.benutzer = this.filter.benutzer;
        if (this.filter.search) params.suche = this.filter.search;
        if (this.filter.ungelesen) params.ungelesen = "true";
        if (this.filter.unzugeordnet) params.unzugeordnet = "true";

        if (this.filter.sortBy) params.sortBy = this.filter.sortBy;
        if (this.filter.sortDesc) params.sortDesc = "true";

        this.$router.replace({
          name: "ticketliste",
          params: {
            kategorie: this.$route.params.kategorie,
            ticketstatus: this.$route.params.ticketstatus,
          },
          query: params,
        });
      });
    },
    sortCompare(
      aRow,
      bRow,
      key,
      sortDesc,
      formatter,
      compareOptions,
      compareLocale
    ) {
      const a = aRow[key]; // or use Lodash `_.get()`
      const b = bRow[key];
      if (
        (typeof a === "number" && typeof b === "number") ||
        (a instanceof Date && b instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        return a < b ? -1 : a > b ? 1 : 0;
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return a.localeCompare(b, compareLocale, compareOptions);
      }
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    countTicketsInKategorie(kategorie) {
      return this.$store.getters.tickets(kategorie).filter(this.ticketFilter)
        .length;
    },
    countTicketsInStatus(status) {
      if (status === "ARCHIV") {
        return this.$store.state.archiveCnt;
      } else {
        return this.ticketsInStatus(status).filter(this.ticketFilter).length;
      }
    },
    ticketsInStatus(status) {
      return this.$store.getters.tickets(this.selectedKategorie, status);
    },
    reloadTickets() {
      this.$store.dispatch("loadTickets");
    },
    reloadArchiveTickets(page) {
      this.$store.dispatch("loadArchiveTickets", [page || "1", this.filter]);
    },
    createMasterTicket() {
      Tickets.create({ kategorie: "MASTER" }).then((response) => {
        if (response.data && response.data.success) {
          let ticket = response.data.ticket;
          // Direkt zum Ticket navigieren
          // Ticket hinzufüge
          this.$store.commit(SET_TICKET, ticket);

          this.$router.push({
            name: "ticketdetail",
            params: { id: ticket.id },
          });
        }
      });
    },
    kategorie(kategorie) {
      console.log("k " + JSON.stringify(kategorie));
      return new Kategorie(kategorie);
    },
    sortenTree(parent) {
      let self = this;
      return this.$store.state.sorten
        .filter(
          (e) =>
            (parent == null && (!e.parent || e.parent === "")) ||
            e.parent === parent
        )
        .map((e) => {
          return {
            id: e.id,
            titel: e.titel,
            archived: e.archived,
            sub: self.sortenTree(e.id),
          };
        });
    },
    getSubSortenIds(id, arr) {
      arr.push(id);
      var self = this;
      this.$store.state.sorten
        .filter((s) => s.parent === id)
        .forEach((s) => {
          self.getSubSortenIds(s.id, arr);
        });
    },
  },
  created() {
    this.$store.dispatch("loadTickets");
    this.filter.kategorie = this.$route.params.kategorie
      ? this.$route.params.kategorie.toUpperCase()
      : "STANDARD";
    if (this.$route.path === "/tickets/standard/archiv") {
      this.reloadArchiveTickets();
    }
  },
  watch: {
    availableRolle() {
      if (this.$route.query.rolle) {
        this.filter.rolle =
          this.availableRolle
            .filter((e) => this.$route.query.rolle.indexOf(e.value.id) >= 0)
            .map((ro) => ro.value) || null;
      }
    },
    availableKvzs() {
      if (this.$route.query.kvz) {
        let avKvz = this.availableKvzs.find(
          (e) => e.value.id === this.$route.query.kvz
        );
        this.filter.kvz = avKvz != null ? avKvz.value || null : null;
      }
    },
    availableSorten() {
      if (this.$route.query.sorte) {
        this.filter.sorte = this.availableSorten.find(
          (e) => e.id === this.$route.query.sorte
        );
      }
    },
    availableBenutzer() {
      if (this.$route.query.benutzer) {
        this.filter.benutzer = this.availableBenutzer.find(
          (e) => e.value === this.$route.query.benutzer
        )?.value;
      }
    },
  },
  mounted() {
    this.filter.jahr = this.$route.query.jahr || null;
    if (this.availableRolle) {
      let r = this.availableRolle.filter(
        (e) =>
          this.$route.query.rolle &&
          this.$route.query.rolle.split(",").indexOf(e.value.id) >= 0
      );
      if (r.length > 0) this.filter.rolle = r.map((ro) => ro.value);
      else this.filter.rolle = null;
    }
    if (this.availableSorten) {
      this.filter.sorte =
        this.availableSorten.find((e) => {
          return e.id === this.$route.query.sorte;
        }) || null;
    }
    if (this.availableKvzs) {
      let k = this.availableKvzs.find(
        (e) => e.value.id === this.$route.query.kvz
      );
      if (k) this.filter.kvz = k.value;
      else this.filter.kvz = null;
    }

    /* kein Automatismus gesünscht, der den angemeldetn User auswählt (Ticket #800)
    if (this.angemeldeterBenutzer) {
      let u = this.availableBenutzer.find(e => e.value === this.angemeldeterBenutzer.id);
      this.filter.benutzer = u.value;
    }*/

    this.filter.search = this.$route.query.suche || "";
    this.filter.ungelesen = this.$route.query.ungelesen === "true";
    this.filter.unzugeordnet = this.$route.query.unzugeordnet === "true";
    this.filter.kategorie = this.$route.params.kategorie
      ? this.$route.params.kategorie.toUpperCase()
      : "STANDARD";
  },
};
</script>
