<template>
  <div>
    <loader></loader>
    <main-navigation></main-navigation>
    <div class="container mt-5" v-if="pages.length > 1">
      <b-nav tabs>
        <b-nav-item
          v-for="p in pages"
          :key="p.title"
          :to="p.to"
          :active-class="p.active_class"
          :exact-active-class="p.exact_active_class"
          >{{ p.title }}</b-nav-item
        >
      </b-nav>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import MainNavigation from "@/components/MainNavigation";
import { BNav, BNavItem } from "bootstrap-vue";

export default {
  components: {
    Loader,
    MainNavigation,
    BNav,
    BNavItem,
  },
  name: "Einstellungen",
  computed: {
    pages() {
      let pages = [];
      pages.push({
        to: { name: "profil" },
        title: "Profileinstellungen",
        exact_active_class: "active",
      });
      if (this.$can("manage", "UserSettings"))
        pages.push({
          to: { name: "benutzer" },
          title: "Benutzer",
          active_class: "active",
          exact_active_class: "active",
        });
      if (this.$can("manage", "RollenSettings"))
        pages.push({
          to: { name: "rollen" },
          title: "Rollen",
          active_class: "active",
          exact_active_class: "active",
        });
      if (this.$can("manage", "KategorieSettings"))
        pages.push({
          to: { name: "kategorien" },
          title: "Kategorien",
          active_class: "active",
          exact_active_class: "active",
        });
      if (this.$can("manage", "VorlagenSettings"))
        pages.push({
          to: { name: "vorlagen" },
          title: "Textvorlagen",
          active_class: "active",
          exact_active_class: "active",
        });
      if (this.$can("manage", "KvzAusfLstSettings"))
        pages.push({
          to: { name: "kvz_ausfall" },
          title: "KVZ-Ausfall",
          active_class: "active",
          exact_active_class: "active",
        });
      if (this.$can("manage", "GlobalOfficeSettings"))
        pages.push({
          to: { name: "globalofficesettings" },
          title: "GlobalOffice",
          active_class: "active",
          exact_active_class: "active",
        });

      return pages;
    },
  },
};
</script>

<style lang="scss">
.b-form-datepicker {
  label.form-control {
    border: 0;
  }
}
</style>