<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "PrivateLayout",
  mounted() {
    this.$store.dispatch('loadUser');
    this.$store.dispatch('loadKategorien');
    this.$store.dispatch('loadUsers');
    this.$store.dispatch('loadMeldungspositionen');
  }
}
</script>