<template>
  <b-modal
    :id="id"
    v-model="modalShow"
    header-bg-variant="primary"
    header-text-variant="light"
    :title="title"
    ok-title="Schließen"
    ok-variant="primary"
    ok-only
    size="lg"
  >
    <tickettabelle :compact="true" :tickets="tickets" status="ALLE"></tickettabelle>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Tickettabelle from "@/components/tables/Tickettabelle";

export default {
  name: "TicketlisteStatistik",
  components: { BModal, Tickettabelle },
  props: {
    id: String,
    modalShow: {
      type: Boolean,
      default: false
    },
    tickets: Array,
    title: String
  }
};
</script>