<template>
  <b-table
    striped
    hover
    :items="geplanteArbeiten"
    :fields="fields"
    :filter="filter"
    :busy="isBusy"
    @sort-changed="e => $emit('sort-changed', e)"
  >
    <template v-slot:table-colgroup="data">
      <col v-for="field in data.fields" :key="field.key" />
    </template>

    <template v-slot:cell(datum)="data">
      <div>{{
      dateAndTime(data.item.datum, data.item.vonBis)
    }}</div></template>
    <template v-slot:cell(ort)="data">{{ data.item.ort ? data.item.ort : "-" }}</template>
    <template v-slot:cell(cntKunden)="data">{{ data.item.cntKunden ? data.item.cntKunden : "-" }}</template>
    <template v-slot:cell(kvz)="data">{{ data.item.kvz ? data.item.kvz : "-" }}</template>
<!--    <template v-slot:cell(stromlos)="data">{{
      data.item.stromlos ? "ja" : "nein"
    }}</template>-->
    <template v-slot:cell(betroffeneKunden)="data">{{
      data.item.betroffeneKunden ? data.item.betroffeneKunden : "-"
    }}</template>
    <template v-slot:cell(status)="data">{{
      data.item.status ? data.item.status : "-"
    }}</template>
    <template v-slot:cell(bemerkungen)="data">{{
      data.item.bemerkungen ? data.item.bemerkungen : "-"
    }}</template>
    <template v-slot:cell(kundenInfo)="data">{{
      data.item.kundenInfo ? data.item.kundenInfo : "-"
    }}</template>
    <template v-slot:cell(aktion)="data">
      <b-button
        variant="primary"
        :to="{ name: 'geplante_arbeiten_detail', params: { id: data.item.id } }"
        :disabled="!$can('update', 'GeplanteArbeiten')"
        size="sm"
      >
        <b-icon-pencil></b-icon-pencil>
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { BTable, BIconPencil, BButton } from "bootstrap-vue";
import { DateTime } from "luxon";

export default {
  components: {
    BTable,
    BIconPencil,
    BButton
  },
  name: "GeplanteArbeitenTabelle",
  props: {
    geplanteArbeiten: { type: Array, required: true },
    filter: { type: String }
  },
  data() {
    return {
      isMobile: !window.matchMedia("(min-width: 768px)").matches
    };
  },
  computed: {
    isBusy() {
      return this.$store.getters.isLoading;
    },
    fields() {
      let f = [
        {
          key: "datum",
          label: "Datum / Von - Bis",
          sortable: true,
          thStyle: "white-space: nowrap;",
          tdClass:"word-break-cell-100"
        },
        // {
        //   key: "vonBis",
        //   label: "Von - Bis",
        //   sortable: true
        // },
        {
          key: "ort",
          label: "Ort",
          sortable: true
        },
        {
          key: "cntKunden",
          label: "Kunden",
          sortable: true
        },
        {
          key: "kvz",
          label: "KVZ",
          sortable: true,
          tdClass:"word-break-cell-200"
        },
        {
          key: "betroffeneKunden",
          label: "betroffene Kunden",
          sortable: false
        },
        {
          key: "status",
          label: "Status",
          sortable: false
        }
      ];

      // screen size dependend
      if (!this.isMobile) {
        f.push({
          key: "bemerkungen",
          label: "Bemerkungen",
          sortable: false,
          tdClass:"word-break-cell-200"
        });
        f.push({
          key: "kundenInfo",
          label: "Kundeninformation",
          thClass: this.mobile ? "d-none" : "",
          sortable: false
        });
      }

      f.push({
        key: "aktion",
        label: "Aktion",
        sortable: false
      });
      return f;
    }
  },
  methods: {
    dateAndTime(date, vonBis) {
      if (date !== null) {
        return (
          DateTime.fromISO(date)
            .setLocale("de")
            .toLocaleString({
              year: "numeric",
              month: "2-digit",
              day: "2-digit"
            }) +
          "\n" + (vonBis ? vonBis : " keine Angabe ")
        );
      } else {
        return vonBis;
      }
    }
  }
};
</script>

<style scoped="scss">
.wspre {
  white-space: pre;
}
</style>
