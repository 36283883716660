import { AbilityBuilder } from '@casl/ability'

export default {
  defineRulesFor: (user) => {
    const { rules, can, cannot } = new AbilityBuilder()
    if (!user) return rules

    // alle Tickets ansehen
    if (user.groups.findIndex(a => a.name === 'acl_read_all_tickets') > -1)
      can(['read'], 'Ticket')

    // Tickets eigener Gruppe sehen
    if (user.groups.findIndex(a => a.name === 'acl_read_own_role_tickets') > -1)
      can(['read'], 'Ticket', { roleId: user.role.id })

    // Tickets eigener Person sehen
    if (user.groups.findIndex(a => a.name === 'acl_read_own_tickets') > -1)
      can(['read'], 'Ticket', { userId: user.id })

    // angepinnte Tickets
    can(['read'], 'Ticket', { pinned: true })

    can(['read'], 'Kategorie', { id: 'PINNED' })

    if (user.groups.findIndex(a => a.name === 'acl_read_master_tickets') > -1) {
      can(['read'], 'Kategorie', { id: 'MASTER' })
    } else {
      cannot(['read'], 'Ticket', { kategorie: 'MASTER' })
    }
    if (user.groups.findIndex(a => a.name === 'acl_read_neli_tickets') > -1) {
      can(['read'], 'Kategorie', { id: 'NELI' })
    } else {
      cannot(['read'], 'Ticket', { kategorie: 'NELI' })
    }
    if (user.groups.findIndex(a => a.name === 'acl_read_installer_tickets') > -1) {
      can(['read'], 'Kategorie', { id: 'INSTALLER' })
    } else {
      cannot(['read'], 'Ticket', { kategorie: 'INSTALLER' })
    }
    if (user.groups.findIndex(a => a.name === 'acl_read_standard_tickets') > -1) {
      can(['read'], 'Kategorie', { id: 'STANDARD' })
    } else {
      cannot(['read'], 'Ticket', { kategorie: 'STANDARD' })
    }
    if (user.groups.findIndex(a => a.name === 'acl_read_btc_tickets') > -1) {
      can(['read'], 'Kategorie', { id: 'BTC' })
    } else {
      cannot(['read'], 'Ticket', { kategorie: 'BTC' })
    }

    if (user.groups.findIndex(a => a.name === 'acl_search_customers') > -1)
      can(['read'], 'Kunde')

    if (user.groups.findIndex(a => a.name === 'acl_read_kvzausfall') > -1)
      can(['read'], 'KVZAusfall');

    if (user.groups.findIndex(a => a.name === 'acl_read_globaloffice') > -1)
      can(['read'], 'GlobalOffice');

    // Kann Tickets erstellen und updaten
    if (user.groups.findIndex(a => a.name === 'acl_create_tickets') > -1) {
      can(['create', 'update'], 'Ticket', { kategorie: 'STANDARD' })
    }
    if (user.groups.findIndex(a => a.name === 'acl_update_tickets') > -1) {
      can(['update'], 'Ticket', { kategorie: 'STANDARD' })
      can(['update'], 'Ticket', { kategorie: 'NELI' })
      can(['update'], 'Ticket', { kategorie: 'INSTALLER' })
      can(['update'], 'Ticket', { kategorie: 'BTC' })
    }
    if (user.groups.findIndex(a => a.name === 'acl_archive_tickets') > -1) {
      can(['archive'], 'Ticket', { kategorie: 'STANDARD' })
      can(['archive'], 'Ticket', { kategorie: 'NELI' })
      can(['archive'], 'Ticket', { kategorie: 'INSTALLER' })
    }
    // Kann Mastertickets erstellen und updaten
    if (user.groups.findIndex(a => a.name === 'acl_create_masterticket') > -1) {
      can(['create', 'update'], 'Ticket', { kategorie: 'MASTER' })
    }
    if (user.groups.findIndex(a => a.name === 'acl_update_masterticket') > -1)
      can(['update'], 'Ticket', { kategorie: 'MASTER' })
    if (user.groups.findIndex(a => a.name === 'acl_archive_mastertickets') > -1)
      can(['archive'], 'Ticket', { kategorie: 'MASTER' })

    cannot(['update'], 'Ticket', { status: 'ARCHIV' })
    // Kann Status wechseln
    if (user.groups.findIndex(a => a.name === 'acl_update_status') > -1)
      can(['update'], 'TicketStatus')

    // Kann verantwortliche Rolle wechseln, sonst nur zur eigenen
    if (user.groups.findIndex(a => a.name === 'acl_update_role') > -1) {
      can(['update'], 'Rolle')
    } else {
      can(['update'], 'Rolle', { id: user.role.id })
    }

    // Kann verantwortlichen Mitarbeiter wechseln, sonst nur zu sich selbst oder Mitarbeitern der eigenen Gruppe
    if (user.groups.findIndex(a => a.name === 'acl_update_user') > -1) {
      can(['update'], 'User')
      can(['update'], 'emptyuser');
    } else if (user.teamleiter) {
      // Teamleiter dürfen auch andere User außer dem eigenen zuweisen, aber nur in der eigenen Rolle
      can(['update'], 'User', { role: user.role.id })
      can(['update'], 'emptyuser');
    } else {
      // Sonst darf man standardmäßig nur sich selbst zuweisen
      can(['update'], 'User', { id: user.id })
    }

    // Darf Billing-Informationen updaten
    if (user.groups.findIndex(a => a.name === 'acl_update_billing') > -1)
      can(['update'], 'Billing')

    // Darf Excel-Report erstellen
    if (user.groups.findIndex(a => a.name === 'acl_create_excel') > -1)
      can(['create'], 'Excelreport')

    // Kann Kommentar erzeugen
    if (user.groups.findIndex(a => a.name === 'acl_create_comment') > -1) {
      can(['create'], 'Kommentar', { kategorie: 'STANDARD' })
      can(['create'], 'Kommentar', { kategorie: 'NELI' })
      can(['create'], 'Kommentar', { kategorie: 'INSTALLER' })
      can(['create'], 'Kommentar', { kategorie: 'BTC' })
    }
    if (user.groups.findIndex(a => a.name === 'acl_create_masterticket_comment') > -1) {
      can(['create'], 'Kommentar', { kategorie: 'MASTER' })
      can(['create'], 'SMS', { kategorie: 'MASTER' })
      can(['create'], 'Mail', { kategorie: 'MASTER' })
    }

    // Kann SMS erzeugen
    if (user.groups.findIndex(a => a.name === 'acl_create_sms') > -1) {
      can(['create'], 'SMS', { kategorie: 'STANDARD' })
      can(['create'], 'SMS', { kategorie: 'NELI' })
      can(['create'], 'SMS', { kategorie: 'INSTALLER' })
      can(['create'], 'SMS', { kategorie: 'BTC' })
    }

    // Kann Mail erzeugen
    if (user.groups.findIndex(a => a.name === 'acl_create_mail') > -1) {
      can(['create'], 'Mail', { kategorie: 'STANDARD' })
      can(['create'], 'Mail', { kategorie: 'NELI' })
      can(['create'], 'Mail', { kategorie: 'INSTALLER' })
      can(['create'], 'Mail', { kategorie: 'BTC' })
    }

    // Kann Portabfrage erstellen
    if (user.groups.findIndex(a => a.name === 'acl_create_portabfrage') > -1)
      can(['create'], 'Portabfrage')

    // Kann Benutzer managen
    if (user.groups.findIndex(a => a.name === 'acl_manage_users') > -1)
      can(['manage'], 'UserSettings')

    // Kann Rollen managen
    if (user.groups.findIndex(a => a.name === 'acl_manage_roles') > -1)
      can(['manage'], 'RollenSettings')

    // Kann Kategorien managen
    if (user.groups.findIndex(a => a.name === 'acl_manage_categories') > -1)
      can(['manage'], 'KategorieSettings')

    // Kann Nachrichtenvorlagen managen
    if (user.groups.findIndex(a => a.name === 'acl_manage_templates') > -1)
      can(['manage'], 'VorlagenSettings')

    if (user.groups.findIndex(a => a.name === 'acl_manage_kvzausf_lst') > -1)
      can(['manage'], 'KvzAusfLstSettings')

    if (user.groups.findIndex(a => a.name === 'acl_manage_globaloffice') > -1)
      can(['manage'], 'GlobalOfficeSettings')

    if (user.groups.findIndex(a => a.name === 'acl_view_dashboard') > -1)
      can(['read'], 'Dashboard')

    // Kann geplante Arbeiten anzeigen, erstellen, bearbeiten
    if (user.groups.findIndex(a => a.name === 'acl_read_sheduledwork') > -1)
      can(['read'], 'GeplanteArbeiten')
    if (user.groups.findIndex(a => a.name === 'acl_create_sheduledwork') > -1)
      can(['create'], 'GeplanteArbeiten')
    if (user.groups.findIndex(a => a.name === 'acl_update_sheduledwork') > -1)
      can(['update'], 'GeplanteArbeiten')
    if (user.groups.findIndex(a => a.name === 'acl_delete_sheduledwork') > -1)
      can(['delete'], 'GeplanteArbeiten')
    return rules
  }
}