<template>
  <b-col cols="12">
    <b-row>
      <b-form-group label-for="adresse" label="Adresse" class="col-12 col-lg-6">
        <div id="adresse" v-if="ticket.strasse && ticket.strasse.id">
          {{ ticket.strasse.strasse }} {{ ticket.strasse.nr }},
          {{ ticket.strasse.plz }} {{ ticket.strasse.ort }}
        </div>
        <div id="adresse" v-else>Keine Adresse zugeordnet</div>
      </b-form-group>

      <b-form-group
        label-for="kvz"
        label="Kabelverzweiger"
        class="col-12 col-lg-6"
      >
        <div id="kvz" v-if="ticket.kvz && ticket.kvz.id">
          {{ ticket.kvz.netkomName }} {{ ticket.kvz.telekomName }}<br />{{
            ticket.kvz.adresse
          }}
        </div>
        <div id="kvz" v-else>Kein KVZ zugeordnet</div>
      </b-form-group>

      <b-form-group label-for="produkt" label="Produkt" class="col-12 col-lg-6">
        <produktcard
          v-if="ticket.product"
          :produkt="ticket.product"
        ></produktcard>
        <div id="produkt" v-else>Kein Produkt zugeordnet</div>
      </b-form-group>

      <b-form-group
        label-for="vorqualifizierung"
        label="Vorqualifizierung"
        class="col-12 col-lg-6"
      >
        <template v-if="ticket.vorquali">
          <b-row v-for="q in vorqualifizierung" :key="q.step" class="mb-3">
            <b-col md="7">{{ q.title }}</b-col>
            <b-col md="5">{{ q.answer }}</b-col>
          </b-row>
        </template>
        <b-row>
          <b-col>
            <b-button variant="primary" block v-b-modal.modal-vorqualifizierung
              >Vorqualifizierung starten</b-button
            >
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label-for="kundenbetreuer"
        label="Kundenbetreuer"
        class="col-12 col-lg-6"
      >
        <div id="kundenbetreuer" v-if="ticket.kundenbetreuer">
          {{ ticket.kundenbetreuer }}
        </div>
        <div id="kundenbetreuer" v-else>Kein Kundenbetreuer zugeordnet</div>
      </b-form-group>
    </b-row>
  </b-col>
</template>

<script>
import { BCol, BRow, BFormGroup } from "bootstrap-vue";
import Produktcard from "@/components/Produktcard";

export default {
  name: "Produktinformationen",
  components: {
    BCol,
    BRow,
    BFormGroup,
    Produktcard,
  },
  props: {
    ticket: { type: Object, required: true },
  },
  computed: {
    vorqualifizierung() {
      if (!this.ticket || !this.ticket.vorquali) return [];
      return this.ticket.vorquali.filter(
        (e) => e.answer && e.answer.length > 0
      );
    },
  },
};
</script>
