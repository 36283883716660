import { Api } from "."

export default {
  get: (id) => {
    if (id)
      return Api.get("role/", { params: { id: id } })
    else
      return Api.get("role/")
  },
  update: (role) => {
    if (role.id === 'neu')
      return Api.post("role/", role)
    return Api.put("role/", role)
  },
  delete: (id) => {
    return Api.delete("role/", { params: { id: id } })
  }
}
