<template>
  <div>
    <main-navigation></main-navigation>
    <div class="container-fluid scope-style">
      <b-row>
        <b-col>
          <h1>Demo zur Vorqualifizierung</h1>
        </b-col>
      </b-row>
      <b-row> </b-row>
      <b-row>
        <b-col>
          <b-button variant="primary" v-b-modal.modal-vorqualifizierung
            >Vorqualifizierung starten</b-button
          >
          <vorqualifizierung-modal
            id="modal-vorqualifizierung"
            @exit="setVorquali"
          ></vorqualifizierung-modal>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ vorqualiData }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import VorqualifizierungModal from "@/components/modals/VorqualifizierungModal";
import { VBModal } from "bootstrap-vue";

export default {
  name: "Vorqualidemo",
  components: {
    MainNavigation,
    VorqualifizierungModal,
  },
  data() {
    return {
      vorqualiData: {},
    };
  },
  methods: {
    setVorquali(data) {
      this.vorqualiData = data;
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
