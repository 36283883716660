import { Api } from "."

export default {
  get: (id) => {
    if (id)
      return Api.get("globaloffice/settings/", { params: { id: id } })
    else
      return Api.get("globaloffice/settings/")
  },
  update: (kvz_ausfallliste) => {
    if (kvz_ausfallliste.id === 'neu')
      return Api.post("globaloffice/settings/", kvz_ausfallliste)
    return Api.put("globaloffice/settings/", kvz_ausfallliste)
  },
  updateGeneralSettings: (zeitraum) => {
    return Api.put("globaloffice/settings/", { zeitraum: zeitraum })
  },
  delete: (id) => {
    return Api.delete("globaloffice/settings/", { params: { id: id } })
  },
  getAusfaelle: (page) => {
    return Api.get(`globaloffice/?page=${page}`)
  }
}
