import { Api } from "."

export default {
  get: (id, ref, hmac) => {
    if (id) {
      let params = { id: id }
      // wenn ref und hmac übergeben wurden, dann mitschicken, sonst nicht
      if (ref && hmac) {
        params.ref = ref;
        params.hmac = hmac;
      }
      return Api.get("tickets/", { params: params })
    } else {
      return Api.get("tickets/")
    }
  },
  getArchive: (page, sort, desc, filter) => {
    return Api.get("tickets/", { params: Object.assign({ archive: "true", page: page, sort: sort, desc: desc }, filter) })
  },
  getByTicketnr: (ticketnr) => {
    return Api.get("tickets/", { params: { ticketnr: ticketnr } })
  },
  getListForVertragsnummer: (ref) => {
    return Api.get("tickets/", { params: { ref: ref } })
  },
  getVorqualiStorybook: () => {
    return Api.get("vorqualifizierung/")
  },
  setPinned: (id, pinned, user) => {
    return Api.put("tickets/pin/", { id: id, state: pinned ? "true" : "false", user: user })
  },
  setRead: (id) => {
    return Api.get("tickets/read/", { params: { id: id } })
  },
  createComment: (id, text, file) => {
    const formData = new FormData();
    formData.append('file', file)
    formData.append('id', id);
    formData.append('text', text);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return Api.post("tickets/comment/", formData, config);
  },
  updateComment: (ticketId, aktivitaetsId) => {
    const formData = new FormData();
    formData.append('ticket_id', ticketId);
    formData.append('aktivitaet_id', aktivitaetsId);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    return Api.put("tickets/comment/", formData, config);
  },
  createFinalComment: (id, text) => {
    return Api.post("tickets/finalcomment/", { id: id, text: text })
  },
  linkCustomer: (id, receiver, isMail, contractNumber, contractId, customerNumber, name, vorname) => {
    return Api.post("tickets/link_customer/", { id: id, receiver: receiver, mail: isMail, contractNumber: contractNumber, contractId: contractId, customerNumber: customerNumber, name: name, vorname: vorname })
  },
  sendMail: (id, receiver, text) => {
    return Api.post("tickets/mail/", { id: id, receiver: receiver, text: text })
  },
  sendSMS: (id, receiver, text) => {
    return Api.post("tickets/sms/", { id: id, receiver: receiver, text: text })
  },
  sendAlleBenachrichtigen: (id, textMail, textSms) => {
    return Api.post("tickets/alleBenachrichtigen/", { id: id, textMail: textMail, textSms: textSms })
  },
  create: (ticket) => {
    return Api.post("tickets/", ticket)
  },
  update: (ticket) => {
    return Api.put("tickets/", ticket)
  },
  printPdf: (formData) => {
    return Api.post(`tickets/print/?ticket=${formData.ticket}`, formData)
  },
  getOpenTicketsStatistic: (fromDateStr, toDateStr, sorteParent) => {
    // fromDateStr muss im Forat yyyy-MM-dd sein!
    return Api.get("tickets/statistic/open/", { params: { fromDate: fromDateStr, toDate: toDateStr, sorteParent: sorteParent } })
  },
  getOpenTicketsStatisticList: (dayStr, sorteParent) => {
    return Api.get("tickets/statistic/open/", { params: { day: dayStr, sorteParent: sorteParent } })
  },
  getNewTicketsStatistic: (fromDateStr, toDateStr) => {
    // fromDateStr muss im Forat yyyy-MM-dd sein!
    return Api.get("tickets/statistic/new/", { params: { fromDate: fromDateStr, toDate: toDateStr } })
  },
  getNewTicketsStatisticList: (dayStr) => {
    return Api.get("tickets/statistic/new/", { params: { day: dayStr } })
  },
  getWeeklyStatistic: (fromDateStr, toDateStr) => {
    // fromDateStr muss im Forat yyyy-MM-dd sein!
    return Api.get("tickets/statistic/weekly/", { params: { fromDate: fromDateStr, toDate: toDateStr } })
  },
  getWeeklyStatisticList: (weekStr) => {
    return Api.get("tickets/statistic/weekly/", { params: { week: weekStr } })
  },
  getUnassignedStatistic: () => {
    return Api.get("tickets/statistic/unassigned/")
  },
  getUnassignedStatisticList: (group) => {
    return Api.get("tickets/statistic/unassigned/", { params: { group: group } })
  },
  getMasterStatistic: () => {
    return Api.get("tickets/statistic/master/")
  },
  getMasterStatisticList: () => {
    return Api.get("tickets/statistic/master/", { params: { list: true } })
  },
  getGeoStatistic: (fromDateStr, toDateStr) => {
    // fromDateStr muss im Forat yyyy-MM-dd sein!
    return Api.get("tickets/statistic/geo/", { params: { fromDate: fromDateStr, toDate: toDateStr } })
  },
  getGeoStatisticList: (fromDateStr, toDateStr, onkz, ort) => {
    return Api.get("tickets/statistic/geo/", { params: { fromDate: fromDateStr, toDate: toDateStr, onkz: onkz, ort: ort } })
  }
}
