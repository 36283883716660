<template>
  <div>
    <loader></loader>
    <main-navigation :showBackBtn="true"></main-navigation>
    <div
      id="wrapper"
      :class="[{ toggled: sidebarVisible }, 'd-flex', 'justify-content-end']"
      v-if="ticket"
    >
      <!-- Page Content -->
      <div id="page-content-wrapper">
        <div class="container-fluid">
          <b-row class="mt-4">
            <b-col>
              <h1>
                <b-button
                  id="sidebar-btn"
                  variant="light"
                  class="float-right"
                  @click="sidebarToggle"
                >
                  <b-icon-chevron-double-left
                    v-if="!sidebarVisible"
                  ></b-icon-chevron-double-left>
                  <b-icon-chevron-double-right
                    v-else
                  ></b-icon-chevron-double-right> </b-button
                >Ticket-Details
                <br />
                <small>Ticketnummer {{ ticket.ticketNr }}</small>
              </h1>
            </b-col>
            <b-col cols="6" class="mt-2" v-show="hasUnsavedChanges">
              <b-button
                size="sm"
                class="w-100"
                variant="outline-primary"
                :class="showChanges ? null : 'collapsed'"
                :aria-expanded="showChanges ? 'true' : 'false'"
                aria-controls="collapse-changes"
                @click="showChanges = !showChanges"
              >
                Ungespeicherte Änderungen vorhanden
                <b-icon-chevron-up v-if="showChanges" /><b-icon-chevron-down
                  v-if="!showChanges"
                />
              </b-button>
              <b-collapse
                id="collapse-changes"
                v-model="showChanges"
                class="mt-2"
              >
                <b-card>
                  <span>{{ Object.keys(unsavedChanges).join(", ") }}</span>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
          <template v-if="ticket.kategorie === 'BTC'">
            <ticketdetail-btc :ticket="ticket"></ticketdetail-btc>
          </template>
          <template v-else>
            <b-row>
              <!-- Stammdaten -->
              <netkom-card title="Stammdaten" :cols="{ cols: 12, xl: 6 }">
                <!-- :cols="{cols:12, xl: ticket.kategorie != 'MASTER' ? 6 : 12}"-->
                <stammdaten
                  :ticket="ticket"
                  :readonly="!ticketEditable"
                  :titelEnabled="titelEnabled"
                  :beschreibungEnabled="beschreibungEnabled"
                ></stammdaten>
              </netkom-card>

              <!-- Produktinfos -->
              <netkom-card
                title="Produktinfos"
                :cols="{ cols: 12, xl: 6 }"
                v-if="ticket.kategorie != 'MASTER'"
              >
                <produktinformationen :ticket="ticket"></produktinformationen>
              </netkom-card>
              <netkom-card
                title="Masterticket Informationen"
                :cols="{ cols: 12, xl: 6 }"
                v-if="ticket.kategorie == 'MASTER'"
              >
                <masterticket-informationen
                  :ticket="ticket"
                  :kvzs="kvzs"
                ></masterticket-informationen>
              </netkom-card>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-for="servicebericht"
                  label="Service- / Montagebericht"
                  class="p-0 col-12 col-lg-6"
                >
                  <div id="servicebericht" v-if="ticket.servicebericht">
                    <a
                      :href="ticket.servicebericht.path"
                      target="_blank"
                      class="badge badge-primary"
                    >
                      <b-icon-download />
                      {{ ticket.servicebericht.name }} ({{
                        Math.floor(ticket.servicebericht.size / 1024)
                      }}
                      kb)
                    </a>
                  </div>
                  <div id="servicebericht" v-else>
                    Noch kein Bericht vorhanden
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <b-row>
            <netkom-card title="Aktivitäten" :cols="{ cols: 12 }">
              <aktivitaetentabelle :ticket="ticket"></aktivitaetentabelle>
            </netkom-card>
          </b-row>
        </div>
      </div>

      <!-- Sidebar right -->
      <div class="bg-light border-left" id="sidebar-wrapper" v-show="sidebarVisible">
        <!--<div class="sidebar-heading">Start Bootstrap</div>-->
        <b-list-group flush>
          <b-list-group-item>
            <b-form-group label="Status">
              <b-form-select
                v-model="ticketStatus"
                :options="status"
                :disabled="!ticketEditable"
                :class="[
                  selectedStatus != null ? `bg-${selectedStatus.variant}` : '',
                  'text-light',
                  'status-select',
                ]"
              ></b-form-select>
              <date-time-select
                v-if="showDateTimeSelect"
                v-model="ticket.inBeobachtung"
              ></date-time-select>
            </b-form-group>
          </b-list-group-item>
          <b-list-group-item v-if="ticket.kategorie !== 'BTC'">
            <b-form-group
              label="Kategorie"
              :invalid-feedback="
                !ticket.sorte
                  ? 'Kategorie muss gesetzt sein'
                  : 'Kategorie ist archiviert'
              "
              :state="!ticket.sorte || ticket.sorte.archived ? false : null"
            >
              <tree-select
                v-model="ticket.sorte"
                :options="sortenAsTree"
                :disabled="!ticketEditable"
                :state="!ticket.sorte || ticket.sorte.archived ? false : null"
              ></tree-select>
            </b-form-group>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-group
              label="Verantwortlich"
              :invalid-feedback="verantwortlicherMitarbeiterUrlaub"
              :state="verantwortlicherMitarbeiterUrlaub == null ? null : false"
            >
              <b-form-select
                :options="rollen"
                v-model="verantwortlicheRolle"
                :disabled="!ticketEditable"
              ></b-form-select>
              <b-form-select
                :options="mitarbeiter"
                :disabled="!canChangeVerantwortlicherMitarbeiter"
                v-model="ticket.verantwortlicherBenutzer"
                :state="
                  verantwortlicherMitarbeiterUrlaub == null ? null : false
                "
                :variant="verantwortlicherMitarbeiterUrlaub ? 'danger' : ''"
              >
              </b-form-select>
            </b-form-group>
          </b-list-group-item>

          <b-list-group-item v-if="ticket.kategorie == 'INSTALLER'">
            <b-form-group label="Wunschtermin">
              <vue-ctk-date-time-picker
                v-model="terminTime"
                locale="de"
                no-label
                no-header
                no-button-now
                right
                only-date
                format="YYYY-MM-DD"
                formatted="DD.MM.YYYY"
                color="var(--primary)"
                button-color="var(--green)"
                label="Termin wählen"
              />
            </b-form-group>
          </b-list-group-item>

          <b-list-group-item
            v-if="ticket.kategorie !== 'BTC' && $can('update', 'Billing')"
          >
            <b-form-group
              label="Billing pausiert"
              :state="!ticket.billingPausiertGesetzt ? false : null"
              invalid-feedback="Billing pausiert muss gesetzt werden"
            >
              <b-button-group class="d-flex" :state="false">
                <b-button
                  :variant="
                    ticket.billingPausiert && ticket.billingPausiertGesetzt
                      ? 'success'
                      : 'light'
                  "
                  :disabled="!ticketEditable"
                  v-b-modal.modal-billing
                  :class="{ 'is-invalid': !ticket.billingPausiertGesetzt }"
                  >Ja</b-button
                >
                <b-button
                  :variant="
                    !ticket.billingPausiert && ticket.billingPausiertGesetzt
                      ? 'danger'
                      : 'light'
                  "
                  :disabled="!ticketEditable"
                  @click="setBillingPausiert(false)"
                  :class="{ 'is-invalid': !ticket.billingPausiertGesetzt }"
                  >Nein</b-button
                >
              </b-button-group>

              <div
                class="d-flex w-100 justify-content-between"
                v-if="
                  ticket.billingPausiert &&
                  ticket.billingPausiertGesetzt &&
                  ticket.billingPausiertBis
                "
              >
                <span>
                  <b-icon-calendar class="mt-1 mr-2" />Pausiert bis:
                </span>
                <span>{{ billingPausiertBisFormatted }}</span>
              </div>
              <!--
              <b-button
                v-if="ticket.billingPausiert && ticket.billingPausiertGesetzt"
                variant="light"
                v-b-modal.modal-billing
                block
              >
                <b-icon-calendar
                  class="float-left"
                  style="margin-top: 0.125rem"
                ></b-icon-calendar>
                <template v-if="ticket.billingPausiertBis"
                  >bis {{ billingPausiertBisFormatted }}</template
                >
                <template v-else>
                  <i>kein Enddatum</i>
                </template>
              </b-button>
              -->
            </b-form-group>
          </b-list-group-item>
          <b-list-group-item>
            <b-form-group label="Beobachter">
              <div class="mb-2">
                <b-avatar-group>
                  <b-avatar
                    variant="light"
                    v-for="b in ticket.beobachter"
                    :key="b.id"
                    v-b-tooltip
                    :title="`${b.vorname}${b.name ? ` ${b.name}` : ''}`"
                    :text="`${b.vorname ? b.vorname.substring(0, 1) : ''}${
                      b.name ? b.name.substring(0, 1) : ''
                    }`"
                  ></b-avatar>
                </b-avatar-group>
              </div>

              <b-button @click="togglePinned" block variant="light">
                <template v-if="ticket.pinned">
                  <b-icon-eye-slash></b-icon-eye-slash>&nbsp;Ticket nicht mehr
                  verfolgen
                </template>
                <template v-if="!ticket.pinned">
                  <b-icon-eye></b-icon-eye>&nbsp;Ticket verfolgen
                </template>
              </b-button>
              <b-dropdown
                right
                menu-class="w-100"
                block
                @hidden="mitarbeiterSearch = ''"
                variant="light"
              >
                <template v-slot:button-content>
                  <b-icon-people-fill />
                </template>
                <b-dropdown-form>
                  <b-form-group label="Suche" label-for="dropdown-form-search">
                    <b-form-input
                      id="dropdown-form-email"
                      size="sm"
                      placeholder="Freitext..."
                      autocomplete="off"
                      v-model="mitarbeiterSearch"
                    ></b-form-input>
                    <b-button-group vertical class="d-block">
                      <b-button
                        v-for="ma in foundMitarbeiter"
                        :key="ma.id"
                        variant="light"
                        class="text-left"
                        @click="togglePinnedForUser(ma)"
                      >
                        <b-icon-eye
                          v-if="
                            ticket.beobachter.find((b) => b.id === ma.id) ==
                            null
                          "
                        ></b-icon-eye
                        ><b-icon-eye-slash v-else></b-icon-eye-slash>&nbsp;{{
                          ma.name
                        }}, {{ ma.vorname }}</b-button
                      >
                    </b-button-group>
                  </b-form-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-form-group>
          </b-list-group-item>
          <b-list-group-item class="flex-column align-items-start">
            <div
              class="d-flex w-100 justify-content-between"
              v-if="ticket.erstelltAm"
            >
              <span>
                <b-icon-clock class="mt-1 mr-2"></b-icon-clock>Ticket erstellt:
              </span>
              <span>{{ erstelltAm }}</span>
            </div>
            <div
              class="d-flex w-100 justify-content-between"
              v-if="ticket.letzteAktivitaet"
            >
              <span>
                <b-icon-clock-history class="mt-1 mr-2"></b-icon-clock-history
                >Letzte Aktivität:
              </span>
              <span>{{ letzteAktivitaet }}</span>
            </div>
            <div
              class="d-flex w-100 justify-content-between"
              v-if="ticket.inBeobachtung"
            >
              <span>
                <b-icon-eye class="mt-1 mr-2"></b-icon-eye>In Beobachtung:
              </span>
              <span>{{ inBeobachtung }}</span>
            </div>
            <div
              class="d-flex w-100 justify-content-between"
              v-if="ticket.archiv"
            >
              <span> <b-icon-check></b-icon-check>Im Archiv: </span>
              <span>{{ imArchiv }}</span>
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <b-button
              variant="success"
              block
              @click="saveTicket"
              v-if="ticketEditable"
              :disabled="!ticketSaveable"
            >
              <b-spinner small v-if="isSaving" class="mr-2"></b-spinner>Ticket
              speichern
            </b-button>
            <b-button
              variant="primary"
              block
              v-b-modal.modal-archive
              v-if="ticketArchivable"
              :disabled="hasUnsavedChanges || !ticketSaveable"
              >Ticket abschließen</b-button
            >
            <b-button @click="closeTicket" block variant="dark"
              >Ticket verlassen</b-button
            >

            <b-button
              variant="light"
              block
              v-if="ticket.kategorie != 'MASTER'"
              :to="{ name: 'formular' }"
              >Druckansicht</b-button
            >
            <b-button
              variant="light"
              block
              v-if="$can('read', ticket)"
              v-b-modal.modal-vertragstickets
              >Weitere Tickets</b-button
            >
            <b-button
              variant="info"
              block
              v-if="$can('create', 'Portabfrage')"
              v-b-modal.modal-portabfrage
              :disabled="!ticket.vertragsnummer"
              >Portabfrage</b-button
            >
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div v-else>
      <b-row v-if="!isLoading" class="mt-4 justify-content-md-center">
        <b-col cols="auto">
          <b-alert show variant="danger"
            >Ticket nicht gefunden, oder keine Berechtigung, das Ticket zu
            lesen!</b-alert
          >
        </b-col>
      </b-row>
    </div>
    <!-- Abschluss-Modal -->
    <ticketabschluss-modal
      id="modal-archive"
      :ticket="ticket"
    ></ticketabschluss-modal>
    <!-- BillingPausieren-Modal -->
    <billing-modal id="modal-billing" :ticket="ticket"></billing-modal>
    <!-- Modal für weitere Tickets zum Vertrag -->
    <vertragstickets-modal
      id="modal-vertragstickets"
      :ticket="ticket"
    ></vertragstickets-modal>
    <!-- Modal für Portabfrage -->
    <portabfrage-modal
      v-if="ticket && ticket.vertragsnummer"
      id="modal-portabfrage"
      :ticket="ticket"
      :contract-no="ticket.vertragsnummer"
    ></portabfrage-modal>
    <!-- Modal für Vorqualifizierung -->
    <vorqualifizierung-modal
      v-if="ticket"
      id="modal-vorqualifizierung"
      :kundennummer="ticket.kundennummer"
      @exit="setVorquali"
    ></vorqualifizierung-modal>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import Loader from "@/components/Loader";
import store from "@/store";
import { SET_TICKET_PINNED } from "@/store/mutations.type";
import Tickets from "@/api/tickets";
import UserApi from "@/api/user";
import KVZs from "@/api/kvzs";
import { Ticket, User } from "@/classes/entities";
import NetkomCard from "@/components/NetkomCard";
import Stammdaten from "@/components/ticketdetail/Stammdaten";
import Produktinformationen from "@/components/ticketdetail/Produktinformationen";
import MasterticketInformationen from "@/components/ticketdetail/MasterticketInformationen";
import Aktivitaetentabelle from "@/components/tables/Aktivitaetentabelle";
import TicketabschlussModal from "@/components/modals/Ticketabschluss";
import BillingModal from "@/components/modals/BillingPausieren";
import VertragsticketsModal from "@/components/modals/VertragsnummerTickets";
import PortabfrageModal from "@/components/modals/Portabfrage";
import VorqualifizierungModal from "@/components/modals/VorqualifizierungModal";
import TicketdetailBtc from "@/components/ticketdetail/TicketdetailBtc";
import { DateTime } from "luxon";
import _ from "lodash";
import TreeSelect from "@/components/TreeSelect";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import DateTimeSelect from "@/components/entities/DateTimeSelect";
import { VBTooltip, VBModal } from "bootstrap-vue";

export default {
  name: "Ticketdetail",
  components: {
    MainNavigation,
    Loader,
    NetkomCard,
    Stammdaten,
    Produktinformationen,
    MasterticketInformationen,
    Aktivitaetentabelle,
    TicketabschlussModal,
    BillingModal,
    VertragsticketsModal,
    PortabfrageModal,
    VorqualifizierungModal,
    TreeSelect,
    VueCtkDateTimePicker,
    DateTimeSelect,
    TicketdetailBtc,
  },
  store,
  data: function () {
    return {
      ticket: null,
      ticketChange: null,
      titelEnabled: false,
      beschreibungEnabled: false,
      prevRoute: null,
      grund: null,
      sidebarVisible: true,
      isSaving: false,
      kvzs: [],
      mitarbeiterSearch: "",
      mitarbeiterList: [],
      foundMitarbeiter: [],
      unsavedChanges: {},
      showChanges: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    ticketEditable() {
      if (!this.ticket) return false;
      if (this.ticket.status == "ARCHIV") return false;
      if (!this.$can("read", this.ticket)) return false;
      return this.$can("update", this.ticket);
    },
    ticketArchivable() {
      if (!this.ticket) return false;
      if (this.ticket.status == "ARCHIV") return false;
      if (!this.$can("read", this.ticket) || !this.$can("archive", this.ticket))
        return false;
      return true;
    },
    ticketSaveable() {
      //if (!this.ticket.billingPausiertGesetzt) return false;
      if (!this.ticket.sorte) return false;

      // Folgende Stati haben ein Ablaufdatum als Pflicht:
      if (
        (this.ticket.status == "IN_BEARBEITUNG" ||
          this.ticket.status == "WARTEN_AUF_KUNDEN" ||
          this.ticket.status == "STOERUNG_IN_BEOBACHTUNG" ||
          this.ticket.status == "WARTEN_AUF_TECHNIKER") &&
        !this.ticket.inBeobachtung
      )
        return false;

      return this.ticketEditable;
    },
    rollen: {
      get() {
        if (!this.$store.state.rollen) return [];
        return this.$store.state.rollen
          .filter((e) => this.$can("update", e))
          .map((r) => {
            return {
              value: { id: r.id, titel: r.titel, email: r.email },
              text: r.titel,
            };
          });
      },
    },
    mitarbeiter: {
      get() {
        if (!this.ticket.verantwortlicheRolle) return null;
        let rolle = this.$store.state.rollen.find(
          (r) => r.id === this.ticket.verantwortlicheRolle.id
        );
        if (!rolle) return null;
        let maliste = rolle.sub
          .map((m) => {
            return new User(Object.assign(m, { role: rolle.id }));
          })
          // alle nach Berechtigung zuweisbaren Mitarbeiter UND der aktuelle verantwortliche Mitarbeiter
          .filter(
            (e) =>
              this.$can("update", e) ||
              (this.ticket.verantwortlicherBenutzer &&
                this.ticket.verantwortlicherBenutzer.id &&
                e.id === this.ticket.verantwortlicherBenutzer.id)
          )
          .map((m) => {
            return {
              value: m,
              text: `${m.name}, ${m.vorname}${m.hatUrlaub(7) ? " (U)" : ""}`,
            };
          });

        if (this.$can("update", "emptyuser")) {
          // füge leeren User hinzu
          maliste.unshift({
            value: null,
            text: "- keinem Mitarbeiter -",
          });
        }
        return maliste;
      },
    },
    canChangeVerantwortlicherMitarbeiter() {
      if (!this.ticketEditable) return false;
      if (!this.mitarbeiter) return false;
      if (
        this.ticket.verantwortlicherBenutzer &&
        this.ticket.verantwortlicherBenutzer.id &&
        !this.$can("update", new User(this.ticket.verantwortlicherBenutzer))
      )
        return false;
      return true;
    },
    status: {
      get() {
        return this.$store.state.status
          .map((r) => {
            return {
              id: r.id,
              value: r.name,
              text: r.titel,
              sort: r.order,
              variant: r.variant,
              disabled: r.disabled,
            };
          })
          .sort((a, b) => {
            return a.sort - b.sort;
          });
      },
    },
    ticketStatus: {
      get() {
        return this.ticket.status;
      },
      set(val) {
        if (val === "STOERUNG_IN_BEOBACHTUNG" && !this.ticket.inBeobachtung) {
          var ib = DateTime.local().plus({ hours: 3 });
          var min = Math.floor(ib.minute / 15) * 15;
          this.ticket.inBeobachtung = ib
            .set({ minute: min, second: 0, millisecond: 0 })
            .toISO({ suppressMilliseconds: true });
        } else {
          this.ticket.inBeobachtung = null;
        }
        this.ticket.status = val;
      },
    },
    sortenAsTree: {
      get() {
        return this.sortenTree();
      },
    },
    selectedStatus: {
      get() {
        if (!this.ticket || !this.ticket.status) return null;
        return this.status.find((s) => s.value === this.ticket.status);
      },
    },
    erstelltAm: {
      get() {
        if (!this.ticket.erstelltAm) return null;
        return this.timeSince(this.ticket.erstelltAm);
      },
    },
    letzteAktivitaet: {
      get() {
        if (!this.ticket.letzteAktivitaet) return null;
        return this.timeSince(this.ticket.letzteAktivitaet);
      },
    },
    inBeobachtung: {
      get() {
        if (!this.ticket.inBeobachtung) return null;
        return this.timeSince(this.ticket.inBeobachtung);
      },
    },
    imArchiv: {
      get() {
        if (!this.ticket.archiv) return null;
        return this.timeSince(this.ticket.archiv);
      },
    },
    verantwortlicheRolle: {
      get() {
        return this.ticket.verantwortlicheRolle;
      },
      set(val) {
        if (
          this.ticket.verantwortlicherBenutzer &&
          val.id !== this.ticket.verantwortlicherBenutzer.role
        )
          this.ticket.verantwortlicherBenutzer = null;
        this.ticket.verantwortlicheRolle = val;
      },
    },
    verantwortlicherMitarbeiterUrlaub() {
      if (!this.ticket || !this.ticket.verantwortlicherBenutzer) return null;
      let urlaub = this.ticket.verantwortlicherBenutzer.findeUrlaub(7);
      if (urlaub) {
        return `hat Urlaub vom ${DateTime.fromISO(urlaub.start).toFormat(
          "dd.MM.yyyy"
        )} bis ${DateTime.fromISO(urlaub.ende).toFormat("dd.MM.yyyy")}`;
      }
      return null;
    },
    billingPausiertBisFormatted() {
      if (!this.ticket.billingPausiertBis) return null;
      return DateTime.fromFormat(
        this.ticket.billingPausiertBis,
        "yyyy-MM-dd"
      ).toFormat("dd.MM.yyyy");
    },

    terminTime: {
      get() {
        if (!this.ticket.termin) return "";
        return DateTime.fromISO(this.ticket.termin).toFormat("yyyy-MM-dd");
      },
      set(val) {
        console.log(val);
        if (val) {
          this.ticket.termin = DateTime.fromFormat(val, "yyyy-MM-dd").toISO({
            suppressMilliseconds: true,
          });
        } else {
          this.ticket.termin = null;
        }
      },
    },
    showDateTimeSelect() {
      return (
        this.ticket.status == "IN_BEARBEITUNG" ||
        this.ticket.status == "WARTEN_AUF_KUNDEN" ||
        this.ticket.status == "STOERUNG_IN_BEOBACHTUNG" ||
        this.ticket.status == "WARTEN_AUF_TECHNIKER"
      );
    },
    hasUnsavedChanges() {
      return Object.keys(this.unsavedChanges).length !== 0;
    },
  },
  watch: {
    mitarbeiterSearch(val) {
      let searchArr = val.split(" ").filter((s) => s.trim().length > 1);
      if (this.mitarbeiterList.length > 0 && searchArr.length > 0) {
        this.foundMitarbeiter = this.mitarbeiterList.filter((ma) => {
          let found = true;
          searchArr.forEach((s) => {
            found &=
              (ma.name &&
                ma.name.toLowerCase().indexOf(s.toLowerCase()) >= 0) ||
              (ma.vorname &&
                ma.vorname.toLowerCase().indexOf(s.toLowerCase()) >= 0) ||
              (ma.login &&
                ma.login.toLowerCase().indexOf(s.toLowerCase()) >= 0);
          });
          return found;
        });
      } else {
        this.foundMitarbeiter = [];
      }
    },
    ticket: {
      deep: true,
      handler(val) {
        this.unsavedChanges = this.difference(val, this.ticketChange);
      },
    },
  },
  methods: {
    loadTicket() {
      if (this.$route.params.id) {
        //this.$store.commit(SET_TICKET_VIEWED, this.$route.params.id);
        let ref = this.$route.query.ref;
        let hmac = this.$route.query.hmac;
        Tickets.get(this.$route.params.id, ref, hmac).then((response) => {
          this.ticket = new Ticket(response.data);
          this.ticketChange = _.cloneDeep(this.ticket);
          this.titelEnabled =
            this.ticket.titel == null || this.ticket.titel.length < 3;
          this.beschreibungEnabled =
            this.ticket.beschreibung == null ||
            this.ticket.beschreibung.length < 3;
        });
      }
    },
    saveTicket() {
      this.isSaving = true;
      this.$store.dispatch("updateTicket", [
        this.ticket,
        () => {
          this.isSaving = false;
          this.ticket = new Ticket(this.$store.getters.ticket(this.ticket.id));
          this.ticketChange = _.cloneDeep(this.ticket);
          this.unsavedChanges = {};
        },
      ]);
    },
    closeTicket() {
      this.$router.go(-1);
    },
    loadKVZs() {
      let ref = this.$route.query.ref;
      let hmac = this.$route.query.hmac;
      KVZs.get(this.$route.params.id, ref, hmac).then((response) => {
        this.kvzs = response.data.kvzs;
      });
    },
    togglePinned() {
      let pinned = !this.ticket.pinned;
      let self = this;
      Tickets.setPinned(this.ticket.id, pinned, null).then((response) => {
        if (response.data.success) {
          self.$store.commit(SET_TICKET_PINNED, [self.ticket.id, pinned]);
          self.ticket.pinned = pinned;
          if (pinned) {
            // sich selbst zu den Beobachtern hinzufügen
            self.ticket.beobachter.push(self.$store.state.user);
          } else {
            // sich selbst aus Beobachtern entfernen
            let index = self.ticket.beobachter.findIndex(
              (e) => e.id === self.$store.state.user.id
            );
            if (index > -1) {
              self.ticket.beobachter.splice(index, 1);
            }
          }
        }
      });
    },
    togglePinnedForUser(user) {
      if (user.id === this.$store.state.user.id) {
        this.togglePinned();
        return;
      }
      let pinned = this.ticket.beobachter.find((b) => b.id === user.id) == null;
      let self = this;
      Tickets.setPinned(this.ticket.id, pinned, user.id).then((response) => {
        if (response.data.success) {
          if (pinned) {
            // User zu den Beobachtern hinzufügen
            self.ticket.beobachter.push(user);
          } else {
            // User aus Beobachtern entfernen
            let index = self.ticket.beobachter.findIndex(
              (e) => e.id === user.id
            );
            if (index > -1) {
              self.ticket.beobachter.splice(index, 1);
            }
          }
        }
      });
    },
    setBillingPausiert(val) {
      if (val === false) {
        this.ticket.billingPausiertBis = null;
      }
      this.ticket.billingPausiert = val;
      this.ticket.billingPausiertGesetzt = true;
    },
    setVorquali(data) {
      console.log("setVorquali", data);
      if (data.vorquali) {
        this.ticket.vorquali = data.vorquali;
      }
      if (data.ticket) {
        if (data.ticket.titel) {
          console.log("ändere Titel", data.ticket.titel);
          this.ticket.titel = data.ticket.titel;
        }
        if (data.ticket.beschreibung) {
          this.ticket.beschreibung = data.ticket.beschreibung;
        }
        if (data.ticket.sorte) {
          this.ticket.sorte = this.$store.getters.sorte(data.ticket.sorte);
        }
      }
    },
    sidebarToggle() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    timeSince(date) {
      let dur = DateTime.local()
        .diff(DateTime.fromISO(date), ["days", "hours", "minutes", "seconds"])
        .toObject();
      if (dur.days > 0) {
        return `${dur.days}d ${dur.hours}h`;
      } else {
        return `${dur.hours}h ${dur.minutes}m`;
      }
    },
    sortenTree(parent) {
      let self = this;
      return this.$store.state.sorten
        .filter(
          (e) =>
            ((parent == null && (!e.parent || e.parent === "")) ||
              e.parent === parent) &&
            (!e.archived ||
              (this.ticket.sorte && e.id === this.ticket.sorte.id))
        )
        .map((e) => {
          return {
            id: e.id,
            titel: e.titel,
            archived: e.archived,
            sub: self.sortenTree(e.id),
          };
        });
    },
    difference(obj1, obj2) {
      var allkeys = _.union(_.keys(obj1), _.keys(obj2));
      return _.reduce(
        allkeys,
        function (result, key) {
          if (!_.isEqual(obj1[key], obj2[key])) {
            // Ausnahme NULL Check, NULL kann auch empty string werden
            let empty1 =
              obj1[key] === null && obj2[key]?.length === 0 ? true : false;
            let empty2 =
              obj2[key] === null && obj1[key]?.length === 0 ? true : false;
            // Ausnahme "aktivitaeten nicht tracken"
            if (
              empty1 === empty2 &&
              key !== "aktivitaeten" &&
              key !== "letzteAktivitaet"
            ) {
              result[key] = obj1[key];
            }
          }
          return result;
        },
        {}
      );
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
  },
  mounted() {
    this.loadTicket();
    this.loadKVZs();
    UserApi.getList().then((response) => {
      this.mitarbeiterList = response.data;
    });
  },
};
</script>

<style lang="scss" scoped>
  button.is-invalid {
    border-color: #dc3545;
  }
</style>
