import { DateTime, Interval } from 'luxon';

class Entity {
  constructor(attrs) {
    Object.assign(this, attrs);
  }
}

export class Ticket extends Entity {
  static get modelName() {
    return 'Ticket'
  }
  constructor(attrs) {
    super(attrs);
    this.verantwortlicheRolle = new Rolle(this.verantwortlicheRolle);
    this.verantwortlicherBenutzer = new User(Object.assign({}, this.verantwortlicherBenutzer, { role: this.verantwortlicheRolle ? this.verantwortlicheRolle.id : "none" }));
    this.userId = this.verantwortlicherBenutzer ? this.verantwortlicherBenutzer.id : "none";
    this.roleId = this.verantwortlicheRolle ? this.verantwortlicheRolle.id : "none";
    if (this.unviewed) {
      this._rowVariant = "ungelesen";
      if (this.erstelltAm) {
        let today = DateTime.local();
        let then = DateTime.fromISO(this.erstelltAm);
        // wenn länger als 3 Tage ungelesen, markiere anders
        if (Math.floor(today.diff(then).as("days")) > 3)
          this._rowVariant = "ungelesen_ueberfaellig";
      }
    }
  }
}
export class Kategorie extends Entity {
  static get modelName() {
    return 'Kategorie'
  }
}
export class Rolle extends Entity {
  static get modelName() {
    return 'Rolle'
  }
}
export class User extends Entity {
  static get modelName() {
    return 'User'
  }

  constructor(attrs) {
    super(attrs);
  }

  hatUrlaub(tageVoraus = 0) {
    return this.findeUrlaub(tageVoraus) != null;
  }

  findeUrlaub(tageVoraus = 0) {
    // gibt an, ob der Mitarbeiter eine Abwesenheit in den nächsten [tageVoraus] Tagen hat
    // (0 -> nue heute prüfen, 1 -> bis morgen prüfen, etc)

    if (!this.abwesenheiten || this.abwesenheiten.length == 0) return null;
    let searchStart = DateTime.local().set({ hour: 0, minute: 0, second: 0 });
    let searchEnd = DateTime.local().set({ hour: 23, minute: 59, second: 59 }).plus({ days: tageVoraus });

    let searchInterval = Interval.fromDateTimes(searchStart, searchEnd);

    return this.abwesenheiten.find(abw => {
      if (!abw.start || !abw.ende) return false;
      let abwStart = DateTime.fromISO(abw.start);
      let abwEnde = DateTime.fromISO(abw.ende).set({ hour: 23, minute: 59, second: 59 });
      let abwIntervall = Interval.fromDateTimes(abwStart, abwEnde);
      return abwIntervall.overlaps(searchInterval)
    });
  }
}
export class Kommentar extends Entity {
  static get modelName() {
    return 'Kommentar'
  }
}
export class SMS extends Entity {
  static get modelName() {
    return 'SMS'
  }
}
export class Mail extends Entity {
  static get modelName() {
    return 'Mail'
  }
}
export class KVZ extends Entity {
  static get modelName() {
    return 'KVZ'
  }
}