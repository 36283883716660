<template>
  <div>
    <loader></loader>
    <main-navigation></main-navigation>
    <div class="container formular-container">
      <b-button variant="light" class="mt-4" @click="$router.go(-1)"> <b-icon-chevron-left></b-icon-chevron-left>&nbsp;Zurück zum Ticket </b-button>
      <h1>Serviceauftrag / Montagebericht</h1>
      <b-row class="">
        <b-col cols="12" md="6">
          <b-form-checkbox v-model="installation">Erstinbetriebnahme/Installationsservice</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col cols="12" md="3">
          <b-form-checkbox v-model="entstoerung">Entstörung</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row class="">
        <b-col cols="12" md="3">
          <b-form-checkbox v-model="mit_dtag">mit DTAG</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-checkbox v-model="ont_montiert">ONT montiert</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols="4" label-cols-md="6" label-cols-lg="4" label="Vor-Ort Installationsservice">
            <b-form-checkbox v-model="installationsservice_ftth">FTTH 49€ (Ohne Anfahrt)</b-form-checkbox>
            <b-form-checkbox v-model="installationsservice_dsl">DSL 99€ (Inklusive Anfahrt)</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mb-1">
          <b-form-checkbox v-model="kunde_nicht_erreicht">Kunde nicht angetroffen/ telefonisch nicht erreicht</b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols="4" label-cols-md="6" label-cols-lg="4" label="Keine NE4">
            <b-form-checkbox v-model="ne4_einfamilienhaus">Einfamilienhaus</b-form-checkbox>
            <b-form-checkbox v-model="ne4_mehrfamilienhaus">Mehrfamilienhaus</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label-cols="4" label-cols-md="6" label-cols-lg="4" label="Datum">
            <b-form-datepicker
              id="printDatum"
              v-model="datum"
              right
              button-variant="light"
              v-bind="datepickerLabels"
              reset-button
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              locale="de"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label-cols="4" label-cols-md="6" label-cols-lg="4" label="Uhrzeit">
            <b-form-input v-model="uhrzeit"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label-cols="4" label-cols-md="3" label-cols-lg="2" label="Inbetriebnahme">
            <b-form-checkbox inline v-model="inbetriebnahme_internet">Internet</b-form-checkbox>
            <b-form-checkbox inline v-model="inbetriebnahme_telefonie">Telefonie</b-form-checkbox>
            <b-form-checkbox inline v-model="inbetriebnahme_wlan">WLAN</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label-cols="4" label-cols-md="3" label-cols-lg="2" label="Entstörung">
            <b-form-radio inline name="entstoerung" v-model="stoerung_behoben" value="true">Störung behoben</b-form-radio>
            <b-form-radio inline name="entstoerung" v-model="stoerung_behoben" value="false">Störung nicht behoben</b-form-radio>
            <b-form-radio inline name="entstoerung" v-model="stoerung_behoben" value="null">Nicht relevant</b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label-cols="4" label-cols-md="3" label-cols-lg="2" label="Fehlerursache">
            <b-form-checkbox inline v-model="fehlerursache_dtag">DTAG</b-form-checkbox>
            <b-form-checkbox inline v-model="fehlerursache_tnk">TNK</b-form-checkbox>
            <b-form-checkbox inline v-model="fehlerursache_endkunde">Endkunde</b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label-cols="4" label-cols-md="3" label-cols-lg="2" label="Zusatzaufwand / Zeit">
            <b-form-radio inline name="zeit" v-model="aufwand_zeit" value="true">Ja</b-form-radio>
            <b-form-radio inline name="zeit" v-model="aufwand_zeit" value="false">Nein</b-form-radio>
            <b-form-radio inline name="zeit" v-model="aufwand_zeit" value="null">Nicht relevant</b-form-radio>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label-cols="4" label-cols-md="3" label-cols-lg="2" label="Zusatzaufwand / Material">
            <b-form-radio inline name="material" v-model="aufwand_material" value="true">Ja</b-form-radio>
            <b-form-radio inline name="material" v-model="aufwand_material" value="false">Nein</b-form-radio>
            <b-form-radio inline name="material" v-model="aufwand_material" value="null">Nicht relevant</b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label-cols="12" label-cols-md="3" label-cols-lg="2" label="Bemerkungen">
            <b-form-textarea rows="4" max-rows="8" v-model="bemerkung"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label-cols="4" label-cols-md="6" label-cols-lg="4" label="Unterschrift Kunde">
            <img :src="kundeSign" class="border rounded" width="100%" style="min-height: 50px; max-height: 120px" v-b-modal.modal-sign-customer />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label-cols="4" label-cols-md="6" label-cols-lg="4" label="Unterschrift Mitarbeiter">
            <img :src="mitarbeiterSign" class="border rounded" width="100%" style="min-height: 50px; max-height: 120px" v-b-modal.modal-sign-employee />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button variant="primary" @click="printPdf">PDF erzeugen</b-button>
        </b-col>
      </b-row>
    </div>
    <unterschrift-modal id="modal-sign-customer" titel="Unterschrift Kunde" @signed="handleCustomerSign"></unterschrift-modal>
    <unterschrift-modal id="modal-sign-employee" titel="Unterschrift Mitarbeiter" @signed="handleEmployeeSign"></unterschrift-modal>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import Loader from "@/components/Loader";
import UnterschriftModal from "@/components/modals/Unterschrift";

import { BRow, BCol, BFormGroup, BFormCheckbox, BFormRadio, BFormInput, BFormDatepicker, BFormTextarea, BButton, VBModal, BIconChevronLeft } from "bootstrap-vue";

export default {
  components: {
    MainNavigation,
    Loader,
    UnterschriftModal,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BFormCheckbox,
    BFormRadio,
    BFormInput,
    BButton,
    BFormTextarea,
    BIconChevronLeft,
  },
  name: "Formular",
  data() {
    return {
      isLoading: false,
      responseForm: null,
      installation: null,
      entstoerung: null,
      mit_dtag: null,
      ohne_dtag: null,
      ont_montiert: null,
      installationsservice_ftth: null,
      installationsservice_dsl: null,
      kunde_nicht_erreicht: null,
      ne4_einfamilienhaus: null,
      ne4_mehrfamilienhaus: null,
      datum: null,
      uhrzeit: null,
      inbetriebnahme_internet: null,
      inbetriebnahme_telefonie: null,
      inbetriebnahme_wlan: null,
      bemerkung: null,
      stoerung_behoben: null,
      fehlerursache_dtag: null,
      fehlerursache_tnk: null,
      fehlerursache_endkunde: null,
      aufwand_zeit: null,
      aufwand_material: null,
      kundeSign: null,
      mitarbeiterSign: null,
      datepickerLabels: {
        labelPrevDecade: "Vorheriges Jahrzehnt",
        labelPrevYear: "Vorheriges Jahr",
        labelPrevMonth: "Vorheriger Monat",
        labelCurrentMonth: "Aktueller Monat",
        labelNextMonth: "Nächster Monat",
        labelNextYear: "Nächstes Jahr",
        labelNextDecade: "Nächstes Jahrzehnt",
        labelToday: "Heute",
        labelSelected: "Ausgewähltes Datum",
        labelNoDateSelected: "kein Datum gewählt",
        labelCalendar: "Kalender",
        labelNav: "Kalendernavigation",
        labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
        labelResetButton: "Nicht fortsetzen",
      },
    };
  },
  directives: {
    "b-modal": VBModal,
  },
  methods: {
    handleCustomerSign(sign) {
      this.kundeSign = sign;
    },
    handleEmployeeSign(sign) {
      this.mitarbeiterSign = sign;
    },
    async printPdf() {
      this.isLoading = true;
      let form = document.createElement("form");
      form.action = `/api/tickets/print/?ticket=${this.$route.params.id}&ts=${new Date().getTime()}`;
      //form.target = "new";
      form.method = "POST";

      this.addFieldToForm(form, "installation", this.installation);
      this.addFieldToForm(form, "entstoerung", this.entstoerung);
      this.addFieldToForm(form, "ohne_dtag", this.ohne_dtag);
      this.addFieldToForm(form, "ont_montiert", this.ont_montiert);
      this.addFieldToForm(form, "installationsservice_ftth", this.installationsservice_ftth);
      this.addFieldToForm(form, "installationsservice_dsl", this.installationsservice_dsl);
      this.addFieldToForm(form, "kunde_nicht_erreicht", this.kunde_nicht_erreicht);
      this.addFieldToForm(form, "ne4_einfamilienhaus", this.ne4_einfamilienhaus);
      this.addFieldToForm(form, "ne4_mehrfamilienhaus", this.ne4_mehrfamilienhaus);
      this.addFieldToForm(form, "datum", this.datum);
      this.addFieldToForm(form, "uhrzeit", this.uhrzeit);
      this.addFieldToForm(form, "inbetriebnahme_internet", this.inbetriebnahme_internet);
      this.addFieldToForm(form, "inbetriebnahme_telefonie", this.inbetriebnahme_telefonie);
      this.addFieldToForm(form, "inbetriebnahme_wlan", this.inbetriebnahme_wlan);
      this.addFieldToForm(form, "bemerkung", this.bemerkung);
      this.addFieldToForm(form, "stoerung_behoben", this.stoerung_behoben);
      this.addFieldToForm(form, "fehlerursache_dtag", this.fehlerursache_dtag);
      this.addFieldToForm(form, "fehlerursache_tnk", this.fehlerursache_tnk);
      this.addFieldToForm(form, "fehlerursache_endkunde", this.fehlerursache_endkunde);
      this.addFieldToForm(form, "aufwand_zeit", this.aufwand_zeit);
      this.addFieldToForm(form, "aufwand_material", this.aufwand_material);
      this.addFieldToForm(form, "inbetriebnahme_internet", this.inbetriebnahme_internet);
      this.addFieldToForm(form, "inbetriebnahme_telefonie", this.inbetriebnahme_telefonie);
      this.addFieldToForm(form, "inbetriebnahme_wlan", this.inbetriebnahme_wlan);
      this.addFieldToForm(form, "stoerung_behoben", this.stoerung_behoben);
      this.addFieldToForm(form, "fehlerursache_dtag", this.fehlerursache_dtag);
      this.addFieldToForm(form, "fehlerursache_tnk", this.fehlerursache_tnk);
      this.addFieldToForm(form, "fehlerursache_endkunde", this.fehlerursache_endkunde);
      this.addFieldToForm(form, "aufwand_zeit", this.aufwand_zeit);
      this.addFieldToForm(form, "aufwand_material", this.aufwand_material);
      this.addFieldToForm(form, "bemerkung", this.bemerkung);
      this.addFieldToForm(form, "unterschriftKunde", this.kundeSign);
      this.addFieldToForm(form, "unterschriftMitarbeiter", this.mitarbeiterSign);
      this.addFieldToForm(form, "unterschriftMitarbeiter", this.mitarbeiterSign);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);

      this.$bvModal.msgBoxOk(" Das PDF Dokument wird erzeugt und am Ticket gespeichert. Es steht ebenso als Download bereit.");
    },
    addFieldToForm(form, fieldname, fieldvalue) {
      let field = document.createElement("input");
      field.type = "hidden";
      field.name = fieldname;
      field.value = fieldvalue;
      form.appendChild(field);
    },
  },
};
</script>

<style lang="scss">
.formular-container {
  .custom-radio > label.custom-control-label {
    font-weight: normal;
  }
}
</style>
