export default {
    data() {
        return {
            datepickerLabels: {
                labelPrevDecade: "Vorheriges Jahrzehnt",
                labelPrevYear: "Vorheriges Jahr",
                labelPrevMonth: "Vorheriger Monat",
                labelCurrentMonth: "Aktueller Monat",
                labelNextMonth: "Nächster Monat",
                labelNextYear: "Nächstes Jahr",
                labelNextDecade: "Nächstes Jahrzehnt",
                labelToday: "Heute",
                labelSelected: "Ausgewähltes Datum",
                labelNoDateSelected: "Kein Datum gewählt",
                labelCalendar: "Kalender",
                labelNav: "Kalendernavigation",
                labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
                labelResetButton: "Kein Datum",
            },
            datepickerDateFormatOptions: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }
        }
    },
};