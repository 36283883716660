<template>
  <b-container class="container-login">
    <b-row align-h="center">
      <b-card class="bg-light">
        <template #header>
          <div class="text-center text-dark h4">
            BIBER - Entstörsystem
          </div>
        </template>
        <div class="m-2">
          <div class="mt-2">
            <b-form-input placeholder="Benutzername" v-model="username"></b-form-input>
          </div>
          <div class="mt-2">
            <b-form-input placeholder="Passwort" type="password" v-model="password"></b-form-input>
          </div>
          <b-alert :show="fehler != null" fade variant="danger" class="mt-2">{{fehler}}</b-alert>
          <div class="mt-4 mb-2">
            <b-button block variant="secondary" @click="login"><b-spinner small v-if="loading"></b-spinner> Anmelden</b-button>
          </div>
        </div>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  //BCol,
  BCard,
  BButton,
  BAlert,
  //BForm,
  //BFormInput /*, VBModal*/,
} from "bootstrap-vue";

export default {
  name: "Login",

  components: {
    BContainer,
    BRow,
    //BCol,
    BCard,
    //BForm,
    //BFormInput,
    BButton,
    BAlert
  },

  data() {
    return {
      username: null,
      password: null,
      fehler: null,
      loading: false
    }
  },
  computed: {
    fwd() {
      var queryString = location.search
      let params = new URLSearchParams(queryString)
      return params.get("fwd")
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true;
        this.fehler = null;
        let success = await this.$store.dispatch("login", [this.username, this.password])
        if (success) {
          if (this.fwd) {
            window.location.replace(this.fwd)
          } else {
            this.$router.replace({name: "startseite"})
          }
        } else {
          this.fehler = "Anmeldedaten ungültig"
        }
      } catch(status) {
        if (status === 401) {
          this.fehler = "Anmeldedaten ungültig"
        } else {
          this.fehler = "Fehler bei der Anmeldung"
        }
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>