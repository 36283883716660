import { render, staticRenderFns } from "./Kundensuche.vue?vue&type=template&id=708bb9c4"
import script from "./Kundensuche.vue?vue&type=script&lang=js"
export * from "./Kundensuche.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports