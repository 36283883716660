import axios from "axios";
import router from "@/Router";
import store from "@/store";

import { API_START_LOADING, API_FINISH_LOADING } from "@/store/mutations.type";

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
});

Api.interceptors.request.use(
  config => {
    store.commit(API_START_LOADING);
    return config;
  },
  error => {
    store.commit(API_FINISH_LOADING);
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  response => {
    store.commit(API_FINISH_LOADING);
    return response;
  },
  error => {
    console.log("ERROR HANDLER", JSON.stringify(error.request), JSON.stringify(error.response));
    store.commit(API_FINISH_LOADING);
    // 500 - Internal Server Error
    if (error.response.status === 500) {
      if (error.response && error.response.config && error.response.config.auth) {
        error.response.config.auth.username = "******";
        error.response.config.auth.password = "******";
      }

      if (error.response && error.response.config && error.response.config.data) {
        error.response.config.data = JSON.parse(error.response.config.data);
      }
      router.push({ name: "servererror", params: { response: error.response } });
    }

    // 401 - Unauthorized
    // 403 - Forbidden
    if (error.response.status === 403 || error.response.status === 401) {
      window.open("/biber-app/entstoerung/login?fwd=" + encodeURI(window.location.pathname), "_self")
    }

    return Promise.reject(error);
  }
);

export { Api };
