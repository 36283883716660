<template>
  <b-table striped hover :items="ausfaelle" :fields="fields" :filter="filter" :busy="isBusy">
    <template v-slot:table-colgroup="data">
      <col v-for="field in data.fields" :key="field.key" />
    </template>
    <template v-slot:cell(beginn)="data">
      <template v-if="data.item.beginn">{{ formatDate(data.item.beginn) }} {{ formatTime(data.item.beginn) }} Uhr</template>
    </template>
    <template v-slot:cell(ende)="data">
      <template v-if="data.item.ende">{{ formatDate(data.item.ende) }} {{ formatTime(data.item.ende) }} Uhr</template>
    </template>
    <template v-slot:cell(PLZ)="data">{{ data.item.kvz.plz }}</template>
    <template v-slot:cell(ort)="data">{{ data.item.kvz.ort }}</template>
    <template v-slot:cell(kvz)="data">{{
      [data.item.kvz.netkomName, data.item.kvz.telekomName].filter(e => e && e.length > 0).join(" - ")
    }}</template>
    <template v-slot:cell(cntKundenGood)="data">{{ data.item.cntKundenOK + data.item.cntKundenTotalausfall }}</template>
    <template v-slot:cell(cntKundenBad)="data">{{ data.item.cntKundenTotalausfall }}</template>
    <template v-slot:cell(bemerkung)="data">
      <div class="d-flex flex-row">
        <b-form-input id="idBemerkung" v-model="data.item.kvz.bemerkung" type="text" :readonly="false" maxlength="30" :disabled="data.item.kvz.id ==null"></b-form-input>
        <b-button class="ml-1" size="sm" variant="primary" @click="updateBemerkung(data.item.kvz.id, data.item.kvz.bemerkung)" :disabled="data.item.kvz.id ==null">
          <b-icon-check2></b-icon-check2>
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import { BTable, BButton, BIconCheck2, BFormInput} from "bootstrap-vue";
import KVZ from "@/api/kvzs";
import { DateTime } from "luxon";

export default {
  components: {
    BTable,
    BButton,
    BIconCheck2,
    BFormInput,

  },
  name: "KVZAusfalltabelle",
  props: {
    ausfaelle: { type: Array, required: true },
    filter: { type: String },
  },
  data() {
    return {
      bSwitchBemerkungInput: false,
      fields: [
        {
          key: "beginn",
          label: "Start Ausfall",
          sortable: false,
        },
        {
          key: "ende",
          label: "Ende Ausfall",
          sortable: false,
        },
        {
          key: "PLZ",
          label: "PLZ",
          sortable: false,
        },
        {
          key: "ort",
          label: "Ort",
          sortable: false,
        },
        {
          key: "kvz",
          label: "KVZ",
          sortable: false,
        },
        {
          key: "cntKundenGood",
          label: "Angeschlossene Kunden",
          sortable: false,
        },
        {
          key: "cntKundenBad",
          label: "Ausgefallene Kunden",
          sortable: false,
        },
        {
          key: "bemerkung",
          label: "Bemerkung",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    isBusy() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    formatDate(date) {
      return DateTime.fromISO(date)
        .setLocale("de")
        .toLocaleString({
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
    },
    formatTime(date) {
      return DateTime.fromISO(date)
        .setLocale("de")
        .toLocaleString({
          hour: "2-digit",
          minute: "2-digit",
        });
    },
    updateBemerkung(id, bemerkung){
      KVZ.updateBemerkung(id, bemerkung).then((response) => {
        if(response.status !== 200){
          console.warn("response status: " + response.status);
          console.dir(response);
        }
      });
    }
  },
};
</script>
