<template>
  <b-col
    :cols="columns.cols"
    :xl="columns.xl"
    :lg="columns.lg"
    :md="columns.md"
    :sm="columns.sm"
    :xs="columns.xs"
    class="mt-4"
  >
    <b-row v-if="title">
      <b-col cols="12">
        <div class="nk-card-header">{{title}}</div>
      </b-col>
    </b-row>
    <b-row>
      <slot></slot>
    </b-row>
  </b-col>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "NetkomCard",
  components: {
    BRow,
    BCol
  },
  props: {
    cols: {
      type: Object,
      default: () => {
        return { cols: 4 };
      }
    },
    title: String
  },
  computed: {
    columns() {
      return Object.assign(
        {
          xl: this.cols.cols,
          lg: this.cols.cols,
          md: this.cols.cols,
          sm: this.cols.cols,
          xs: this.cols.cols
        },
        this.cols
      );
    }
  }
};
</script>

<style lang="scss">
@import "../scss/colors.scss";

.nk-card-header {
  border-bottom: 4px solid $header-grey;
  color: $header-grey;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1em;
}
</style>