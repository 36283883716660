<template>
  <b-card :header="produkt.name">
    <b-card-text>
      <div v-if="showContractNumber && produkt.contractNumber">
        <b>Vertragsnummer:</b>
        {{ produkt.contractNumber }}
      </div>
      <div>
        <b>gültig:</b>
        {{ gueltigVon }} - {{ gueltigBis }}
      </div>
      <div>
        <b>Artikel:</b>
        <ul>
          <li v-for="a in produkt.articles" :key="a.id">{{ a.name }}</li>
        </ul>
      </div>
      <div v-if="linkedTickets.length > 0">
        <b>Verknüpfte Tickets:</b>
        <ul>
          <li v-for="l in linkedTickets" :key="l.id">
            <b-badge variant="ticket_number" style="font-size: 14px">{{
              l.ticketNr
            }}</b-badge>
            {{ l.titel }}
          </li>
        </ul>
      </div>
    </b-card-text>
    <template
      v-if="addTicketBtn && $can('create', standardTicket)"
      v-slot:footer
    >
      <div class="text-right">
        <b-dropdown
          variant="danger"
          text="Zu Masterticket hinzu"
          class="mr-2"
          v-if="masterTickets"
        >
          <b-dropdown-item-button
            v-for="ticket in filteredMasterTickets"
            :key="ticket.id"
            @click="linkCustomer(ticket)"
            >{{ ticket.ticketNr }} {{ ticket.titel }}</b-dropdown-item-button
          >
        </b-dropdown>
        <b-button variant="success" @click="createTicket">
          Ticket erstellen
          <b-icon-plus></b-icon-plus>
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BBadge,
  BButton,
  BIconPlus,
  BDropdown,
  BDropdownItemButton,
} from "bootstrap-vue";
import { Ticket } from "@/classes/entities";
import { DateTime } from "luxon";
import store from "@/store";
export default {
  components: {
    BCard,
    BCardText,
    BBadge,
    BButton,
    BIconPlus,
    BDropdown,
    BDropdownItemButton,
  },
  store,
  props: {
    produkt: { type: Object, required: true },
    addTicketBtn: { type: Boolean, default: false },
    masterTickets: { type: Array, default: null },
    showContractNumber: { type: Boolean, default: false },
  },
  methods: {
    createTicket() {
      this.$emit("create-ticket", this.produkt);
    },
    linkCustomer(ticket) {
      this.$emit("link-customer", {
        masterticket: ticket,
        produkt: this.produkt,
      });
    },
  },
  computed: {
    filteredMasterTickets() {
      if (!this.produkt.linked) return this.masterTickets;
      return this.masterTickets.filter(
        (t) => this.produkt.linked.indexOf(t.id) < 0
      );
    },
    standardTicket() {
      return new Ticket({ kategorie: "STANDARD" });
    },
    gueltigVon() {
      if (!this.produkt || !this.produkt.validFrom) return null;
      return DateTime.fromFormat(this.produkt.validFrom, "yyyy-MM-dd").toFormat(
        "dd.MM.yyyy"
      );
    },
    gueltigBis() {
      if (!this.produkt || !this.produkt.expirationDate) return null;
      return DateTime.fromFormat(
        this.produkt.expirationDate,
        "yyyy-MM-dd"
      ).toFormat("dd.MM.yyyy");
    },
    linkedTickets() {
      if (!this.produkt.linked) return [];
      return store.state.tickets.filter(
        (t) => this.produkt.linked.indexOf(t.id) > -1
      );
    },
  },
};
</script>