<template>
  <b-col cols="12">
    <b-row>
      <b-form-group label-for="kvz" label="Kabelverzweiger" class="col-12 col-lg-6">
        <v-select v-model="ticket.kvz.id" :options="kvzs" :reduce="k => k.id" label="bezeichnung" placeholder="Systemweites Masterticket">
        </v-select>            
      </b-form-group>
    </b-row>
  </b-col>
</template>

<script>
import { BCol, BRow, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select"

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    vSelect
  },
  props: {
    ticket: { type: Object, required: true },
    kvzs: {type: Array, required: true}
  },
  computed: {
    vorqualifizierung() {
      if (!this.ticket || !this.ticket.vorquali) return [];
      return this.ticket.vorquali.filter(e => e.answer && e.answer.length > 0);
    }
  }
};
</script>