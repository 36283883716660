<template>
  <b-navbar toggleable="md" type="dark">
    <b-button
      v-if="showBackBtn"
      class="navbar-toggler m-2 d-md-none"
      variant="primary"
      @click="$router.go(-1)"
    >
      <b-icon-chevron-left></b-icon-chevron-left>
    </b-button>
    <b-navbar-toggle
      target="nav-collapse"
      class="ml-auto m-2"
    ></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item :to="{ name: 'startseite' }">Startseite</b-nav-item>
        <b-nav-item :to="{ name: 'dashboard' }" v-if="$can('read', 'Dashboard')"
          >Dashboard</b-nav-item
        >
        <b-nav-item
          active-class="router-link-exact-active"
          :to="{ name: 'kvzausfall' }"
          v-if="$can('read', 'KVZAusfall')"
          >KVZ-Ausfälle</b-nav-item
        >
        <b-nav-item
          active-class="router-link-exact-active"
          :to="{ name: 'globaloffice' }"
          v-if="$can('read', 'GlobalOffice')"
          >GlobalOffice</b-nav-item
        >
        <b-nav-item
          active-class="router-link-exact-active"
          :to="{ name: 'kundensuche' }"
          v-if="$can('read', 'Kunde')"
          >Kunden</b-nav-item
        >
        <b-nav-item :to="{ name: 'ticketliste' }">Tickets</b-nav-item>
        <b-nav-item
          :to="{ name: 'geplante_arbeiten' }"
          v-if="$can('read', 'GeplanteArbeiten')"
          >Geplante Arbeiten</b-nav-item
        >
        <b-nav-item-dropdown right>
          <template v-slot:button-content>
            <b-icon-person></b-icon-person>
          </template>
          <b-nav-item :to="{ name: 'profil' }">Einstellungen</b-nav-item>
          <b-nav-item @click="logout">Logout</b-nav-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import {
  BButton,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BNavItem,
  BNavItemDropdown,
  BIconPersonFill,
  BIconChevronLeft,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    "b-icon-person": BIconPersonFill,
    BIconChevronLeft,
  },
  props: {
    showBackBtn: { type: Boolean, default: false },
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch("logout");
        window.open("/login", "_self");
      } catch(e) {
        console.error(e)
      }
    }
  }
};
</script>

<style lang="scss">

</style>