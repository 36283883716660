<template>
  <div class="chart-container">
    <div
      v-if="loaded"
      :class="cntClasses"
      style="font-size: 15vh;"
      @click="onPointClicked"
    >{{openedMasterTickets}}</div>
    <b-row v-else align-v="center" align-h="center" class="p-3">
      <b-col cols="auto">
        <b-spinner variant="primary" label="Lädt..." style="width: 10vw; height: 10vw;"></b-spinner>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Api } from "@/api";
import { Ticket } from "@/classes/entities";
import ticketApi from "@/api/tickets";
import { BRow, BCol, BSpinner } from "bootstrap-vue";

export default {
  name: "TicketMasterChart",
  components: {
    BRow,
    BCol,
    BSpinner,
  },
  data() {
    return {
      loaded: false,
      openedMasterTickets: 0,
    };
  },
  methods: {
    loadData() {
      let chart = this;
      ticketApi.getMasterStatistic().then((response) => {
        chart.openedMasterTickets = response.data.cnt;
        this.loaded = true;
      });
    },
    excel() {
      Api.get("excelreport/", { params: { type: "openmaster" } }).then(
        (response) => {
          if (response.data.status === "ok") {
            let filename = response.data.document;
            if (process.env.NODE_ENV === "development")
              filename = "https://tes-dev-tickets.batix.net" + filename;

            window.open(filename);
          }
        }
      );
    },
    onPointClicked() {
      ticketApi.getMasterStatisticList().then((response) => {
        this.$emit("point-clicked", [
          "Offene Mastertickets",
          response.data.tickets.map((e) => new Ticket(e)),
        ]);
      });
    },
  },
  computed: {
    cntClasses() {
      let classes = ["text-center"];
      if (this.openedMasterTickets <= 0) {
        classes.push("text-success");
      } else if (this.openedMasterTickets <= 2) {
        classes.push("text-warning");
      } else {
        classes.push("text-danger");
      }
      return classes;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>