import Router from 'vue-router'
import PublicLayout from '@/layout/Public'
import PrivateLayout from '@/layout/Private'
import Startseite from '@/pages/Startseite'
import Vorqualidemo from '@/pages/Vorqualidemo'
import Dashboard from '@/pages/Dashboard'
import Ticketliste from '@/pages/Ticketliste'
import Ticketdetail from '@/pages/Ticketdetail'
import Kundensuche from '@/pages/Kundensuche'
import Login from '@/pages/Login'
import Formular from '@/pages/Formular'
import Einstellungen from '@/pages/Einstellungen'
import GeplanteArbeiten from '@/pages/GeplanteArbeiten'
import GeplanteArbeitenDetail from '@/pages/GeplanteArbeitenDetail'
import Profil from '@/components/settings/Profil'
import Userverwaltung from '@/components/settings/Users'
import Rollenverwaltung from '@/components/settings/Rollen'
import Kategorieverwaltung from '@/components/settings/Sorten'
import Vorlagenverwaltung from '@/components/settings/Vorlagen'
import KvzAusfallverwaltung from '@/components/settings/kvz_ausfall'
import GlobalOfficeverwaltung from '@/components/settings/GlobalOffice'
import KvzAusfallliste from '@/pages/KVZAusfaelle'
import GlobalOfficeListe from '@/pages/GlobalOffice'


const routes = [
  {
    path: '/',
    component: PrivateLayout,
    name: 'private',
    children: [
      { path: '/vorquali-demo', name: 'vorquali-demo', component: Vorqualidemo },
      { path: '/dashboard', name: 'dashboard', component: Dashboard },
      { path: '/kunden', name: 'kundensuche', component: Kundensuche },
      { path: '/kvzausfall', name: 'kvzausfall', component: KvzAusfallliste },
      { path: '/globaloffice', name: 'globaloffice', component: GlobalOfficeListe },
      { path: '/geplante_arbeiten', name: 'geplante_arbeiten', component: GeplanteArbeiten },
      { path: '/geplante_arbeiten/:id', name: 'geplante_arbeiten_detail', component: GeplanteArbeitenDetail },
      {
        path: '/tickets/:kategorie?/:ticketstatus?', name: 'ticketliste', component: Ticketliste
      },
      { path: '/ticket/:id', name: 'ticketdetail', component: Ticketdetail },
      { path: '/ticket/:id/print', name: 'formular', component: Formular },
      {
        path: '/einstellungen',
        name: 'einstellungen',
        component: Einstellungen,
        children: [
          { path: 'vorlagen/:id?', name: 'vorlagen', component: Vorlagenverwaltung, props: true },
          { path: 'kvzausfall/:id?', name: 'kvz_ausfall', component: KvzAusfallverwaltung, props: true },
          { path: 'globaloffice/:id?', name: 'globalofficesettings', component: GlobalOfficeverwaltung, props: true },
          { path: 'kategorien/:id?', name: 'kategorien', component: Kategorieverwaltung },
          { path: 'rollen/:id?', name: 'rollen', component: Rollenverwaltung },
          { path: 'benutzer/:id?', name: 'benutzer', component: Userverwaltung },
          { path: '', name: 'profil', component: Profil }
        ]
      },
      { path: '', name: 'startseite', component: Startseite }
    ]
  },
  {
    path: '/login',
    name: 'public',
    component: PublicLayout,
    children: [
      { path: '', name: 'login', component: Login }
    ]
  },

]

export default new Router(
  {
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes: routes
  })
