<template>
  <b-table striped hover :items="roles" :fields="fields" :filter="filter">
    <template v-slot:table-colgroup="data">
      <col
        v-for="field in data.fields"
        :key="field.key"
        :style="{ width: field.key === 'aktion' ? '120px' : 'auto' }"
      />
    </template>
    <template v-slot:cell(titel)="data">{{ data.item.titel }}</template>
    <template v-slot:cell(aktion)="data">
      <b-button-group>
        <b-button
          variant="light"
          :to="`/einstellungen/rollen/${data.item.id}`"
          size="sm"
        >
          <b-icon-pencil></b-icon-pencil>
        </b-button>
        <b-button variant="danger" size="sm" @click="deleteRole(data.item)">
          <b-icon-trash></b-icon-trash>
        </b-button>
      </b-button-group>
    </template>
  </b-table>
</template>

<script>
import {
  BTable,
  BButton,
  BButtonGroup,
  BIconPencil,
  BIconTrash,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BButton,
    BButtonGroup,
    BIconPencil,
    BIconTrash,
  },
  name: "Rollentabelle",
  props: {
    roles: { type: Array, required: true },
    filter: { type: String },
  },
  data() {
    return {
      fields: [
        {
          key: "titel",
          label: "Titel",
          sortable: true,
        },
        {
          key: "aktion",
          label: "Aktionen",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    deleteRole(role) {
      this.$emit("deleteRole", role);
    },
  },
};
</script>