<template>
  <b-modal
    :id="id"
    ref="vertragstickets-modal"
    header-bg-variant="primary"
    header-text-variant="light"
    title="Weitere Tickets zum Vertrag"
    ok-title="Schließen"
    ok-variant="primary"
    ok-only
    @show="loadVertragstickets"
    size="xl"
  >
    <tickettabelle :compact="true" :tickets="tickets" :refTicket="ticket"></tickettabelle>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Tickets from "@/api/tickets";
import store from "@/store";
import Tickettabelle from "@/components/tables/Tickettabelle";
import { Ticket } from "@/classes/entities";

export default {
  name: "VertragsticketsModal",
  store,
  components: {
    BModal,
    Tickettabelle
  },
  props: {
    id: String,
    ticket: Object
  },
  data() {
    return {
      tickets: []
    };
  },
  computed: {},
  methods: {
    loadVertragstickets() {
      let tid = this.ticket.id;
      Tickets.getListForVertragsnummer(tid).then(response => {
        this.tickets = response.data
          .map(e => new Ticket(e))
          .filter(t => t.id != tid);
      });
    }
  }
};
</script>