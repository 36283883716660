<template>
  <!-- the 'accordion' PortAbfrage list -->
  <b-row>
    <b-col class="col-12">
      <div role="tablist">
        <div v-for="pa in maxList" :key="pa.id" class="m-1">
          <div role="tab">
            <b-button block v-b-toggle="pa.id" variant="light" class="btn-border text-left"
              >Erstellt am {{ formattedDate(pa.erstelltAm) }}
              <span v-if="pa.mitarbeiter.name">{{ toUserString(pa.mitarbeiter) }}</span></b-button
            >
          </div>
          <b-collapse :id="pa.id" accordion="noSpecialId" role="tabpanel">
            <template v-if="pa.data !== null">
              <portabfrage :portabfrage="pa.data" :erstelltAm="pa.erstelltAm" :key="pa.key"></portabfrage>
            </template>
            <template v-else>
              <div class="m-1"><b-icon-exclamation-square></b-icon-exclamation-square><p>Portabfrage nicht möglich.</p></div>
            </template>
          </b-collapse>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { DateTime } from "luxon";
import { BRow, BCol, BButton, BCollapse, VBToggle, BIconExclamationSquare } from "bootstrap-vue";
import Portabfrage from "@/components/Portabfrage";

export default {
  name: "PortabfrageListe",
  components: {
    BRow,
    BCol,
    BButton,
    BCollapse,
    BIconExclamationSquare,
    Portabfrage,
  },
  props: {
    list: {
      type: Array,
      required: true,
      default: null,
    },
    expandFirst: {
      type: Boolean,
      required: false,
      default: false,
    },
    max: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  computed: {
    maxList() {
      return this.list.slice(0, this.max);
    },
  },
  watch: {
    list(val) {
      if (val.data !== null && this.expandFirst) {
        this.$nextTick(() => {
          // toggle Element with newest ID
          this.$root.$emit("bv::toggle::collapse", val[0].id);
        });
      }
    },
  },
  methods: {
    formattedDate(date) {
      return DateTime.fromISO(date).toFormat("dd.MM.yyyy 'um' HH:mm:ss 'Uhr'");
    },
    toUserString(m) {
      return `durch ${m.vorname} ${m.name}`;
    },
  },
  directives: {
    "b-toggle": VBToggle,
  },
};
</script>

<style lang="scss" scoped>
.btn-border {
  border: 1px solid lightgray;
  // background-color:#00000010;
}
</style>
