<template>
  <b-modal
    :id="id"
    ref="portabfrage-modal"
    header-bg-variant="primary"
    header-text-variant="light"
    :title="modalTitle"
    ok-title="Schließen"
    ok-variant="primary"
    ok-only
    @show="show()"
    @hide="hide()"
    size="xl"
  >
    <b-row>
      <b-col cols="auto" class="mr-auto"></b-col>
      <b-col cols="auto">
        <b-button variant="success" @click="createPortabfrage"
          >Neue Portabfrage starten</b-button
        >
      </b-col>
      <b-col cols="12">
        <!-- Info -->
        <b-row class="ml-1 pb-2">
          <div v-if="portAbfragenListe">
            <b>Bisherige Portabfragen:</b> {{ portAbfragenListe.length }}
          </div>
        </b-row>

        <!-- loading ? -->
        <b-row v-if="isLoading" align-v="center" align-h="center">
          <b-col cols="auto">
            <b-spinner
              variant="primary"
              label="Portabfrage wird geladen..."
            ></b-spinner>
          </b-col>
          <b-col cols="auto">Portabfrage wird geladen...</b-col>
        </b-row>
        <b-row align-v="center" align-h="center">
          <b-col cols="auto" v-if="responseInvalidText.length > 0">{{
            responseInvalidText
          }}</b-col>
        </b-row>

        <!-- Liste der Portabfragen -->
        <portabfrage-liste
          :list="portAbfragenListe"
          :expandFirst="expandNewPortabfrage"
          :max="portAbfrageListeMax"
        ></portabfrage-liste>
        <b-button
          v-show="portAbfrageListeMax !== portAbfragenListe.length"
          variant="light"
          @click="portAbfrageListeMax = portAbfragenListe.length"
          >...</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BSpinner, BModal, BRow, BCol, BButton } from "bootstrap-vue";
import Port from "@/api/port";
import PortabfrageListe from "@/components/PortAbfrageListe";

export default {
  name: "PortabfrageModal",
  components: {
    BSpinner,
    BModal,
    BRow,
    BCol,
    BButton,
    PortabfrageListe,
  },
  props: {
    id: String,
    ticket: { type: Object, required: false, default: null },
    contractNo: { type: String, required: true },
    portabfrage: String,
  },
  data() {
    return {
      portAbfragenListe: [],
      portAbfrageListeMax: 10,
      responseInvalidText: "",
      expandNewPortabfrage: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
    modalTitle() {
      if (this.ticket !== null) {
        return `Portabfrage für Ticketnummer '${this.ticket.ticketNr}'`;
      }
      if (this.contractNo !== null) {
        return `Portabfrage für Vertragsnummer '${this.contractNo}'`;
      }
      return `Portabfrage`;
    },
  },
  methods: {
    createPortabfrage() {
      this.responseInvalidText = "";
      if (this.contractNo !== null) {
        Port.createPortabfrage(this.contractNo).then((response) => {
          if (!response.data.success) {
            this.responseInvalidText = "Problem bei Abfrage der Vertragsnummer";
          } else {
            // when a new portabfrage was requested... exand this new one
            this.expandNewPortabfrage = true;
            this.loadPortAbfrageListe();
          }
        });
      } else {
        // no ticket or contract no available
      }
    },
    loadPortAbfrageListe() {
      if (this.contractNo !== null) {
        Port.getPortabfrageList(this.contractNo).then((response) => {
          if (response.data && response.data.length !== 0) {
            this.portAbfragenListe = response.data;
            this.portAbfragenListe.length <= 10
              ? (this.portAbfrageListeMax = this.portAbfragenListe.length)
              : (this.portAbfrageListeMax = 10);
          } else {
            this.portAbfrageListeMax = 0;
          }
        });
      } else {
        // no ticket or contract no available
      }
    },
    show() {
      this.loadPortAbfrageListe();
    },
    hide() {
      this.portAbfragenListe = [];
      this.portAbfrageListeMax = 0;
    },
  },
};
</script>
