<template>
  <b-list-group-item v-if="!kategorie.archived">
    <b-row>
      <b-col cols="auto" class="mr-auto">{{kategorie.titel}}</b-col>
      <b-col cols="auto">
        <b-button-group size="sm" class="float-right" title="Unterkategorien einblenden">
          <b-button variant="light" v-if="subKategorien.length > 0" v-b-toggle="kategorie.id">
            <b-icon-chevron-up v-if="expanded"></b-icon-chevron-up>
            <b-icon-chevron-down v-else></b-icon-chevron-down>
          </b-button>
          <b-button
            variant="success"
            title="Neue Unterkategorie hinzufügen"
            @click="addSubKategorie()"
          >
            <b-icon-plus></b-icon-plus>
          </b-button>
          <b-button
            variant="light"
            title="Bearbeiten"
            :to="{'name':'kategorien', params: {id: kategorie.id}}"
          >
            <b-icon-pencil></b-icon-pencil>
          </b-button>
          <b-button variant="danger" title="Kategorie entfernen" @click="deleteKategorie()">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-collapse
          :id="kategorie.id"
          class="mt-2"
          v-if="subKategorien.length > 0"
          v-model="expanded"
        >
          <b-list-group>
            <kategoriecard
              v-for="k in subKategorien"
              :key="k.titel"
              :kategorie="k"
              @addSubKategorie="addSubKategorie"
              @deleteKategorie="deleteKategorie"
            ></kategoriecard>
          </b-list-group>
        </b-collapse>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import {
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BCollapse,
  BButton,
  BButtonGroup,
  BIconPlus,
  BIconTrash,
  BIconPencil,
  BIconChevronDown,
  BIconChevronUp,
  VBToggle
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BCollapse,
    BButton,
    BButtonGroup,
    BIconPlus,
    BIconTrash,
    BIconPencil,
    BIconChevronDown,
    BIconChevronUp
  },
  name: "Kategoriecard",
  props: {
    kategorie: { type: Object, required: true }
  },
  data() {
    return {
      expandedVal: false
    };
  },
  computed: {
    expanded: {
      get() {
        let openCategories = this.$route.hash.split("#");
        if (openCategories.indexOf(this.kategorie.id) > -1) return true;
        else return this.expandedVal;
      },
      set(val) {
        this.expandedVal = val;
        if (val) this.addHash();
        else this.removeHash();
      }
    },
    subKategorien() {
      if (!this.kategorie.subKategorien) return [];
      return this.kategorie.subKategorien;
    }
  },
  methods: {
    addSubKategorie(parentId) {
      this.$emit("addSubKategorie", parentId || this.kategorie.id);
    },
    deleteKategorie(kategorie) {
      this.$emit("deleteKategorie", kategorie || this.kategorie);
    },
    addHash() {
      let openCategories = this.$route.hash
        .split("#")
        .filter(e => e.length > 0);
      if (openCategories.indexOf(this.kategorie.id) > -1) return;
      openCategories.push(this.kategorie.id);
      window.location.hash = `#${openCategories.join("#")}`;
      /*this.$router.replace({
        name: "kategorien",
        hash: `#${openCategories.join("#")}`
      });*/
    },
    removeHash() {
      let openCategories = this.$route.hash
        .split("#")
        .filter(e => e.length > 0);
      let idx = openCategories.indexOf(this.kategorie.id);
      if (idx > -1) openCategories.splice(idx, 1);
      window.location.hash = `#${openCategories.join("#")}`;
      /*this.$router.replace({
        name: "kategorien",
        hash: `#${openCategories.join("#")}`
      });*/
    }
  },
  directives: {
    "b-toggle": VBToggle
  }
};
</script>