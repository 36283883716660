<template>
  <div class="container">
    <template v-if="vorlage && $can('manage', 'GlobalOfficeSettings')">
      <b-button variant="light" class="mt-4" @click="$router.go(-1)">
        <b-icon-chevron-left></b-icon-chevron-left>&nbsp;Zur Liste
      </b-button>
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Vorlage"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label-cols-sm="3"
            label="Titel:"
            label-align-sm="right"
            label-for="titel"
          >
            <b-form-input id="titel" v-model="vorlage.titel"></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Schwellwert für Alarmierung:"
            label-align-sm="right"
            label-for="threshold"
          >
            <b-form-input
              id="threshold"
              type="number"
              v-model="vorlage.threshold"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Liste von SMS Nummern:"
            label-align-sm="right"
            label-for="smsnummern"
          >
            <b-form-tags
              input-id="smsnummern"
              v-model="vorlage.smsnummern"
              input-type="tel"
              tag-variant="primary"
              tag-pills
              :separator="[' ', ',']"
              add-button-text="+"
              placeholder="Mobilnummer eingeben..."
              duplicate-tag-text="Doppelter Eintrag"
            ></b-form-tags>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Liste von Mailadressen:"
            label-align-sm="right"
            label-for="mailadressen"
          >
            <b-form-tags
              input-id="mailadressen"
              v-model="vorlage.mailadressen"
              input-type="email"
              tag-variant="primary"
              tag-pills
              :separator="[' ', ',']"
              add-button-text="+"
              placeholder="Mailadresse eingeben..."
              duplicate-tag-text="Doppelter Eintrag"
            ></b-form-tags>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Wochentage:"
            label-align-sm="right"
            label-for="weekdays"
          >
            <b-form-checkbox-group
              v-model="vorlage.wochentage"
              :options="wochentage"
              id="weekdays"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Uhrzeit von:"
            label-align-sm="right"
            label-for="clockfrom"
          >
            <b-form-input
              id="clockfrom"
              type="time"
              v-model="vorlage.von"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label-cols-sm="3"
            label="Uhrzeit bis:"
            label-align-sm="right"
            label-for="clockuntil"
          >
            <b-form-input
              id="clockuntil"
              type="time"
              v-model="vorlage.bis"
            ></b-form-input>
          </b-form-group>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button :variant="savedInfo || 'primary'" @click="saveDaten">
              <template v-if="savedInfo == 'success'">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else-if="savedInfo == 'danger'">
                <b-icon-x aria-hidden="true"></b-icon-x>Nicht gespeichert
              </template>
              <template v-else>Speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>
    </template>
    <template v-else-if="$can('manage', 'GlobalOfficeSettings')">
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Systemeinstellungen"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-form-group
            label-cols-sm="9"
            label="Zeitraum für Berechnung Schwellenwert (in Minuten):"
            label-align-sm="right"
          >
            <b-form-input
              id="clockfrom"
              type="number"
              v-model="zeitraum"
            ></b-form-input>
          </b-form-group>
        </b-form-group>

        <template slot="footer">
          <div class="text-right">
            <b-button
              :variant="savedSettingsInfo ? 'success' : 'primary'"
              @click="saveSettings"
            >
              <template v-if="savedSettingsInfo">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else>Einstellung speichern</template>
            </b-button>
          </div>
        </template>
      </b-card>
      <b-card bg-variant="light" class="mt-4">
        <b-form-group
          label-cols-lg="3"
          label="Alarmierungsliste"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-0"
        >
          <b-row class="mt-3">
            <b-col cols="auto" class="mr-auto">
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Suche"
              ></b-form-input>
            </b-col>
            <b-col cols="auto">
              <b-button variant="primary" @click="createVorlage"
                >Neue Alarmierung</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                striped
                fixed
                hover
                :items="vorlagen"
                :fields="fields"
                :filter="filter"
                class="mt-3"
              >
                <template v-slot:table-colgroup="data">
                  <col
                    v-for="field in data.fields"
                    :key="field.key"
                    :style="{
                      width: field.key === 'aktion' ? '120px' : 'auto',
                    }"
                  />
                </template>
                <template v-slot:cell(titel)="data">{{
                  data.item.titel
                }}</template>
                <template v-slot:cell(aktion)="data">
                  <b-button-group>
                    <b-button
                      variant="light"
                      :to="`/einstellungen/globaloffice/${data.item.id}`"
                      size="sm"
                    >
                      <b-icon-pencil></b-icon-pencil>
                    </b-button>
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="deleteVorlage(data.item)"
                    >
                      <b-icon-trash></b-icon-trash>
                    </b-button>
                  </b-button-group>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form-group>
      </b-card>
    </template>
  </div>
</template>

<script>
import GlobalOffice from "@/api/globaloffice";
import store from "@/store";

import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormTags,
  BButton,
  BButtonGroup,
  BTable,
  BIconPencil,
  BIconChevronLeft,
  BIconCheck,
  BIconX,
  BIconTrash,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormTags,
    BButton,
    BButtonGroup,
    BTable,
    BIconPencil,
    BIconChevronLeft,
    BIconCheck,
    BIconX,
    BIconTrash,
  },
  store,
  name: "GlobalOffice",
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      vorlagen: [],
      zeitraum: null,
      savedInfo: null,
      savedSettingsInfo: null,
      filter: null,
      fields: [
        {
          key: "titel",
          label: "Titel",
          sortable: true,
        },
        {
          key: "von",
          label: "Von",
          sortable: false,
        },
        {
          key: "bis",
          label: "Bis",
          sortable: false,
        },
        {
          key: "aktion",
          label: "Aktionen",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    GlobalOffice.get().then((response) => {
      this.vorlagen = response.data.vorlagen;
      this.zeitraum = response.data.zeitraum;
    });
  },
  computed: {
    vorlage: {
      get() {
        if (!this.$route.params.id) return null;
        return this.vorlagen.find((u) => u.id === this.$route.params.id);
      },
      set(val) {
        let i = this.vorlagen.findIndex((u) => u.id === val.id);
        if (i > -1) {
          this.vorlage[i] = val;
        } else {
          this.vorlagen.push(val);
        }
      },
    },
    wochentage: {
      get() {
        return [
          { text: "Montag", value: "Mo" },
          { text: "Dienstag", value: "Di" },
          { text: "Mittwoch", value: "Mi" },
          { text: "Donnerstag", value: "Do" },
          { text: "Freitag", value: "Fr" },
          { text: "Samstag", value: "Sa" },
          { text: "Sonntag", value: "So" },
        ];
      },
    },
  },
  methods: {
    createVorlage() {
      this.vorlagen.push({ id: "neu" });
      this.$router.push({
        name: "globalofficesettings",
        params: { id: "neu" },
      });
    },
    deleteVorlage(vorlage) {
      if (!confirm("Alarmierung wirklich löschen?")) return;
      GlobalOffice.delete(vorlage.id).then((response) => {
        if (response.data.success) {
          let idx = this.vorlagen.findIndex((r) => r.id === vorlage.id);
          if (idx > -1) this.vorlagen.splice(idx, 1);
        }
      });
    },
    saveSettings() {
      GlobalOffice.updateGeneralSettings(this.zeitraum).then((response) => {
        if (response.data && response.data.success) {
          this.savedSettingsInfo = "success";
        } else {
          this.savedSettingsInfo = "danger";
        }
        window.setTimeout(() => {
          this.savedSettingsInfo = null;
        }, 5000);
      });
    },
    saveDaten() {
      let self = this;
      GlobalOffice.update(this.vorlage).then((response) => {
        if (response.data && response.data.success) {
          this.savedInfo = "success";
          self.vorlage = response.data.vorlage;
          if (self.$route.params.id != response.data.vorlage.id) {
            // Vorlage mit "neu" entfernen und die mit der neu erhaltenen ID laden
            let idx = self.vorlagen.findIndex(
              (r) => r.id === self.$route.params.id
            );
            if (idx > -1) self.vorlagen.splice(idx, 1);
            this.$router.replace({
              name: "globalofficesettings",
              params: { id: response.data.vorlage.id },
            });
          }
        } else {
          this.savedInfo = "danger";
        }
        window.setTimeout(() => {
          this.savedInfo = null;
        }, 5000);
      });
    },
  },
};
</script>
