import { Api } from "."

export default {
  search: (search, kdnr) => {
    return Api.get("customer/", {
      params: {
        search: search,
        kdnr: kdnr
      }
    })
  },
  getProducts: (organiceId) => {
    return Api.get("customer/products/", { params: { organiceId: organiceId } })
  }
}
