<template>
  <b-modal
    :id="id"
    ref="vorquali-modal"
    size="xl"
    title="Vorqualifizierung"
    ok-only
    ok-title="Schließen"
    hide-header-close
    @ok="handleOk"
    @close="handleOk"
    header-text-variant="light"
    header-bg-variant="secondary"
    @show="show"
    @hide="hide"
    reloadKunden
    :no-close-on-backdrop="true"
  >
    <div class="container-fluid">
      <b-card v-if="currentStep" class="pb-3 mb-2">
        <template v-slot:header>
          <b-button variant="light" @click="stepBack()" size="sm">
            <b-icon-chevron-left></b-icon-chevron-left>
          </b-button>
          <template v-for="(b, index) in breadcrumbSteps">
            <span :key="index">
              <b-icon-chevron-right
                v-if="index > 0"
                shift-v="-1"
              ></b-icon-chevron-right>
              <b-button
                variant="light"
                size="sm"
                @click="gotoStep({ value: b.step })"
                >{{ b.title }} ({{ b.answer }})</b-button
              >
            </span>
          </template>
        </template>
        <b-card-text>
          <h4>{{ currentStep.title }}</h4>
          {{ currentStep.subtitle }}
          <p v-html="currentStep.notice"></p>
          <p v-html="currentStep.description"></p>

          <b-alert
            variant="danger"
            :show="currentStep.alert != null"
            v-html="currentStep.alert"
          ></b-alert>

          <template v-if="currentStep.includes">
            <!-- Includes -->
            <hr />
            <div class="row">
              <template v-for="(i, index) in currentStep.includes">
                <b-embed
                  :type="i.type"
                  :aspect="i.ratio"
                  :src="i.url"
                  :key="index"
                ></b-embed>
              </template>
            </div>
          </template>
          <template v-if="currentStep.portabfrage">
            <b-row>
              <b-col cols="auto">
                <b-button
                  variant="success"
                  @click="createPortabfrage"
                  class="mb-2"
                  >Neue Portabfrage starten</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="auto">
                <b>Bisherige Portabfragen:</b> {{ portAbfragenListe.length }}
              </b-col>
            </b-row>

            <!-- loading ? -->
            <b-row v-if="isLoading" align-v="center" align-h="center">
              <b-col cols="auto">
                <b-spinner
                  variant="primary"
                  label="Portabfrage wird geladen..."
                ></b-spinner>
              </b-col>
              <b-col cols="auto">Portabfrage wird geladen...</b-col>
            </b-row>
            <b-row align-v="center" align-h="center">
              <b-col cols="auto" v-if="responseInvalidText.length > 0">{{
                responseInvalidText
              }}</b-col>
            </b-row>

            <!-- Liste der Portabfragen -->
            <portabfrage-liste
              :list="portAbfragenListe"
              :expandFirst="expandNewPortabfrage"
              :max="portAbfrageListeMax"
            ></portabfrage-liste>
            <b-button
              v-show="portAbfrageListeMax !== portAbfragenListe.length"
              variant="light"
              @click="portAbfrageListeMax = portAbfragenListe.length"
              >...</b-button
            >
          </template>
        </b-card-text>
        <template v-slot:footer>
          <b-row>
            <b-col cols="auto" class="mr-auto"></b-col>
            <b-col cols="auto">
              <template v-if="currentStep.answers">
                <!-- Answers -->
                <b-button
                  v-for="b in currentStep.answers"
                  :key="b.title"
                  @click="gotoStep(b)"
                  variant="secondary"
                  :class="['ml-2', currentStep.btncls]"
                  :title="b.title"
                  >{{ b.shortTitle }}</b-button
                >
              </template>
              <template v-if="currentStep.exits">
                <b-button
                  v-for="b in currentStep.exits"
                  :key="b.title"
                  variant="primary"
                  :class="['ml-2']"
                  @click="exit(b.ticket)"
                  >{{ b.title }}</b-button
                >
              </template>
            </b-col>
          </b-row>
        </template>
      </b-card>
      <b-row>
        <b-col>
            <a href="/files/18C00C7FFD8/SIP-Status_pruefen.pdf" target="_blank"
              >SIP-Status prüfen
              <b-icon-arrow-up-right-square></b-icon-arrow-up-right-square></a
            ><br />
            <a href="/files/18C00C7FFDE/Sperre_pruefen.pdf" target="_blank"
              >Anschlusssperre prüfen
              <b-icon-arrow-up-right-square></b-icon-arrow-up-right-square
            ></a>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import PortabfrageListe from "@/components/PortAbfrageListe";
import Ticket from "@/api/tickets";

export default {
  name: "VorqualifizierungModal",
  components: {
    PortabfrageListe,
  },
  props: {
    id: String,
    kundennummer: String,
  },
  data() {
    return {
      story: null,
      breadcrumbSteps: [],
      portAbfragenListe: [],
      portAbfrageListeMax: 0,
      responseInvalidText: "",
      expandNewPortabfrage: false,
      routerChangeToTicketId: null,
    };
  },
  computed: {
    currentStep() {
      if (!this.story) return null;
      let step = this.story.steps[this.currentStepName];
      if (step.includes && step.includes.length) {
        // Prüfe, ob im Storyboard konfiguriert ist, dass die Kundenummer angehangen werden soll
        step.includes.forEach((incl) => {
          if (incl.kdnr) {
            if (incl.url.indexOf("?") > -1) {
              // bereits Parameter vorhanden -> hänge mit & an
              incl.url = `${incl.url}&${incl.kdnr}=${this.kunde.number}`;
            } else {
              // erster Parameter, hänge mit ? an
              incl.url = `${incl.url}?${incl.kdnr}=${this.kunde.number}`;
            }
          }
        });
      }
      return step;
    },
    currentStepName() {
      if (this.breadcrumbSteps.length == 0) return "start";
      return this.breadcrumbSteps[this.breadcrumbSteps.length - 1].nextStep;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    show() {
      // On modal shown
      this.loadStorybook();
      this.breadcrumbSteps = [];
      this.portAbfragenListe = [];
    },
    hide() {
      // On modal hide
    },
    gotoStep(b) {
      // prüfe, ob der Schritt bereits besucht wurde, falls ja: gehe dahin zurück, sonst füge ihn hinzu
      let stepIndex = this.breadcrumbSteps.findIndex((s) => {
        return s.step === b.value;
      });
      if (stepIndex < 0) {
        // Schritt noch nicht besucht
        this.breadcrumbSteps.push({
          step: this.currentStepName,
          title: this.currentStep.title,
          answer: b.shortTitle,
          nextStep: b.value,
        });

        // start portabfrage to fill list
        this.currentStep.portabfrage ? this.loadPortAbfrageListe() : "";
      } else {
        // Navigiere zurück zu Schritt
        this.breadcrumbSteps.splice(stepIndex);
      }
    },
    stepBack() {
      if (this.breadcrumbSteps.length > 0) this.breadcrumbSteps.pop();
      else this.$emit("exit", null);
    },
    exit(data) {
      this.$emit("exit", {
        vorquali: this.breadcrumbSteps,
        ticket: data,
      });
      this.$nextTick(() => {
        this.$refs["vorquali-modal"].hide();
      });
    },
    createPortabfrage() {
      /* TODO: später für EINS implementieren
      Port.createPortabfrage(this.produkt.contractNumber).then((response) => {
        if (response.data.success) {
          // when a new portabfrage was requested... exand this new one
          this.expandNewPortabfrage = true;
          this.loadPortAbfrageListe();
        }
      });*/
    },
    loadPortAbfrageListe() {
      /* TODO: später für EINS implementieren
      Port.getPortabfrageList(this.produkt.contractNumber).then((response) => {
        if (response.data.length !== 0) {
          this.portAbfragenListe = response.data;
          this.portAbfragenListe.length <= 10
            ? (this.portAbfrageListeMax = this.portAbfragenListe.length)
            : (this.portAbfrageListeMax = 10);
        } else {
          this.portAbfrageListeMax = 0;
        }
      });
      */
    },
    loadStorybook() {
      //temporär ausprogrammiert, damit wir im Rahmen der Entwicklung arbeiten können, auch wenn noch kein Server verfügbar
      if (process.env.NODE_ENV === "development") {
        this.story = require("@/assets/storybook.json");
      } else {
        Ticket.getVorqualiStorybook().then((response) => {
          if (response.data) this.story = response.data;
        });
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.exit(null);
    },
  },
};
</script>