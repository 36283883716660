<template>
  <b-col cols="12">
    <b-row>
      <b-col cols="12">
        <b-row>
          <!--
            <b-form-group label-for="customerId" label="KundenOrganiceId" class="col-md-6 col-12">
            <div v-if="ticket.organiceKundenId">
              <b-form-input id="customerId" v-model="ticket.organiceKundenId" readonly></b-form-input>
            </div>
            <div v-else>
              <span class="text-danger">Der Vertrag / Kunde ist im Organice nicht bekannt</span>
            </div> 
          </b-form-group>
          -->
          <b-form-group class="col-md-6 col-12" v-if="ticket.vertragsnummer" label-for="contractNumber" label="Vertragsnummer">
            <b-form-input id="contractNumber" v-model="ticket.vertragsnummer" readonly></b-form-input>
          </b-form-group>
          <!-- <b-form-group class="col-md-6 col-12" label-for="kundennummer" label="Kundennummer">
            <b-form-input id="kundennummer" v-model="ticket.kundennummer" readonly></b-form-input>
          </b-form-group> -->
          <b-form-group class="col-sm-12 col-md-6" label-for="kundenname" label="Name des Kunden">
            <b-form-input id="kundenname" v-model="ticket.kundenname" readonly></b-form-input>
          </b-form-group>
        </b-row>
      </b-col>
      <b-form-group v-if="ticket.organiceKundenId" class="col-sm-12 col-md-6" label-for="email" label="E-Mail">
        <b-form-input id="email" v-model="ticket.email" readonly autocomplete="new-password"></b-form-input>
      </b-form-group>
      <b-form-group v-if="ticket.organiceKundenId" class="col-sm-12 col-md-6" label-for="rufnummer" label="Telefon">
        <b-form-input id="rufnummer" v-model="ticket.rufnummer" readonly autocomplete="new-password"></b-form-input>
      </b-form-group>
    </b-row>
  </b-col>
</template>

<script>
import { BCol, BRow, BFormGroup, BFormInput } from "bootstrap-vue";

export default {
  name: "StammdatenBtc",
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
  },
  props: {
    ticket: { type: Object, required: true },
  },
};
</script>
