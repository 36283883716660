<template>
  <b-col cols="12">
    <loader></loader>
    <b-alert v-if="communicationErrors != null" show variant="danger">{{ communicationErrors }}</b-alert>
    <b-row>
      <b-col cols="12" class="mb-2">
        <!-- <template  v-for="abkuerzung in bekannteMeldungstypen">
          <b-dropdown class="m-2" right split :text="abkuerzung" :key="abkuerzung">
            <template v-for="position in filterMeldungspositionenByTyp(abkuerzung)">
              <b-dropdown-item :key="position.id">{{ position.text }}</b-dropdown-item>
            </template>
          </b-dropdown>
        </template> -->
        <template v-for="abkuerzung in bekannteMeldungstypenAbkuerzungen">
          <!-- MTMAM wird nur automatisch versendet -->
          <b-row v-if="abkuerzung !== 'MTAM'" :key="abkuerzung">
            <b-col cols="12">
              <b-card class="mb-2">
                <!-- Top -->
                <b-row>
                  <b-col cols="9">
                    <label> {{ meldungstypName(abkuerzung) }} ({{ abkuerzung }})</label>
                  </b-col>
                  <b-col cols="3">
                    <label
                      >Gesendet<b-badge class="p-1 ml-3" :variant="gesendeteMeldungenVomTyp(abkuerzung) > 0 ? 'success' : 'secondary'">{{
                        gesendeteMeldungenVomTyp(abkuerzung)
                      }}</b-badge></label
                    >
                  </b-col>
                </b-row>
                <!-- Down -->
                <b-row>
                  <b-col cols="9" class="mb-3">
                    <b-form-select
                      v-model="selectedMeldungsposition"
                      :options="filterMeldungspositionenByTyp(abkuerzung)"
                      size="sm"
                      :key="abkuerzung + '_meldung'"
                      :disabled="!meldungstypErlaubt(abkuerzung)"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <!-- Eingabefelder -->
                <b-row v-if="selectedMeldungsposition && selectedMeldungsposition.typenList && selectedMeldungsposition.typenList.abkuerzung === abkuerzung">
                  <b-col v-if="abkuerzung === 'ABM'" cols="4">
                    <b-form-group label-for="verbindlicherLiefertermin" label="Verbindlicher Liefertermin">
                      <b-form-datepicker
                        id="verbindlicherLiefertermin"
                        v-model="selectedTermine.verbindlicherLiefertermin"
                        right
                        button-variant="light"
                        v-bind="$data.datepickerLabels"
                        reset-button
                        :date-format-options="$data.datepickerDateFormatOptions"
                        :state="!$v.selectedTermine.verbindlicherLiefertermin.$invalid"
                        locale="de"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="abkuerzung === 'ABBM' || abkuerzung === 'TAM' || abkuerzung === 'MTAM'" cols="4">
                    <b-form-group label-for="wiedervorlagetermin" label="Wiedervorlagetermin">
                      <b-form-datepicker
                        id="wiedervorlagetermin"
                        v-model="selectedTermine.wiedervorlagetermin"
                        right
                        button-variant="light"
                        v-bind="$data.datepickerLabels"
                        reset-button
                        :date-format-options="$data.datepickerDateFormatOptions"
                        locale="de"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="abkuerzung === 'ERLM'" cols="4">
                    <b-form-group label-for="erledigungstermin" label="Erledigungstermin">
                      <b-form-datepicker
                        id="erledigungstermin"
                        v-model="selectedTermine.erledigungstermin"
                        right
                        button-variant="light"
                        v-bind="$data.datepickerLabels"
                        reset-button
                        :date-format-options="$data.datepickerDateFormatOptions"
                        :state="!$v.selectedTermine.erledigungstermin.$invalid"
                        locale="de"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="abkuerzung === 'VZM'" cols="4">
                    <b-form-group label-for="verzoegerungstermin" label="Verzögerungstermin">
                      <b-form-datepicker
                        id="verzoegerungstermin"
                        v-model="selectedTermine.verzoegerungstermin"
                        right
                        button-variant="light"
                        v-bind="$data.datepickerLabels"
                        reset-button
                        :date-format-options="$data.datepickerDateFormatOptions"
                        locale="de"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col cols="9" class="mb-3">
                    <label for="">Freitext</label>
                    <b-form-textarea id="freitext" v-model="freitext" rows="4" placeholder="Max. 160 Zeichen" :state="!$v.freitext.$invalid" trim></b-form-textarea>
                  </b-col>
                  <b-col cols="3" class="d-flex justify-content-end align-items-end">
                    <b-button variant="success" title="Meldung an BTC senden" @click="meldungAbsenden(abkuerzung)" :disabled="!meldungstypErlaubt(abkuerzung)"
                      >Absenden <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { BRow, BCol, BFormSelect, BAlert, BCard, BButton, BIconChevronRight, BFormTextarea, BFormGroup, BFormDatepicker, BBadge } from "bootstrap-vue";
import apiPlugin from "@/api/plugin";
import Loader from "@/components/Loader";
import Config from "@/mixins/Config";
import Ticket from "@/api/tickets";
import { required, maxLength } from "vuelidate/lib/validators";

import { DateTime } from "luxon";
import _ from "lodash";

const MELDUNGSTYP = {
  ABBM: "18460FC5D47",
  ABM: "1843DE2656B",
  ERLM: "1843DE26573",
  TAM: "1843DE2656F"
};

export default {
  name: "WorkflowBtc",
  components: {
    BRow,
    BCol,
    BFormSelect,
    BCard,
    BButton,
    BIconChevronRight,
    BFormTextarea,
    BFormGroup,
    BFormDatepicker,
    BBadge,
    BAlert,
    Loader,
  },
  mixins: [Config],
  data() {
    return {
      selectedMeldungsposition: null,
      freitext: "",
      selectedTermine: {
        verbindlicherLiefertermin: null,
        wiedervorlagetermin: null,
        erledigungstermin: null,
        verzoegerungstermin: null,
      },
      communicationErrors: null,
    };
  },
  validations() {
    // Wir bauen das validation Objekt.... requirements ändern sich, je nach Auswahl des Meldungstyps

    // Immer required
    let validation = {
      selectedMeldungsposition: {
        required,
      },
      freitext: {
        maxLength: maxLength(160), //nicht required!
      },
    };

    if (this.selectedMeldungsposition) {
      switch (this.selectedMeldungsposition.typenList.id) {
        case MELDUNGSTYP.ABM:
          validation.selectedTermine = {
            verbindlicherLiefertermin: {
              required,
            },
          };
          break;
        case MELDUNGSTYP.ERLM:
          validation.selectedTermine = {
            erledigungstermin: {
              required,
            },
          };
          break;
        default:
          break;
      }
    }
    return validation;
  },
  props: {
    btcAuftrag: {
      type: Object,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meldungspositionen() {
      return this.$store.state.meldungspositionen;
    },
    bekannteMeldungstypenAbkuerzungen() {
      let meldungstypen = _.sortBy(this.meldungspositionen, (mp) => mp.typenList.sort);
      meldungstypen = _.map(meldungstypen, (mp) => mp.typenList.abkuerzung);
      return _.uniq(meldungstypen);
    },
    meldungKommentar() {
      return `Meldung an BTC gesendet:
      Typ: ${this.selectedMeldungsposition.typenList.name} (${this.selectedMeldungsposition.typenList.abkuerzung})
      Text: ${this.selectedMeldungsposition.text}
      Freitext: ${this.freitext}
      Termine: ${this.selectedTermineAsDate}
      `;
    },
    // Date String Conversation --> Unix TS
    selectedTermineAsTS() {
      return {
        verbindlicherLiefertermin: this.dateStrToSeconds(this.selectedTermine.verbindlicherLiefertermin),
        wiedervorlagetermin: this.dateStrToSeconds(this.selectedTermine.wiedervorlagetermin),
        erledigungstermin: this.dateStrToSeconds(this.selectedTermine.erledigungstermin),
        verzoegerungstermin: this.dateStrToSeconds(this.selectedTermine.verzoegerungstermin),
      };
    },
    selectedTermineAsDate() {
      return `
          Verbindlicher Liefertermin: ${this.selectedTermine.verbindlicherLiefertermin ? this.selectedTermine.verbindlicherLiefertermin : ""}
          Wiedervorlagetermin: ${this.selectedTermine.wiedervorlagetermin ? this.selectedTermine.wiedervorlagetermin : ""}
          Erledigungstermin: ${this.selectedTermine.erledigungstermin ? this.selectedTermine.erledigungstermin : ""}
          Verzögerungstermin: ${this.selectedTermine.verzoegerungstermin ? this.selectedTermine.verzoegerungstermin : ""}
      `;
    },
  },
  methods: {
    formatDate(aDate) {
      if (aDate) {
        return DateTime.fromISO(aDate).toFormat("HH:mm dd.MM.yyyy");
      } else {
        return "";
      }
    },
    filterMeldungspositionenByTyp(abkuerzung) {
      let meldungspositionen = _.filter(this.meldungspositionen, (position) => position.typenList.abkuerzung === abkuerzung);
      return _.map(meldungspositionen, (mp) => {
        return {
          value: mp,
          text: mp.text,
        };
      });
    },
    meldungstypName(abkuerzung) {
      return _.find(this.meldungspositionen, (mp) => mp.typenList.abkuerzung === abkuerzung)?.typenList.name;
    },
    meldungstypId(abkuerzung) {
      return _.find(this.meldungspositionen, (mp) => mp.typenList.abkuerzung === abkuerzung)?.typenList.id;
    },
    meldungstypErlaubt(abkuerzung) {
      let erlaubt = false;
      // console.log("Check MeldungstypErlaubt: " + abkuerzung);
      _.each(this.meldungspositionen, (mp) => {
        if (mp.typenList.abkuerzung === abkuerzung && this.btcAuftrag.elaubteMeldungstypenIds.includes(mp.typenList.id)) {
          // console.log("erlaubt");
          erlaubt = true;
        }
      });
      return erlaubt;
    },
    gesendeteMeldungenVomTyp(abkuerzung) {
      return _.filter(this.btcAuftrag.abgesetzteMeldungen, (am) => am.gewaehlter_meldungstyp === this.meldungstypId(abkuerzung))?.length;
    },
    // blurFreitext() {
    //   alert("todo invalid msg + control");
    // },
    dateStrToSeconds(ds) {
      return ds ? DateTime.fromISO(ds).toSeconds() : null;
    },
    async meldungAbsenden() {
      this.communicationErrors = null;
      if (this.$v.$invalid) {
        this.communicationErrors = "Bitte geben Sie alle geforderten Informationen an um diese Meldung zu versenden.";
        return;
      }

      this.$store.state.loading++;
      try {
        let responseM = await apiPlugin.meldung.post(this.btcAuftrag.auftrag, this.selectedMeldungsposition, this.freitext, this.selectedTermineAsTS);
        if (responseM.status === 200) {
          if (this.selectedMeldungsposition.typenList.id === MELDUNGSTYP.ABBM || this.selectedMeldungsposition.typenList.id === MELDUNGSTYP.ERLM) {
            this.archiveTicket();
          } else if (this.selectedMeldungsposition.typenList.id === MELDUNGSTYP.ABM) {
            this.wartenAufTechnikerTicket();
          }else if (this.selectedMeldungsposition.typenList.id === MELDUNGSTYP.TAM) {
            this.terminbuchungTicket();
          }

          this.createComment();
        } else {
          this.communicationErrors = "Fehler beim senden der Meldung:" + responseM.status;
        }
      } catch (e) {
        this.communicationErrors = "Fehler beim senden der Meldung:" + e;
      } finally {
        this.$store.state.loading = 0;
        this.$emit("reload");
      }
    },
    terminbuchungTicket(){
      this.ticket.status = "TERMINBUCHUNG";
      this.$store.dispatch("updateTicket", [this.ticket]);
    },
    wartenAufTechnikerTicket() {
      this.ticket.status = "WARTEN_AUF_TECHNIKER";
      this.ticket.inBeobachtung = DateTime.fromSeconds(this.btcAuftrag.auftrag.geschaeftsfall.termine.kundenwunschtermin.datum).toISO({ suppressMilliseconds: true });
      this.$store.dispatch("updateTicket", [this.ticket]);
    },
    archiveTicket() {
      this.ticket.status = "ARCHIV";
      this.ticket.ticketabschlussText = "Geschlossen durch Meldung: " + this.selectedMeldungsposition;
      this.$store.dispatch("updateTicket", [this.ticket]);
    },
    createComment() {
      Ticket.createComment(this.ticket.id, this.meldungKommentar, null).then((responseTC) => {
        if (responseTC.data && responseTC.data.success) {
          this.ticket.aktivitaeten.push(responseTC.data.comment);
          this.ticket.letzteAktivitaet = new Date().toISOString();

          // Alles fein... Inhalte löschen
          this.selectedMeldungsposition = null;
          this.freitext = "";
        } else {
          this.communicationErrors = "Fehler beim Erstellen des Kommentars!";
        }
      });
    },
  },
  mounted() {
    this.$store.state.loading = 0;
  },
};
</script>
