<template>
  <div>
    <main-navigation></main-navigation>
    <div class="container-fluid scope-style">
      <b-row class="mt-5">
        <b-col>
          <h1>Dashboard</h1>
        </b-col>
      </b-row>
      <template v-if="$can('read', 'Dashboard')">
        <b-row>
          <b-col class="section-container">
            <h5>Alter Offener Tickets</h5>
            <div v-if="$can('create', 'Excelreport')" class="export" title="Excel-Export" @click="downloadExcelAge">
              <font-awesome-icon icon="download" />
            </div>
            <TicketAlterChart ref="RefAlterChart" @point-clicked="openModal"></TicketAlterChart>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="section-container">
            <h5>Tickets nach Ort</h5>
            <div v-if="$can('create', 'Excelreport')" class="export" title="Excel-Export" @click="downloadExcelCity">
              <font-awesome-icon icon="download" />
            </div>
            <GeoChart ref="RefGeoChart" @point-clicked="openModal"></GeoChart>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="section-container">
            <h5>Nicht zugewiesene Tickets</h5>
            <div v-if="$can('create', 'Excelreport')" class="export" title="Excel-Export" @click="downloadExcelUnassigned">
              <font-awesome-icon icon="download" />
            </div>
            <ChartUnzugewiesenChart
              ref="RefUnassignedChart"
              @point-clicked="openModal"
            ></ChartUnzugewiesenChart>
          </b-col>
          <b-col cols="12" md="6" class="section-container">
            <h5>Offene Mastertickets</h5>
            <div v-if="$can('create', 'Excelreport')" class="export" title="Excel-Export" @click="downloadExcelOpenMaster">
              <font-awesome-icon icon="download" />
            </div>
            <ChartMasterChart
              ref="RefOpenMasterChart"
              @point-clicked="openModal"
            ></ChartMasterChart>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="section-container">
            <h5>Neue Tickets, Störungsursache und mittlere Bearbeitungszeit</h5>
            <div v-if="$can('create', 'Excelreport')" class="export" title="Excel-Export" @click="downloadExcelNewExt">
              <font-awesome-icon icon="download" />
            </div>
            <ChartKWChart ref="RefNeuExtChart" @point-clicked="openModal"></ChartKWChart>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="section-container">
            <h5>Offene Tickets</h5>
            <div v-if="$can('create', 'Excelreport')" class="export" title="Excel-Export" @click="downloadExcelOpen">
              <font-awesome-icon icon="download" />
            </div>
            <TicketOffenChart ref="RefOffenChart" @point-clicked="openModal"></TicketOffenChart>
          </b-col>
          <b-col cols="12" md="6" class="section-container">
            <h5>Neue Tickets</h5>
            <div v-if="$can('create', 'Excelreport')" class="export" title="Excel-Export" @click="downloadExcelNew">
              <font-awesome-icon icon="download" />
            </div>
            <TicketNeuChart ref="RefNeuChart" @point-clicked="openModal"></TicketNeuChart>
          </b-col>
        </b-row>
      </template>
    </div>
    <ticketliste-statistik
      id="ticketlistestatistik"
      :tickets="modalTickets"
      :modalShow="modalShow"
      :title="modalTitle"
    ></ticketliste-statistik>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import TicketOffenChart from "@/components/charts/TicketOffenChart";
import TicketAlterChart from "@/components/charts/TicketAlterChart";
import TicketNeuChart from "@/components/charts/TicketNeuChart";
import GeoChart from "@/components/charts/TicketGeoChart";
import ChartKWChart from "@/components/charts/TicketKWChart";
import ChartMasterChart from "@/components/charts/TicketMasterChart";
import ChartUnzugewiesenChart from "@/components/charts/TicketUnzugewiesenChart";
import TicketlisteStatistik from "@/components/modals/TicketlisteStatistik";
import { BCol, BRow } from "bootstrap-vue";

export default {
  name: "Dashboard",
  components: {
    MainNavigation,
    BCol,
    BRow,
    TicketOffenChart,
    TicketAlterChart,
    TicketNeuChart,
    GeoChart,
    ChartKWChart,
    ChartMasterChart,
    ChartUnzugewiesenChart,
    TicketlisteStatistik,
  },
  data() {
    return {
      modalTickets: [],
      modalTitle: "Tickets",
      modalShow: false,
    };
  },
  methods: {
    downloadExcelOpen() {
      this.$refs.RefOffenChart.excel();
    },
    downloadExcelAge() {
      this.$refs.RefAlterChart.excel();
    },
    downloadExcelNew() {
      this.$refs.RefNeuChart.excel();
    },
    downloadExcelCity() {
      this.$refs.RefGeoChart.excel();
    },
    downloadExcelNewExt() {
      this.$refs.RefNeuExtChart.excel();
    },
    downloadExcelUnassigned() {
      this.$refs.RefUnassignedChart.excel();
    },
    downloadExcelOpenMaster() {
      this.$refs.RefOpenMasterChart.excel();
    },
    openModal([title, tickets]) {
      this.modalTitle = title;
      this.modalTickets = tickets;
      this.modalShow = true;
    },
  },
};
</script>

<style lang="scss">
.scope-style h5 {
  color: #8b8b8b;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 6px solid #8b8b8b;
  padding-bottom: 4px;
  margin-bottom: 0;
  margin-top: 15px;
}

.scope-style canvas.chartjs-render-monitor {
  background: #fafafa;
  padding-top: 10px;
  width: 100%;
  height: 300px;
}

.scope-style .btn-container {
  background: #fafafa;
}

.scope-style .pdr-0 {
  padding-right: 0 !important;
}

.section-container,
.chart-container {
  position: relative;
}

div.export {
  position: absolute;
  top: 12px;
  right: 16px;
  color: #8b8b8b;
}
div.export:hover {
  color: #333;
  cursor: pointer;
}

.chart-container.loading:before {
  content: "Lädt...";
  display: block;
  position: absolute;
  background: #fafafadd;
  width: 100%;
  color: #8b8b8b;
  height: 100%;
  text-align: center;
  padding-top: 5%;
  font-size: 24px;
}
</style>
