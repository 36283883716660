<template>
  <b-col>
    <b-card header-tag="nav">
      <b-button-group>
        <b-button
          :variant="modalCommentType == 'comment' ? 'primary' : 'light'"
          @click="modalCommentType = 'comment'"
          v-if="$can('create', emptyKommentar)"
          :disabled="modalTextInput != undefined && modalTextInput != null && modalTextInput != ''"
          >Kommentar hinterlegen</b-button
        >
        <b-button
          :variant="modalCommentType == 'mail' ? 'primary' : 'light'"
          @click="modalCommentType = 'mail'"
          v-if="$can('create', emptyMail)"
          :disabled="!ticket.organiceKundenId || (modalTextInput != undefined && modalTextInput != null && modalTextInput != '')"
          >E-Mail an Kunde</b-button
        >
        <b-button
          :variant="modalCommentType == 'sms' ? 'primary' : 'light'"
          @click="modalCommentType = 'sms'"
          v-if="$can('create', emptySMS)"
          :disabled="!ticket.organiceKundenId || (modalTextInput != undefined && modalTextInput != null && modalTextInput != '')"
          >SMS an Kunde</b-button
        >
        <b-button
          v-if="ticket.kategorie === 'MASTER'"
          :variant="modalCommentType == 'all' ? 'primary' : 'light'"
          @click="modalCommentType = 'all'"
          :disabled="modalTextInput != undefined && modalTextInput != null && modalTextInput != ''"
          >Alle Kunden benachrichtigen</b-button
        >

        <b-button variant="danger" v-if="modalCommentType != null" @click="(modalCommentType = null), (modalTextInput = null)">Schließen</b-button>
      </b-button-group>
      <div v-if="modalCommentType != null && modalCommentType !== 'all'" class="pt-2">
        <b-form-group v-if="modalReceiverType" :state="modalReceiverState" label="Empfänger" label-for="receiver-input" invalid-feedback="Empfänger erforderlich">
          <b-form-input
            ref="receiver-input"
            id="receiver-input"
            :key="receiverKey"
            v-model="receiver"
            @blur="receiverKey++"
            :state="modalReceiverState"
            :type="modalReceiverType"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group v-if="modalReceiverType" label="Vorlage" label-for="vorlage-input">
          <b-form-select id="vorlage-input" v-model="selectedVorlage" :options="textvorlagen" @change="insertVorlage"></b-form-select>
        </b-form-group>

        <b-form-group :state="modalTextState" label="Text" label-for="text-input" invalid-feedback="Text erforderlich">
          <b-form-textarea ref="text-input" id="text-input" v-model="modalTextInput" :state="modalTextState" :rows="modalCommentType === 'sms' ? 3 : 10" required></b-form-textarea>
        </b-form-group>

        <b-form-group v-if="modalCommentType == 'comment'">
          <b-form-file v-model="modalFile" placeholder="Datei anhängen" drop-placeholder="Datei hierher ziehen..." browse-text="Durchsuchen"></b-form-file>
        </b-form-group>
        <b-alert v-if="modalError != null" show variant="danger">{{ modalError }}</b-alert>
        <b-button @click="handleOk">Absenden</b-button>
      </div>
      <!-- Alle Benachrichtigen -->
      <div v-if="modalCommentType != null && modalCommentType === 'all'" class="pt-2">
        <!-- Mail-"Box" -->
        <b-form-group :label="'E-Mail Empfänger: ' + alleKundenEmpfaenger('MAIL').length" label-for="receiver-mail"> </b-form-group>
        <b-form-group label="Vorlage" label-for="vorlage-input">
          <b-form-select id="vorlage-input" v-model="selectedVorlageAlleMail" :options="textvorlagen" @change="insertVorlageAlleMail"></b-form-select>
        </b-form-group>

        <b-form-group :state="modalTextState" label="E-Mail Text" label-for="text-input-alle-mail" invalid-feedback="Text erforderlich">
          <b-form-textarea ref="text-input-alle-mail" id="text-input-alle-mail" v-model="modalTextInputAlleMail" :state="modalTextState" :rows="10" required></b-form-textarea>
        </b-form-group>
        <!-- SMS-"Box" -->
        <b-form-group v-if="modalCommentType === 'all'" :label="'SMS Empfänger: ' + alleKundenEmpfaenger('SMS').length" label-for="receiver-sms"> </b-form-group>
        <b-form-group v-if="modalReceiverType || modalCommentType === 'all'" label="Vorlage" label-for="vorlage-input">
          <b-form-select id="vorlage-input" v-model="selectedVorlageAlleSMS" :options="textvorlagen" @change="insertVorlageAlleSMS"></b-form-select>
        </b-form-group>

        <b-form-group :state="modalTextState" label="SMS Text" label-for="text-input-alle-sms" invalid-feedback="Text erforderlich">
          <b-form-textarea ref="text-input-alle-sms" id="text-input-alle-sms" v-model="modalTextInputAlleSMS" :state="modalTextState" :rows="3" required></b-form-textarea>
        </b-form-group>

        <b-alert v-if="modalError != null" show variant="danger">{{ modalError }}</b-alert>
        <b-button @click="handleOk">Absenden</b-button>
      </div>
    </b-card>

    <b-row class="mt-3">
      <b-col>
        <b-table stacked="lg" :items="aktivitaeten" :fields="fields" class="activities-table">
          <!-- Spaltenbreiten -->
          <template v-slot:table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.key === 'date' ? '180px' : field.key === 'bearbeiter_informiert' ? 'auto' : 'auto' }" />
          </template>
          <!-- Datetime-Zelle -->
          <template v-slot:cell(date)="data">
            <span>{{ formatDate(data.item.erstelltAm) }}</span>
          </template>
          <!-- Tätigkeit-Zelle -->
          <template v-slot:cell(title)="data">
            <div>
              <b-badge class="mr-2" :variant="!data.item.erstelltVon.name && !data.item.erstelltVon.vorname ? 'warning' : 'dark'">
                <b-icon-person></b-icon-person>
                {{ data.item.erstelltVon.name }}
                <template v-if="data.item.erstelltVon.vorname">,</template>
                {{ data.item.erstelltVon.vorname }}
                <i v-if="!data.item.erstelltVon.name && !data.item.erstelltVon.vorname">unbekannt</i>
              </b-badge>
              {{ data.item.kategorie.titel }}
              <template v-if="data.item.gesendetAn">an {{ data.item.gesendetAn }}</template>
              <template v-if="!data.item.kategorie.showText">
                <b-icon-chevron-right></b-icon-chevron-right>
                &nbsp;{{ data.item.text }}
              </template>
            </div>
            <div v-if="data.item.dokument">
              <a :href="data.item.dokument.path" target="_blank" class="badge badge-primary">
                <b-icon-download />
                {{ data.item.dokument.name }} ({{ Math.floor(data.item.dokument.size / 1024) }}
                kb)
              </a>
            </div>
            <div v-if="data.item.kategorie.showText">
              <div class="historyText mt-2">{{ data.item.text }}</div>
            </div>
          </template>
          <template v-slot:cell(bearbeiter_informiert)="data">
            <b-button size="sm" variant="success" @click="bearbeiterInformieren(data.item.id)" :disabled="data.item.bearbeiter_informiert">
              {{ data.item.bearbeiter_informiert ? "bereits benachrichtigt" : "jetzt benachrichtigen" }}
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BButton,
  BIconPerson,
  BBadge,
  BIconChevronRight,
  BIconDownload,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BAlert,
  BCard,
  BButtonGroup,
} from "bootstrap-vue";
import { DateTime } from "luxon";
import { Kommentar, SMS, Mail } from "@/classes/entities";
import Ticket from "@/api/tickets";
import store from "@/store";
import MixinWorker from "@/mixins/Worker";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BButton,
    BIconPerson,
    BIconChevronRight,
    BIconDownload,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormFile,
    BAlert,
    BCard,
    BButtonGroup,
  },
  store,
  mixins: [MixinWorker],
  props: {
    ticket: { type: Object, required: true },
  },
  data: function () {
    return {
      modalCommentType: null,
      modalReceiverInput: null,
      modalTextInput: null,
      modalTextInputAlleMail: null,
      modalTextInputAlleSMS: null,
      modalReceiverState: null,
      modalTextState: null,
      modalError: null,
      selectedVorlage: null,
      selectedVorlageAlleMail: null,
      selectedVorlageAlleSMS: null,
      modalFile: null,
      receiverKey: 0,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "date",
          label: "Zeit",
          sortable: false,
        },
        {
          key: "title",
          label: "Tätigkeit",
          sortable: false,
        },
        {
          key: "bearbeiter_informiert",
          label: "Verantwortlichen per Mail informieren",
          sortable: false,
        },
      ];
    },
    aktivitaeten() {
      let a = this.ticket.aktivitaeten;
      if (!a) return [];
      return a.sort((a, b) => {
        return DateTime.fromISO(b.erstelltAm) - DateTime.fromISO(a.erstelltAm);
      });
    },
    emptyKommentar() {
      return new Kommentar({ kategorie: this.ticket.kategorie });
    },
    emptyMail() {
      return new Mail({ kategorie: this.ticket.kategorie });
    },
    emptySMS() {
      return new SMS({ kategorie: this.ticket.kategorie });
    },
    receiver: {
      get() {
        if (this.modalReceiverInput) return this.modalReceiverInput;
        switch (this.modalCommentType) {
          case "sms":
            // finde mögliche Handynummer: 015, 016, 017
            if (this.ticket.rufnummer && this.checkHandynummer(this.ticket.rufnummer.replace(/\D/g, ""))) {
              return this.ticket.rufnummer.replace(/\D/g, "");
            }
            if (this.ticket.rufnummer2 && this.checkHandynummer(this.ticket.rufnummer2.replace(/\D/g, ""))) {
              return this.ticket.rufnummer2.replace(/\D/g, "");
            }
            return this.ticket.rufnummer ? this.ticket.rufnummer.replace(/\D/g, "") : "";
          case "mail":
            return this.ticket.email ? this.ticket.email : "";
          default:
            return null;
        }
      },
      set(val) {
        if (this.modalCommentType == "sms") val = val.replace(/\D/g, "");
        this.modalReceiverInput = val;
      },
    },
    modalTitle() {
      switch (this.modalCommentType) {
        case "comment":
          return "Kommentar hinzufügen";
        case "sms":
          return "SMS versenden";
        case "mail":
          return "Mail versenden";
        default:
          return "";
      }
    },
    modalReceiverType() {
      switch (this.modalCommentType) {
        case "sms":
          return "tel";
        case "mail":
          return "email";
        default:
          return null;
      }
    },
    textvorlagen() {
      return this.$store.state.textvorlagen.map((v) => {
        return {
          text: v.titel,
          value: v,
        };
      });
    },
  },
  methods: {
    alleKundenEmpfaenger(type) {
      return this.ticket.aktivitaeten.filter((a) => {
        return a.kategorie && a.kategorie.name.includes(`BENACHRICHTIGEN_${type}`) && a.gesendetAn && a.gesendetAn.length > 1;
      });
    },
    formatDate(date) {
      return DateTime.fromISO(date).setLocale("de").toLocaleString({
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
    resetModal() {
      this.modalReceiverInput = null;
      this.modalTextInput = null;
      this.modalReceiverState = null;
      this.modalTextState = null;
      this.modalError = null;
    },
    insertVorlage(selectChangedEvt) {
      // check vorlagen and replace placeholder
      let mailtext = this.replacePlaceholderTicket(selectChangedEvt.mailtext, this.ticket);
      let smstext = this.replacePlaceholderTicket(selectChangedEvt.smstext, this.ticket);

      if (!this.modalTextInput) {
        this.modalTextInput = this.modalCommentType === "mail" ? mailtext : smstext;
      } else {
        this.modalTextInput += `\r${this.modalCommentType === "mail" ? mailtext : smstext}`;
      }
    },
    insertVorlageAlleMail(selectChangedEvt) {
      this.modalTextInputAlleMail = this.replacePlaceholderTicket(selectChangedEvt.mailtext, this.ticket);
    },
    insertVorlageAlleSMS(selectChangedEvt) {
      this.modalTextInputAlleSMS = this.replacePlaceholderTicket(selectChangedEvt.smstext, this.ticket);
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.modalError = null;
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.modalCommentType === "comment") {
        Ticket.createComment(this.ticket.id, this.modalTextInput, this.modalFile).then((response) => {
          if (response.data && response.data.success) {
            this.ticket.aktivitaeten.push(response.data.comment);
            this.ticket.letzteAktivitaet = new Date().toISOString();
            this.resetModal();
            this.modalCommentType = null;
          } else {
            this.modalError = "Fehler beim Erstellen des Kommentars!";
          }
        });
      } else if (this.modalCommentType === "sms") {
        Ticket.sendSMS(this.ticket.id, this.receiver, this.modalTextInput).then((response) => {
          if (response.data && response.data.success) {
            this.ticket.aktivitaeten.push(response.data.comment);
            this.ticket.letzteAktivitaet = new Date().toISOString();
            this.resetModal();
            this.modalCommentType = null;
          } else {
            this.modalError = "Fehler beim Versenden der SMS!";
          }
        });
      } else if (this.modalCommentType === "mail") {
        Ticket.sendMail(this.ticket.id, this.receiver, this.modalTextInput).then((response) => {
          if (response.data && response.data.success) {
            this.ticket.aktivitaeten.push(response.data.comment);
            this.ticket.letzteAktivitaet = new Date().toISOString();
            this.resetModal();
            this.modalCommentType = null;
          } else {
            this.modalError = "Fehler beim Versenden der E-Mail!";
          }
        });
      } else if (this.modalCommentType === "all") {
        Ticket.sendAlleBenachrichtigen(this.ticket.id, this.modalTextInputAlleMail, this.modalTextInputAlleSMS).then((response) => {
          if (response.data && response.data.success) {
            this.ticket.aktivitaeten.push(response.data.comment);
            this.ticket.letzteAktivitaet = new Date().toISOString();
            this.resetModal();
            this.modalCommentType = null;
          } else {
            this.modalError = "Fehler beim Versenden der Benachrichtigung!";
          }
        });
      }
    },
    checkFormValidity() {
      if (this.modalCommentType === "all") {
        if (this.alleKundenEmpfaenger("MAIL").length > 0 && !this.$refs["text-input-alle-mail"].checkValidity()) {
          return false;
        }
        if (this.alleKundenEmpfaenger("SMS").length > 0 && !this.$refs["text-input-alle-sms"].checkValidity()) {
          return false;
        }
        return true;
      } else {
        this.modalTextState = this.$refs["text-input"].checkValidity();
        if (this.modalReceiverType) {
          this.modalReceiverState = this.$refs["receiver-input"].checkValidity();
          return this.modalReceiverState && this.modalTextState;
        } else {
          return this.modalTextState;
        }
      }
    },
    bearbeiterInformieren(aktivitaetsId) {
      this.$bvModal.msgBoxConfirm("Verantwortlichen Benutzer wirklich benachrichtigen?").then((value) => {
        if (value === true) {
          Ticket.updateComment(this.ticket.id, aktivitaetsId).then((response) => {
            if (response.data && response.data.success) {
              // Update der Aktivität.... Wir wollen das Ticket nicht speichern, bzw. neu laden, nur den Schalter toggeln
              this.aktivitaeten.forEach((a) => {
                if (a.id === aktivitaetsId) {
                  a.bearbeiter_informiert = true;
                }
              });
            } else {
              console.error("response: ", response);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.historyText {
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  white-space: pre-wrap;
}
@media (max-width: 991.98px) {
  .table.activities-table.b-table.b-table-stacked-lg > tbody > tr > [data-label]::before {
    width: 18%;
  }
  .table.activities-table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
    width: calc(100% - 18%);
  }
  .table.activities-table.b-table.b-table-stacked-lg {
    overflow-x: hidden;
  }
}
</style>
