<template>
  <b-modal
    :id="id"
    ref="billing-modal"
    header-bg-variant="primary"
    header-text-variant="light"
    title="Billing pausieren"
    ok-title="Ich bin mir sicher"
    ok-variant="primary"
    cancel-title="Abbrechen"
    cancel-variant="light"
    @show="selectedDate = ticket.billingPausiertBis"
    @ok="handleOk"
  >
    <p>
      Sind Sie sich sicher, dass Sie das Billing pausieren möchten?
      <br />Im Umzugsfalls darf das Billing niemals storniert werden!
    </p>
    <template v-for="h in hinweistexte">
      <b-alert
        :key="h.text"
        :variant="h.variant"
        show
        v-html="h.text"
      ></b-alert>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BAlert } from "bootstrap-vue";
import { DateTime } from "luxon";

export default {
  name: "BillingModal",
  components: {
    BModal,
    BAlert,
  },
  props: {
    id: String,
    ticket: Object,
  },
  data() {
    return {
      datepickerLabels: {
        labelPrevDecade: "Vorheriges Jahrzehnt",
        labelPrevYear: "Vorheriges Jahr",
        labelPrevMonth: "Vorheriger Monat",
        labelCurrentMonth: "Aktueller Monat",
        labelNextMonth: "Nächster Monat",
        labelNextYear: "Nächstes Jahr",
        labelNextDecade: "Nächstes Jahrzehnt",
        labelToday: "Heute",
        labelSelected: "Ausgewähltes Datum",
        labelNoDateSelected: "Kein Datum",
        labelCalendar: "Kalender",
        labelNav: "Kalendernavigation",
        labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
      },
      editedDate: null,
    };
  },
  computed: {
    selectedDate: {
      get() {
        if (this.editedDate) return this.editedDate;
        if (this.ticket) return this.ticket.billingPausiertBis;
        return null;
      },
      set(val) {
        this.editedDate = val;
      },
    },
    hinweistexte: {
      get() {
        let hinweise = [];
        if (!this.ticket) return hinweise;
        if (this.ticket.kategorie === "NELI") {
          hinweise.push({
            text:
              "Sie können die Billing-Storno Einstellung eines NeLi-Tickets vor Abschluss des Tickets nicht mehr zurücknehmen!",
            variant: "warning",
          });
        }
        // wenn Monatswechsel vorliegt
        if (this.isMonatswechsel) {
          hinweise.push({
            text:
              "<b>Achtung: Es liegt ein Monatswechsel zwischen der Anschaltung und jetzt.</b> Sie dürfen Sie das Billing nicht mehr stornieren!",
            variant: "warning",
          });
        } else {
          // wenn kein Monatswechsel vorliegt
          hinweise.push({
            text:
              "</b>Es liegt <u>kein</u> Monatswechsel zwischen Heute und dem Anschalttermin.</b>",
            variant: "info",
          });
        }
        return hinweise;
      },
      isMonatswechsel() {
        if (
          !this.ticket ||
          !this.ticket.product ||
          !this.ticket.product.validFrom
        )
          return false;
        let today = DateTime.local();
        let validFrom = DateTime.fromFormat(
          this.ticket.product.validFrom,
          "yyyy-MM-dd"
        );
        return validFrom.month > today.month && validFrom.year >= today.year;
      },
    },
  },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      this.ticket.billingPausiert = true;
      this.ticket.billingPausiertBis = null;
      this.ticket.billingPausiertGesetzt = true;
      this.$nextTick(() => {
        this.$refs["billing-modal"].hide();
      });
    },
  },
};
</script>