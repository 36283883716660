<template>
  <div>
    <b-table
      responsive
      hover
      :items="kunden"
      :fields="fields"
      :busy.sync="isBusy"
    >
      <!-- Spalten -->
      <template v-slot:table-colgroup="data">
        <col v-for="field in data.fields" :key="field.key" />
      </template>
      <!-- Kunde-Zelle -->
      <template v-slot:cell(number)="data">
        <div
          :class="{ locked: data.item.locked }"
          :title="'organice: ' + data.item.id"
        >
          <div v-if="data.item.locked" class="locked_msg">
            Kunde ist gesperrt
          </div>
          <div>
            <b>{{ data.item.number }}</b>
          </div>
          <div>
            {{ data.item.salutation }} {{ data.item.firstname }}
            {{ data.item.lastname }}
          </div>
          <div v-if="data.item.company">{{ data.item.company }}</div>
        </div>
      </template>
      <!-- Ort-Zelle -->
      <template v-slot:cell(zipcode)="data">
        <div
          :class="{ locked: data.item.locked }"
          :title="getAdditionalContactInfos(data.item)"
        >
          <div>{{ data.item.zipcode }} {{ data.item.city }}</div>
          <div>
            {{ data.item.street }} {{ data.item.streetNumber
            }}{{ data.item.streetNumberAddon }}
          </div>
        </div>
      </template>
      <!-- Vertriebsgebiet-Zelle -->
      <template v-slot:cell(kvzstreet)="data">
        <div
          v-if="data.item.kvzstreet && data.item.kvzstreet.vertriebsgebiet"
          :title="getAdditionalKvzInfos(data.item)"
        >
          {{ data.item.kvzstreet.vertriebsgebiet.titel }}
        </div>
        <div v-else class="locked_msg">Standort unbekannt</div>
      </template>
      <!-- Anschaltung-Zelle -->
      <template v-slot:cell(creationdate)="data">{{
        data.item.creationdate
      }}</template>
      <!-- Kundenbetreuer -->
      <template v-slot:cell(accountManager)="data">{{
        data.item.accountManager
      }}</template>

      <!-- Aktion-Zelle -->
      <template v-slot:cell(actions)="data">
        <b-button
          v-b-modal.modal-detail
          @click="
            selectedKunde = data.item;
            loadProdukte(data.item);
          "
        >
          <b-icon-checklist></b-icon-checklist>
        </b-button>
      </template>
    </b-table>

    <b-modal
      id="modal-detail"
      size="xl"
      title="Tickets"
      ok-only
      ok-title="Schließen"
      header-text-variant="light"
      header-bg-variant="primary"
      @show="resetModal"
      reloadKunden
      :no-close-on-backdrop="true"
    >
      <!-- Zeige Details zum Kunden an -->
      <template v-if="!selectedProdukt">
        <template
          v-if="
            selectedKunde &&
            selectedKunde.masterTickets &&
            selectedKunde.masterTickets.length > 0
          "
        >
          <h5>Mastertickets</h5>
          <tickettabelle
            :tickets="selectedKunde.masterTickets"
            :compact="true"
          ></tickettabelle>
        </template>
        <template
          v-if="
            selectedKunde &&
            selectedKunde.kvzTickets &&
            selectedKunde.kvzTickets.length > 0
          "
        >
          <h5>Tickets im KVZ</h5>
          <tickettabelle
            :tickets="selectedKunde.kvzTickets"
            :compact="true"
          ></tickettabelle>
        </template>
        <template
          v-if="
            selectedKunde &&
            selectedKunde.ownTickets &&
            selectedKunde.ownTickets.length > 0
          "
        >
          <h5>Eigene Tickets</h5>
          <tickettabelle
            :tickets="selectedKunde.ownTickets"
            :compact="true"
          ></tickettabelle>
        </template>
        <h5>Produkte</h5>
        <div v-if="loading">
          <b-spinner
            variant="primary"
            label="Lädt..."
            style="width: 25px; height: 25px"
          ></b-spinner
          >&nbsp; Frage Produkte in Organice ab...
        </div>
        <b-card-group deck>
          <produktcard
            v-for="p in modalprodukte"
            :produkt="p"
            :key="p.id"
            :addTicketBtn="true"
            :showContractNumber="true"
            :masterTickets="selectedKunde.masterTickets"
            @link-customer="openMasterticketReceiver"
            @create-ticket="selectProdukt"
          ></produktcard>
        </b-card-group>
      </template>
    </b-modal>

    <!-- Auswahl hizufügen zu Masterticket -->
    <masterticket-empfaenger-modal
      v-if="selectedKunde"
      :selectedKunde="selectedKunde"
      :ticketTitle="selectedMasterticket ? selectedMasterticket.titel : ''"
      @benachrichtigung="linkCustomer"
    ></masterticket-empfaenger-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCardGroup,
  VBModal,
  BTable,
  BButton,
  BIconCardChecklist,
  BSpinner,
} from "bootstrap-vue";
import Tickettabelle from "@/components/tables/Tickettabelle";
import Produktcard from "@/components/Produktcard";
import Kunde from "@/api/customer";
import Ticket from "@/api/tickets";
import store from "@/store";
import MasterticketEmpfaengerModal from "@/components/modals/MasterticketEmpfaenger";
import { SET_TICKET } from "@/store/mutations.type";

export default {
  components: {
    BTable,
    BButton,
    BModal,
    BCardGroup,
    "b-icon-checklist": BIconCardChecklist,
    BSpinner,
    Tickettabelle,
    Produktcard,
    MasterticketEmpfaengerModal,
  },
  store,
  directives: {
    // Note that Vue automatically prefixes directive names with `v-`
    "b-modal": VBModal,
  },
  props: {
    kunden: { type: Array, required: true },
  },
  data() {
    return {
      modalprodukte: [],
      modalKunde: null,
      loading: false,
      selectedProdukt: null,
      selectedMasterticket: null,
      selectedProduktOnMasterticket: null,
      fields: [
        {
          key: "number",
          label: "Kundennummer / Name",
          sortable: true,
        },
        {
          key: "zipcode",
          label: "Ort / Straße",
          sortable: true,
        },
        {
          key: "kvzstreet",
          label: "Vertriebsgebiet",
          sortable: true,
        },
        {
          key: "creationdate",
          label: "Anschaltung",
          sortable: true,
        },
        {
          key: "accountManager",
          label: "Kundenbetreuer",
          sortable: true,
        },
        {
          key: "actions",
          label: "Aktionen",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    selectedKunde: {
      get() {
        if (!this.modalKunde) return null;
        var tickets = {
          masterTickets: this.$store.getters.masterTickets,
          ownTickets: [],
          kvzTickets: [],
        };
        if (this.modalKunde.number) {
          tickets.ownTickets = this.$store.getters.kundenTickets(
            this.modalKunde.id
          );
        }
        if (this.modalKunde.kvzstreet && this.modalKunde.kvzstreet.kvz) {
          tickets.kvzTickets = this.$store.getters
            .kvzTickets(this.modalKunde.kvzstreet.kvz.id)
            .filter((t) => !tickets.ownTickets.find((ot) => ot.id === t.id));
        }

        return Object.assign(this.modalKunde, tickets);
      },
      set(val) {
        this.modalKunde = val;
      },
    },
    isBusy() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    getAdditionalContactInfos(item) {
      let infos = [];
      if (item.phone) infos.push(`Tel: ${item.phone}`);
      if (item.email) infos.push(`Email: ${item.email}`);
      if (item.streetId) infos.push(`Anschluss-ID: ${item.streetId}`);
      return infos.join("\r\n");
    },
    getAdditionalKvzInfos(item) {
      if (!item.kvzstreet || !item.kvzstreet.kvz) return "";
      let infos = [];
      if (item.kvzstreet.kvz.netkom_name)
        infos.push(item.kvzstreet.kvz.netkom_name);
      if (item.kvzstreet.kvz.telekom_name)
        infos.push(item.kvzstreet.kvz.telekom_name);
      if (infos.length > 0) {
        return `KVZ: ${infos.join(" - ")}`;
      }
      return "";
    },
    loadProdukte(kunde) {
      this.loading = true;
      this.modalprodukte = [];
      if (kunde.id) {
        Kunde.getProducts(kunde.id).then((response) => {
          this.loading = false;
          if (response.data != null) this.modalprodukte = response.data;
        });
      }
    },
    selectProdukt(produkt) {
      this.selectedProdukt = produkt;
      this.createTicket();
    },
    openMasterticketReceiver(data) {
      this.selectedMasterticket = data.masterticket;
      this.selectedProduktOnMasterticket = data.produkt;
      this.$bvModal.show("masterticket-receiver-modal");
    },
    linkCustomer(data) {
      Ticket.linkCustomer(
        this.selectedMasterticket.id,
        data.receiver,
        data.type === "email" ? true : false,
        this.selectedProduktOnMasterticket.contractNumber,
        this.selectedProduktOnMasterticket.contractId,
        this.selectedKunde.number,
        this.selectedKunde.lastname,
        this.selectedKunde.firstname
      ).then(() => {
        let p = this.modalprodukte.find(
          (p) => p.id === this.selectedProduktOnMasterticket.id
        );
        p.linked.push(this.selectedMasterticket.id);
      });
    },
    resetModal() {
      this.selectedProdukt = null;
    },
    async createTicket() {
      let ticket = {
        product: this.selectedProdukt,
        ticketTyp: "VORQUALIFIZIERUNG",
      };
      if (this.selectedKunde) {
        ticket.kundennummer = this.selectedKunde.number;
        ticket.organiceKundenId = this.selectedKunde.id;
        ticket.kundenname = `${
          this.selectedKunde.firstname ? this.selectedKunde.firstname : ""
        } ${
          this.selectedKunde.lastname ? this.selectedKunde.lastname : ""
        }`.trim();
        ticket.rufnummer = this.selectedKunde.phone;
        ticket.rufnummer2 = this.selectedKunde.phone2;
        ticket.email = this.selectedKunde.email;
        if (this.selectedKunde.kvzstreet) {
          ticket.strasse = { id: this.selectedKunde.kvzstreet.id };
          if (this.selectedKunde.kvzstreet.kvz) {
            ticket.kvz = { id: this.selectedKunde.kvzstreet.kvz.id };
          }
        }
      }
      let response = await Ticket.create(ticket);
      // Ticket hinzufügen
      this.$store.commit(SET_TICKET, response.data.ticket);
      // zum Ticket navigieren

      this.routerChangeToTicketId = response.data.ticket.id;

      this.$router.push({
        name: "ticketdetail",
        params: { id: this.routerChangeToTicketId },
      });
    },
  },
};
</script>

<style lang="scss">
.btn-white {
  background-color: #ffffff;
  border-color: #d3d3d3;
}
.locked {
  color: darkgray;
}
.locked_msg {
  color: crimson;
  font-weight: 700;
}
.modal-dialog {
  .card-deck {
    @media (min-width: 576px) {
      .card {
        margin-bottom: 30px;
        min-width: calc(50% - 30px);
        max-width: calc(100% - 30px);
      }
    }
    @media (min-width: 992px) {
      .card {
        margin-bottom: 30px;
        min-width: calc(33% - 30px);
        max-width: calc(100% - 30px);
      }
    }
    @media (min-width: 1200px) {
      .card {
        margin-bottom: 30px;
        min-width: calc(25% - 30px);
        max-width: calc(100% - 30px);
      }
    }
  }
}
</style>
