import { Api } from "."
import axios from "axios";

export default {
  getSelf: () => {
    return Api.get("user/")
  },
  getByRole: (id) => {
    return Api.get("user/", { params: { role: id } })
  },
  createAbsence: (start, ende) => {
    return Api.post("user/absence/", { start: start, ende: ende })
  },
  updateAbsence: (id, start, ende) => {
    return Api.put("user/absence/", { id: id, start: start, ende: ende })
  },
  deleteAbsence: (id) => {
    return Api.delete("user/absence/", { params: { id: id } })
  },
  getList: () => {
    return Api.get("users/")
  },
  update: (user) => {
    if (user.id === 'neu')
      return Api.post("user/", user)
    return Api.put("user/", user)
  },
  delete: (id) => {
    return Api.delete("user/", { params: { id: id } })
  },
  login: (username, password) => {
    var bodyFormData = new FormData();
    bodyFormData.append('user', username);
    bodyFormData.append('password', password);
    const LoginApi = axios.create({
      baseURL: process.env.VUE_APP_API_URL
    });
    return LoginApi.request({
      method: "POST",
      url: "login/",
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  logout: () => {
    return Api.post("logout/")
  }
}
