<template>
  <div class="container" v-if="user">
    <b-card bg-variant="light" class="mt-4">
      <b-form-group
        label-cols-lg="3"
        label="Profileinstellungen"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >
        <b-form-group
          label-cols-sm="3"
          label="Vorname:"
          label-align-sm="right"
          label-for="vorname"
        >
          <b-form-input id="vorname" v-model="user.vorname"></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols-sm="3"
          label="Nachname:"
          label-align-sm="right"
          label-for="nachname"
        >
          <b-form-input id="nachname" v-model="user.name"></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols-sm="3"
          label="E-Mail:"
          label-align-sm="right"
          label-for="email"
        >
          <b-form-input id="email" v-model="user.email"></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols-sm="3"
          label="E-Mail 2:"
          label-align-sm="right"
          label-for="email2"
        >
          <b-form-input id="email2" v-model="user.email2"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-sm="3"
          label="Mail bei zugewiesenem Ticket:"
          label-align-sm="right"
          label-for="zuweisungsmail"
        >
          <b-form-checkbox id="zuweisungsmail" v-model="user.assignedMail"
            >Mail senden</b-form-checkbox
          >
        </b-form-group>
      </b-form-group>

      <template slot="footer">
        <div class="mr-auto">
          <div class="text-right">
            <b-button
              :variant="profileSavedInfo ? 'success' : 'primary'"
              @click="saveStammdaten"
            >
              <template v-if="profileSavedInfo">
                <b-icon-check aria-hidden="true"></b-icon-check>Gespeichert
              </template>
              <template v-else>Speichern</template>
            </b-button>
          </div>
        </div>
      </template>
    </b-card>

    <b-card bg-variant="light" class="mt-4">
      <b-form-group
        label-cols-lg="3"
        label="Passwort"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >
        <!-- -->
        <p>
          Die Wahl eines sicheren Passworts ist entscheidend f&uuml;r die
          Sicherheit Ihrer Daten. Um Sie dabei zu unterst&uuml;tzen, akzeptieren
          wir grunds&auml;tzlich nur Passw&ouml;rter, die folgende Kriterien
          erf&uuml;llen:
        </p>
        <ul>
          <li>es muss mindestens 12 Zeichen lang sein</li>
          <li>
            es darf nicht einem der am h&auml;ufigsten verwendeten
            Passw&ouml;rtern entsprechen
          </li>
          <li>es darf nicht Ihren Benutzernamen enthalten</li>
          <li>es darf nicht "netkom" enthalten</li>
          <li>es darf nicht kürzlich verwendet worden sein</li>
        </ul>
        <p>Grunds&auml;tzlich empfiehlt sich f&uuml;r ein sicheres Passwort:</p>
        <ul>
          <li>so viele Zeichen wie m&ouml;glich zu verwenden</li>
          <li>
            auch viele Zeichenarten zu verwenden (Gro&szlig;- und
            Kleinbuchstaben, Ziffern, Sonderzeichen)
          </li>
          <li>
            das vollst&auml;ndige Passwort sollte nicht im W&ouml;rterbuch
            vorkommen
          </li>
          <li>
            und es sollte sich nicht um typische Reihenfolgen handeln (wie
            123456, abcdefgh, ...)
          </li>
        </ul>
        <!-- -->

        <b-form-group
          label-cols-sm="3"
          label="Passwort:"
          label-align-sm="right"
          label-for="password"
        >
          <b-form-input
            id="password"
            type="password"
            v-model="password"
            autocomplete="new-password"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols-sm="3"
          label="Passwort wiederholen:"
          label-align-sm="right"
          label-for="passwordrepeat"
        >
          <b-form-input
            id="passwordrepeat"
            type="password"
            v-model="passwordRepeat"
            autocomplete="new-password"
          ></b-form-input>
        </b-form-group>
        <b-alert v-model="passwordSavedError" variant="danger">
          Passwort nicht gespeichert! Wurden alle Passwortrichtlinien
          eingehalten?
        </b-alert>
      </b-form-group>

      <template slot="footer">
        <div class="text-right">
          <b-button
            :variant="
              passwordSavedInfo && !passwordSavedError
                ? 'success'
                : passwordSavedInfo && passwordSavedError
                ? 'danger'
                : 'primary'
            "
            @click="savePassword"
          >
            <template v-if="passwordSavedInfo && !passwordSavedError">
              <b-icon-check aria-hidden="true" />Gespeichert
            </template>
            <template v-else-if="passwordSavedInfo && passwordSavedError">
              <b-icon-x aria-hidden="true" />Fehler
            </template>
            <template v-else>Passwort ändern</template>
          </b-button>
        </div>
      </template>
    </b-card>

    <b-card bg-variant="light" class="mt-4">
      <b-form-group
        label-cols-lg="3"
        label="Abwesenheiten"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
      >
        <b-list-group>
          <b-list-group-item
            v-for="a in user.abwesenheiten"
            :key="a.start + a.ende"
            class="flex-column align-items-start"
          >
            <div class="d-flex w-100">
              <div v-if="editedId != a.id" class="mr-auto pt-1">
                {{ formatDate(a.start) }} - {{ formatDate(a.ende) }}
              </div>
              <div v-if="editedId == a.id" class="mr-auto form-inline">
                <b-form-datepicker
                  v-bind="datepickerLabels"
                  locale="de"
                  size="sm"
                  value-as-date
                  v-model="startDate"
                ></b-form-datepicker>
                <b-form-datepicker
                  v-bind="datepickerLabels"
                  locale="de"
                  size="sm"
                  class="ml-2"
                  value-as-date
                  v-model="endDate"
                ></b-form-datepicker>
              </div>
              <b-button-group size="sm" v-if="editedId == a.id">
                <b-button variant="success" @click="saveAbsence(a)">
                  <b-icon-check></b-icon-check>
                </b-button>
                <b-button variant="danger" @click="cancelEditAbsence()">
                  <b-icon-x></b-icon-x>
                </b-button>
              </b-button-group>
              <b-button-group size="sm" v-if="!editedId">
                <b-button variant="light" @click="editAbsence(a)">
                  <b-icon-pencil></b-icon-pencil>
                </b-button>
                <b-button variant="danger" @click="deleteAbsence(a)">
                  <b-icon-trash></b-icon-trash>
                </b-button>
              </b-button-group>
            </div>
          </b-list-group-item>
          <!-- Neue Abwesenheit -->
          <b-list-group-item
            class="flex-column align-items-start"
            v-if="!editedId"
          >
            <div class="mr-auto">
              <div class="text-right">
                <b-button
                  variant="success"
                  size="sm"
                  title="Abwesenheit hinzufügen"
                  @click="createAbsence()"
                >
                  <b-icon-plus></b-icon-plus>
                </b-button>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import User from "@/api/user";
import { DateTime } from "luxon";
import {
  BCard,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BAlert,
  BButton,
  BButtonGroup,
  BListGroup,
  BListGroupItem,
  BIconPencil,
  BIconTrash,
  BIconX,
  BIconCheck,
  BIconPlus,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    BAlert,
    BListGroup,
    BListGroupItem,
    BIconPencil,
    BIconTrash,
    BIconX,
    BIconCheck,
    BIconPlus,
    BFormDatepicker,
  },
  name: "Profil",
  data: function () {
    return {
      user: null,
      startDate: null,
      endDate: null,
      password: null,
      passwordRepeat: null,
      editedId: null,
      profileSavedInfo: false,
      passwordSavedInfo: false,
      passwordSavedError: false,
      datepickerLabels: {
        labelPrevDecade: "Vorheriges Jahrzehnt",
        labelPrevYear: "Vorheriges Jahr",
        labelPrevMonth: "Vorheriger Monat",
        labelCurrentMonth: "Aktueller Monat",
        labelNextMonth: "Nächster Monat",
        labelNextYear: "Nächstes Jahr",
        labelNextDecade: "Nächstes Jahrzehnt",
        labelToday: "Heute",
        labelSelected: "Ausgewähltes Datum",
        labelNoDateSelected: "Kein Datum gewählt",
        labelCalendar: "Kalender",
        labelNav: "Kalendernavigation",
        labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren",
      },
    };
  },
  mounted() {
    User.getSelf().then((response) => {
      this.user = response.data;
    });
  },
  computed: {},
  methods: {
    createAbsence() {
      let a = {
        id: "new",
        start: DateTime.local().toISO(),
        ende: DateTime.local().toISO(),
      };
      this.user.abwesenheiten.push(a);
      this.editAbsence(a);
    },
    editAbsence(a) {
      this.editedId = a.id;
      this.startDate = DateTime.fromISO(a.start).toJSDate();
      this.endDate = DateTime.fromISO(a.ende).toJSDate();
    },
    deleteAbsence(a) {
      if (!confirm("Abwesenheit wirklich entfernen?")) return;
      User.deleteAbsence(a.id).then((response) => {
        if (response.data.success) {
          this.user.abwesenheiten = response.data.abwesenheiten;
        }
      });
    },
    cancelEditAbsence() {
      if (this.editedId == "new") {
        // entferne temporäre Abwesenheit wieder
        this.user.abwesenheiten.splice(0, 1);
      }
      this.editedId = null;
      this.startDate = null;
      this.endDate = null;
    },
    saveAbsence(a) {
      if (a.id === "new") {
        User.createAbsence(
          DateTime.fromJSDate(this.startDate).toISO(),
          DateTime.fromJSDate(this.endDate).toISO()
        ).then((response) => {
          if (response.data.abwesenheiten) {
            this.user.abwesenheiten = response.data.abwesenheiten;
            this.editedId = null;
            this.startDate = null;
            this.endDate = null;
          }
        });
      } else {
        User.updateAbsence(
          a.id,
          DateTime.fromJSDate(this.startDate).toISO(),
          DateTime.fromJSDate(this.endDate).toISO()
        ).then((response) => {
          if (response.data.abwesenheiten) {
            this.user.abwesenheiten = response.data.abwesenheiten;
            this.editedId = null;
            this.startDate = null;
            this.endDate = null;
          }
        });
      }
    },
    saveStammdaten() {
      User.update(this.user).then(() => {
        this.profileSavedInfo = true;
        window.setTimeout(() => {
          this.profileSavedInfo = false;
        }, 5000);
      });
    },
    savePassword() {
      if (
        this.password &&
        this.passwordRepeat &&
        this.password === this.passwordRepeat
      ) {
        this.passwordSavedError = false;
        User.update({ id: this.user.id, password: this.password }).then(
          (response) => {
            console.log(response);
            this.passwordSavedInfo = true;
            this.passwordSavedError = !response.data.success;
            window.setTimeout(() => {
              this.passwordSavedInfo = false;
            }, 5000);
          }
        );
      }
    },
    formatDate(date) {
      return DateTime.fromISO(date).setLocale("de").toLocaleString({
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
};
</script>