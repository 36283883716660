<template>
  <div id="app">
    <router-view></router-view>

    <b-modal
      :visible="this.user && this.user.passwortwechselErzwingen"
      title="Passwortänderung nötig"
      ok-title="Passwort ändern"
      ok-only
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @ok="handlePasswordOk"
    >
      <p>
        Die Wahl eines sicheren Passworts ist entscheidend f&uuml;r die
        Sicherheit Ihrer Daten. Um Sie dabei zu unterst&uuml;tzen, akzeptieren
        wir grunds&auml;tzlich nur Passw&ouml;rter, die folgende Kriterien
        erf&uuml;llen:
      </p>
      <ul>
        <li>es muss mindestens 12 Zeichen lang sein</li>
        <li>
          es darf nicht einem der am h&auml;ufigsten verwendeten
          Passw&ouml;rtern entsprechen
        </li>
        <li>es darf nicht Ihren Benutzernamen enthalten</li>
        <li>es darf nicht "Biber" enthalten</li>
        <li>es darf nicht kürzlich verwendet worden sein</li>
      </ul>
      <p>Grunds&auml;tzlich empfiehlt sich f&uuml;r ein sicheres Passwort:</p>
      <ul>
        <li>so viele Zeichen wie m&ouml;glich zu verwenden</li>
        <li>
          auch viele Zeichenarten zu verwenden (Gro&szlig;- und Kleinbuchstaben,
          Ziffern, Sonderzeichen)
        </li>
        <li>
          das vollst&auml;ndige Passwort sollte nicht im W&ouml;rterbuch
          vorkommen
        </li>
        <li>
          und es sollte sich nicht um typische Reihenfolgen handeln (wie 123456,
          abcdefgh, ...)
        </li>
      </ul>
      <b-form @submit.stop.prevent="savePassword">
        <b-form-group label-for="input-1">
          <b-form-input
            v-model="passwordForm.password"
            type="password"
            placeholder="Passwort"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-2" label-for="input-2" class="mb-1">
          <b-form-input
            v-model="passwordForm.passwordRepeat"
            type="password"
            placeholder="Passwort wiederholen"
            required
          ></b-form-input>
        </b-form-group>
        <b-alert v-model="passwordSavedError" variant="danger">
          Passwort nicht gespeichert! Wurden alle Passwortrichtlinien
          eingehalten?
        </b-alert>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import User from "@/api/user";
import { BForm, BFormGroup, BFormInput, BModal, BAlert } from "bootstrap-vue";
import { SET_USER } from "././store/mutations.type";

export default {
  name: "App",
  components: { BForm, BFormGroup, BFormInput, BModal, BAlert },
  data() {
    return {
      AppEnv: process.env.NODE_ENV,
      form: {
        user: "",
        password: "",
      },
      invalidLogin: false,
      passwordForm: {
        password: "",
        passwordRepeat: "",
      },
      passwordSavedError: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    passwortwechselNoetig() {
      return this.user && this.user.passwortwechselErzwingen;
    },
    isShowLogin() {
      return this.$store.getters.isLoginShown;
    },
  },
  methods: {
    handlePasswordOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.savePassword();
    },
    savePassword() {
      if (
        this.passwordForm.password &&
        this.passwordForm.passwordRepeat &&
        this.passwordForm.password === this.passwordForm.passwordRepeat
      ) {
        this.passwordSavedError = false;
        User.update({
          id: this.user.id,
          password: this.passwordForm.password,
        }).then((response) => {
          console.log(response);
          if (response.data.success) {
            this.$store.commit(SET_USER, response.data.user);
          } else {
            this.passwordSavedError = true;
          }
        });
      } else {
        this.passwordSavedError = true;
      }
    },
  },
  mounted() {
    console.log("Environment: " + process.env.NODE_ENV);
  },
};
</script>

<style lang="scss">
@import "./scss/app.scss";
@import "./scss/eins-changes.scss";

#app {
  /*-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
  margin-top: 10px;
}
</style>
