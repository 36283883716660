<template>
  <b-row>
    <netkom-card title="Auftragsdaten" :cols="{ cols: 12, xl: 6 }">
      <auftragsdaten-btc v-if="btcAuftrag" :btc-auftrag="btcAuftrag"></auftragsdaten-btc>
    </netkom-card>
    <netkom-card title="Stammdaten" :cols="{ cols: 12, xl: 6 }">
      <stammdaten-btc :ticket="ticket"></stammdaten-btc>
    </netkom-card>
     <netkom-card v-if="ticket.status !== 'ARCHIV' " title="Workflow" :cols="{ cols: 12 }">
      <workflow-btc v-if="btcAuftrag" :btc-auftrag="btcAuftrag" :ticket="ticket" @reload="loadBtcAuftrag()"></workflow-btc>
    </netkom-card>

    <!-- Produktinfos -->
    <!-- <netkom-card title="Produktinfos" :cols="{ cols: 12, xl: 6 }" v-if="ticket.kategorie != 'MASTER'"> -->
    <!-- <produktinformationen :ticket="ticket"></produktinformationen> -->
    <!-- </netkom-card> -->
    <!-- <netkom-card title="Masterticket Informationen" :cols="{ cols: 12, xl: 6 }" v-if="ticket.kategorie == 'MASTER'"> -->
    <!-- <masterticket-informationen :ticket="ticket" :kvzs="kvzs"></masterticket-informationen> -->
    <!-- </netkom-card> -->
  </b-row>
</template>

<script>
import NetkomCard from "@/components/NetkomCard";
import AuftragsdatenBtc from "@/components/ticketdetail/AuftragsdatenBtc";
import StammdatenBtc from "@/components/ticketdetail/StammdatenBtc";
import WorkflowBtc from "@/components/ticketdetail/WorkflowBtc";
import { BRow, } from "bootstrap-vue";
import apiPlugin from "@/api/plugin";

export default {
  name: "TicketdetailBtc",
  components: {
    BRow,
    NetkomCard,
    AuftragsdatenBtc,
    WorkflowBtc,
    StammdatenBtc,
  },
  data() {
    return {
      sidebarVisible: false,
      btcAuftrag: null,
    };
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },
  methods: {
    sidebarToggle() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    async loadBtcAuftrag() {
      let response = await apiPlugin.btc.getBtcById(this.ticket.id);
      if (response.status === 200) {
        this.btcAuftrag = response.data;
      } else {
        console.error("error loading btc ticket von plugin api", response);
      }
    },
  },
  mounted() {
    this.loadBtcAuftrag();
  },
};
</script>

<style></style>
