import { Api } from "."

export default {
  get: () => {
    return Api.get("kvzs/")
  },
  getAusfaelle: (page, search) => {
    return Api.get(`kvzs/ausfaelle/?page=${page}&search=${search}`)
  },
  updateBemerkung: (id,bemerkung) => {
    return Api.put(`kvzs/ausfaelle/`, {"id":id, "bemerkung":bemerkung})
  }
}
