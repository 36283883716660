<template>
  <b-col v-if="btcAuftrag" cols="12">
    <b-row>
      <b-col sm="12" md="6" class="mb-2">
        <b-form-group label-for="externeAuftragsnummer" label="Externe Auftragsnummer">
          <b-form-input id="externeAuftragsnummer" :value="btcAuftrag.auftrag.externeAuftragsnummer" readonly></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" class="mb-2">
        <b-form-group label-for="geschaeftsfallart" label="Geschäftsfallart">
          <b-form-input id="geschaeftsfallart" :value="btcAuftrag.auftrag.geschaeftsfallart" readonly></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" class="mb-2">
        <b-form-group label-for="lineId" label="Line-ID">
          <b-form-input id="lineId" :value="btcAuftrag.auftrag.geschaeftsfall.lineID" readonly></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" class="mb-2">
        <b-form-group label-for="aenderungsKennzeichen" label="Änderungskennzeichen">
          <b-form-input id="aenderungsKennzeichen" :value="btcAuftrag.auftrag.aenderungsKennzeichen" readonly></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" class="mb-2">
        <b-form-group label-for="ansprechpartnerKundeTelefon" label="Telefon Ansprechpartner Kunde">
          <b-form-input id="ansprechpartnerKundeTelefon" :value="btcAuftrag.auftrag.geschaeftsfall.ansprechpartnerKundeTelefon" readonly></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="3" class="mb-2">
        <b-form-group label-for="standortA_plz" label="PLZ">
          <b-form-input id="standortA_plz" :value="btcAuftrag.auftrag.geschaeftsfall.standortA.plz" readonly></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="9" class="mb-2">
        <b-form-group label-for="standortA_ort" label="Ort">
          <b-form-input id="standortA_ort" :value="btcAuftrag.auftrag.geschaeftsfall.standortA.ort" readonly></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="9" class="mb-2">
        <b-form-group label-for="standortA_str" label="Strasse">
          <b-form-input id="standortA_str" :value="btcAuftrag.auftrag.geschaeftsfall.standortA.str" readonly></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3" class="mb-2">
        <b-form-group label-for="standortA_hnr" label="Hausnummer">
          <b-form-input id="standortA_hnr" :value="btcAuftrag.auftrag.geschaeftsfall.standortA.hnr" readonly></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="6" class="mb-2">
        <b-form-group label-for="kundenwunschtermin" label="Kundenwunschtermin">
          <b-form-input id="kundenwunschtermin" :value="formatTS(btcAuftrag.auftrag.geschaeftsfall.termine.kundenwunschtermin.datum)" readonly></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" class="mb-2">
        <b-form-group label-for="kundenwunschterminZeitfenster" label="Zeitfenster">
          <b-form-input id="kundenwunschterminZeitfenster" :value="btcAuftrag.auftrag.geschaeftsfall.termine.kundenwunschtermin.zeitfenster" readonly></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mb-2">
        <b-form-group label-for="auftragsposition" label="Auftragsposition">
          <b-card class="mb-2">
            <b-card-text>
              <json-view :json="auftragspositionJson"></json-view>
            </b-card-text>
          </b-card>
        </b-form-group>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BCard, BCardText } from "bootstrap-vue";

import { DateTime } from "luxon";

export default {
  name: "AuftragsdatenBtc",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardText,
  },
  props: {
    btcAuftrag: {
      type: Object,
      required: true,
    },
  },
  computed: {
    auftragspositionJson() {
      return JSON.parse(this.btcAuftrag.auftrag.geschaeftsfall.auftragsposition);
    },
  },
  methods: {
    formatTS(ts) {
      if (ts) {
        return DateTime.fromSeconds(ts).toFormat("dd.MM.yyyy");
      } else {
        return "";
      }
    },
  },
};
</script>
