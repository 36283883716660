<template>
  <div class="loader" v-if="loading">
    <b-spinner variant="primary" label="Lädt..." style="width: 75px; height: 75px;"></b-spinner>
    <div class="mt-3">Lädt...</div>
  </div>
</template>

<script>
import store from "@/store";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    "b-spinner": BSpinner
  },
  store,
  computed: {
    loading: {
      get() {
        return this.$store.getters.isLoading;
      }
    }
  }
};
</script>

<style lang="scss">
.btn-white {
  background-color: #ffffff;
  border-color: #d3d3d3;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  text-align: center;
}
</style>