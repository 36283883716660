<template>
  <div class="chart-container">
    <doughnut-chart v-if="loaded" :chartdata="chartData" :options="chartOptions"></doughnut-chart>
    <b-row v-else align-v="center" align-h="center" class="p-3">
      <b-col cols="auto">
        <b-spinner variant="primary" label="Lädt..." style="width: 10vw; height: 10vw;"></b-spinner>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Api } from "@/api";

import DoughnutChart from "./DoughnutChart";
import ticketApi from "@/api/tickets";
import store from "@/store";
import { Ticket } from "@/classes/entities";
import { BCol, BRow, BSpinner } from "bootstrap-vue";
import variables from '@/scss/_export.module.scss';

export default {
  name: "TicketUnzugewiesenChart",
  store,
  components: {
    DoughnutChart,
    BCol,
    BRow,
    BSpinner,
  },
  props: {
    type: {
      type: String,
      default: "offen",
    },
  },
  data() {
    return {
      statisticData: {},
      loaded: false,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
          labels: {
            generateLabels: a => {
              return a.data.labels.map((d, i) => {
                return {
                  text: `${d} (${a.data.datasets[0].data[i]})`,
                  fillStyle: a.data.datasets[0].backgroundColor[i],
                };
              });
            },
          },
        },
        onClick: this.onPointClicked,
      },
    };
  },
  computed: {
    chartData() {
      // Error on DEV! Unbekannte Gruppen verfügbar
      let namedLabels = Object.keys(this.statisticData).map(l => {
        if (l === "NULL") {
          return "Keine Gruppe";
        } else {
          let rolle = store.state.rollen.find(r => r.id === l);
          if (rolle) {
            return rolle.titel;
          } else {
            return `Unbekannt (${l})`;
          }
        }
      });
      return {
        labels: namedLabels,
        datasets: [
          {
            label: "Tickets",
            backgroundColor: namedLabels.map(l => this.createHexColor(l)),
            data: Object.values(this.statisticData),
            lineTension: 0,
            borderWidth: 0,
            pointBackgroundColor: variables.primary,
            pointRadius: 1,
          },
        ],
      };
    },
  },
  methods: {
    onPointClicked(event, point) {
      let group = Object.keys(this.statisticData)[point[0]._index];
      ticketApi.getUnassignedStatisticList(group).then(response => {
        this.$emit("point-clicked", [
          `Nicht zugewiesene Tickets`,
          response.data.tickets.map(e => new Ticket(e)),
        ]);
      });
    },
    loadData() {
      let chart = this;
      ticketApi.getUnassignedStatistic().then(response => {
        chart.statisticData = response.data;
        chart.loaded = true;
      });
    },
    createHexColor(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var c = (hash & 0x00ffffff).toString(16).toUpperCase();
      return "#00000".substring(0, 7 - c.length) + c;
    },
    excel() {
      Api.get("excelreport/", { params: { type: "unassigned" } }).then(response => {
        if (response.data.status === "ok") {
          let filename = response.data.document;
          if (process.env.NODE_ENV === "development")
            filename = "https://tes-dev-tickets.batix.net" + filename;

          window.open(filename);
        }
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
