<template>
  <div>
    <main-navigation></main-navigation>
    <div class="container-fluid scope-style">
      <b-row class="mt-5">
        <b-col>
          <h1>Geplante Arbeiten</h1>
        </b-col>
      </b-row>

      <!-- search field + create button -->
      <template v-if="$can('read', 'GeplanteArbeiten')">
        <b-row align-v="end">
          <b-col cols="auto" class="mr-auto">
            <b-form-input v-model="search" placeholder="Suche"></b-form-input>
          </b-col>
          <b-col cols="auto">
            <b-button
              variant="primary"
              :to="{ name: 'geplante_arbeiten_detail', params: { id: 'neu' } }"
              :disabled="!$can('create', 'GeplanteArbeiten')"
            >
              <span>Erstellen</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- pagination -->
        <b-row align-h="center" v-if="cnt > 100">
          <b-col cols="auto">
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="numberOfPages"
              use-router
            ></b-pagination-nav>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <geplante-arbeiten-tabelle
              :geplanteArbeiten="geplanteArbeiten"
              :filter="search"
              @sort-changed="sortChanged"
              class="mt-3"
            ></geplante-arbeiten-tabelle>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        Keine Berechtigung.
      </template>
    </div>
  </div>
</template>

<script>
import MainNavigation from "@/components/MainNavigation";
import GeplanteArbeitenTabelle from "@/components/tables/GeplanteArbeitenTabelle";
import GeplanteArbeitenApi from "@/api/geplanteArbeiten";
import { DateTime } from "luxon";

import { BRow, BCol, BButton, BPaginationNav, BFormInput } from "bootstrap-vue";

export default {
  name: "GeplanteArbeiten",
  components: {
    MainNavigation,
    GeplanteArbeitenTabelle,
    BRow,
    BCol,
    BButton,
    BPaginationNav,
    BFormInput
  },
  data() {
    return {
      geplanteArbeiten: [],
      search: "",
      cnt: 0,
      sortBy: "t",
      sortDescending: true
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.loadGeplanteArbeiten(to.query.page);
    next();
  },
  computed: {
    numberOfPages() {
      return Math.max(1, Math.ceil(this.cnt / 100));
    }
  },
  methods: {
    sortChanged(e) {
      switch (e.sortBy) {
        case "datum_vonBis": {
          this.sortBy = "t";
          break;
        }
        case "ort": {
          this.sortBy = "o";
          break;
        }
        case "cntKunden": {
          this.sortBy = "c";
          break;
        }
        case "dslam": {
          this.sortBy = "d";
          break;
        }
        case "kvz": {
          this.sortBy = "k";
          break;
        }
        default: {
          this.sortBy = "t";
          break;
        }
      }
      this.sortDescending = e.sortDesc;
      // console.log(this.sortBy + " " + this.sortDescending);
    },
    linkGen(pageNum) {
      return pageNum === 1
        ? "?"
        : `?sort=${this.sortBy}&desc=${this.sortDescending}&page=${pageNum}`;
    },
    loadGeplanteArbeiten(page) {
      page = page || "1";
      page = parseInt(page) - 1;

      // On load: sort by date, descending: true
      GeplanteArbeitenApi.get(this.sortBy, page, this.sortDescending).then(
        response => {
          this.geplanteArbeiten = response.data.arbeiten;

          // Eigenschaft für Markierung setzen. Bedingung: "Datum heute" --> table row --> red
          this.geplanteArbeiten.forEach(arb => {
              let termin = DateTime.fromISO(arb.datum);
              let heute = DateTime.local();
              if(termin.startOf("day").ts === heute.startOf("day").ts){
                arb["_rowVariant"] = "danger";
              }
          });
          this.cnt = response.data.count;
        }
      );
    }
  },
  mounted() {
    this.loadGeplanteArbeiten();
  }
};
</script>

<style scoped>
table.listWork {
  width: 100%;
}
table.listWork th {
  background: var(--primary);
  color: #fff;
}
table.listWork td {
  line-height: 1.2;
  padding-top: 8px;
  padding-bottom: 8px;
}
table.listWork tr:nth-child(even) td {
  background: #fff;
}
table.listWork tr:nth-child(odd) td {
  background: #f1cfea;
}
</style>
