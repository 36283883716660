import { Api } from "."

export default {
  get: (id) => {
    if (id)
      return Api.get("vorlagen/", { params: { id: id } })
    else
      return Api.get("vorlagen/")
  },
  update: (vorlage) => {
    if (vorlage.id === 'neu')
      return Api.post("vorlagen/", vorlage)
    return Api.put("vorlagen/", vorlage)
  },
  delete: (id) => {
    return Api.delete("vorlagen/", { params: { id: id } } )
  }
}
