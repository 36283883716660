<template>
  <b-col cols="12">
    <b-row v-if="ticket.organiceAgbleichFehlgeschlagenAm">
      <b-col cols="12" class="mb-2">
        <b-icon-exclamation-triangle-fill variant="ungelesen" />
        Organice Abgleich zuletzt fehlgeschlagen {{ formatDate(ticket.organiceAgbleichFehlgeschlagenAm) }}, wird erneut versucht.
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" :md="ticket.kategorie != 'MASTER' ? 6 : 12">
        <b-form-group label-for="titel" label="Titel" :state="ticket.titel && ticket.titel.length > 3 ? null : false">
          <b-form-input id="titel" v-model="ticket.titel" :readonly="!titelEnabled || readonly" :state="ticket.titel && ticket.titel.length > 3 ? null : false"></b-form-input>
        </b-form-group>

        <b-form-group label-for="beschreibung" label="Beschreibung" :state="ticket.beschreibung && ticket.beschreibung.length > 3 ? null : false">
          <b-form-textarea
            id="beschreibung"
            rows="5"
            cols="20"
            v-model="ticket.beschreibung"
            :readonly="!beschreibungEnabled || readonly"
            :state="ticket.beschreibung && ticket.beschreibung.length > 3 ? null : false"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" v-if="ticket.kategorie != 'MASTER'">
        <b-row>
          <b-form-group label-for="customerId" label="KundenOrganiceId" class="col-lg-6 col-12" v-if="ticket.product">
            <b-form-input id="customerId" v-model="ticket.product.customerId" readonly></b-form-input>
          </b-form-group>

          <b-form-group class="col-lg-6 col-12" label-for="organiceContractId" label="ContractId">
            <b-form-input id="organiceContractId" v-model="ticket.organiceContractId" readonly></b-form-input>
          </b-form-group>

          <b-form-group class="col-lg-6 col-12" v-if="ticket.vertragsnummer" label-for="contractNumber" label="Vertragsnummer">
            <b-form-input id="contractNumber" v-model="ticket.vertragsnummer" readonly></b-form-input>
          </b-form-group>
          <b-form-group class="col-lg-6 col-12" label-for="kundennummer" label="Kundennummer">
            <b-form-input id="kundennummer" v-model="ticket.kundennummer" readonly></b-form-input>
          </b-form-group>
          <b-form-group class="col-12" label-for="wichtigeInfo" label="Wichtige Info">
            <b-form-textarea class="text-danger" id="wichtigeInfo" rows="8" v-model="ticket.wichtigeInfo"></b-form-textarea>
          </b-form-group>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="ticket.kategorie != 'MASTER'">
      <b-form-group class="col-sm-12 col-md-6" label-for="kundenname" label="Name des Kunden">
        <b-form-input id="kundenname" v-model="ticket.kundenname" readonly></b-form-input>
      </b-form-group>

      <b-form-group class="col-sm-12 col-md-6" label-for="email" label="E-Mail">
        <b-form-input id="email" v-model="ticket.email" :readonly="readonly" autocomplete="new-password"></b-form-input>
      </b-form-group>

      <b-form-group class="col-sm-12 col-md-6" label-for="rufnummer" label="Telefon">
        <b-form-input id="rufnummer" v-model="ticket.rufnummer" :readonly="readonly" autocomplete="new-password"></b-form-input>
      </b-form-group>
      <b-form-group class="col-sm-12 col-md-6" label-for="rufnummer2" label="Telefon 2" description="(2. Rufnummer optional)">
        <b-form-input id="rufnummer2" v-model="ticket.rufnummer2" :readonly="readonly" autocomplete="new-password"></b-form-input>
      </b-form-group>
    </b-row>
  </b-col>
</template>

<script>
import { BCol, BRow, BFormGroup, BFormInput, BFormTextarea, BIconExclamationTriangleFill } from "bootstrap-vue";
import { DateTime } from "luxon";

export default {
  name: "Stammdaten",
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BIconExclamationTriangleFill,
  },
  props: {
    ticket: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
    titelEnabled: { type: Boolean, default: false },
    beschreibungEnabled: { type: Boolean, default: false },
  },
  methods: {
    formatDate(aDate) {
      if (aDate) {
        return DateTime.fromISO(aDate).toFormat("HH:mm dd.MM.yyyy");
      } else {
        return "";
      }
    },
  },
};
</script>
